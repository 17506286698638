import { Col, Form, Row } from "react-bootstrap";
import { RewardCategoryEnum, RewardCurrencyEnum } from "../../types";
import Select from "react-select";
import { categoryOptions, currencyOptions, Option } from "./options";
import { GroupBase } from "react-select";
import { ReactComponent as Minus } from "../../../../assets/Minus.svg";
import "./index.scss";
import { useField } from "formik";

interface RewardConfigProps {
  rcLength: number;
  idx: number;
  deleteReward: () => void;
}

const RewardConfig: React.FC<RewardConfigProps> = ({
  idx,
  rcLength,
  deleteReward,
}) => {
  const [amountField, amountMeta, amountHelpers] = useField(
    `rewardConfig.${idx}.amount`,
  );
  const [categoryField, categoryMeta, categoryHelpers] = useField(
    `rewardConfig.${idx}.category`,
  );
  const [currencyField, currencyMeta, currencyHelpers] = useField(
    `rewardConfig.${idx}.currency`,
  );
  return (
    <Row className="reward-config">
      <span className="reward-label">Reward {idx + 1}</span>
      {rcLength > 1 && (
        <button className="reward-remove-btn" onClick={deleteReward}>
          <Minus className="text-danger" />
        </button>
      )}

      <Col lg={4}>
        <Form.Control
          type="number"
          placeholder="Amount"
          {...amountField}
          onChange={(e) => {
            const value = +e.target.value;
            if (!Number.isNaN(value)) {
              amountHelpers.setValue(value);
            }
          }}
          className="button-height"
          isInvalid={amountMeta.touched && !!amountMeta.error}
        />
        {amountMeta.touched && amountMeta.error && (
          <Form.Control.Feedback type="invalid">
            {amountMeta.error}
          </Form.Control.Feedback>
        )}
      </Col>

      <Col lg={4}>
        <Select<
          Option<RewardCategoryEnum>,
          false,
          GroupBase<Option<RewardCategoryEnum>>
        >
          placeholder="Category"
          menuPlacement="auto"
          options={categoryOptions}
          className="button-height"
          value={categoryOptions.find(
            (opt) => opt.value === categoryField.value,
          )}
          onChange={(selected) => {
            categoryHelpers.setValue(selected?.value);
          }}
        />
        {categoryMeta.touched && categoryMeta.error && (
          <div className="invalid-feedback d-block">{categoryMeta.error}</div>
        )}
      </Col>

      <Col lg={4}>
        <Select<
          Option<RewardCurrencyEnum>,
          false,
          GroupBase<Option<RewardCurrencyEnum>>
        >
          placeholder="Currency"
          menuPlacement="auto"
          options={currencyOptions}
          className="button-height"
          value={currencyOptions.find(
            (opt) => opt.value === currencyField.value,
          )}
          onChange={(selected) => {
            currencyHelpers.setValue(selected?.value);
          }}
        />
        {currencyMeta.touched && currencyMeta.error && (
          <div className="invalid-feedback d-block">{currencyMeta.error}</div>
        )}
      </Col>
    </Row>
  );
};

export default RewardConfig;

import { API_METHOD } from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";

export const createUser = async (payload = {}) => {
  const url: string = getBase() + "/api" + URLS.USER_ACQUISITION_CREATE;
  return await handleApiRequest(API_METHOD.POST, url, payload);
};

export const updateUser = async (id, payload = {}) => {
  const url: string =
    getBase() + "/api" + URLS.USER_ACQUISITION_UPDATE.replace("{id}", id);
  return await handleApiRequest(API_METHOD.PUT, url, payload);
};

export const fetchUsersList = async (payload = {}) => {
  const url: string = getBase() + "/api" + URLS.USER_ACQUISITION_LIST;
  return await handleApiRequest(API_METHOD.POST, url, payload);
};

import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./style.scss";
import Typography from "../../../common/components/Typography";
import { Button, Spinner } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  disbursePrizeQuiz,
  fetchGWPlayers,
  fetchQuiz,
  publishQuiz,
  saveQuiz,
  updateQuiz,
} from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import {
  Action,
  defaultTripleThreatPrizes,
  MatchStatus,
} from "../../../common/constants";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getActiveSeason } from "../../../LeaguesConfigure/components/leaguesUtils";
import { CommonMessage } from "../../../common/message";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import {
  defaultTTQQuestionConfig,
  gameweekOptions,
  IPlayer,
  ITeam,
  QuizSchema,
} from "../utils";
import QuestionConfigForm from "./QuestionConfigForm";

export const QuizInfo = () => {
  const navigate = useNavigate();
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const [leaguesOption, setLeaguesOption] = useState(
    leaguesMetadata.leaguesSeason,
  );
  const [seasonsOption, setSeasonsOption] = useState([]);
  const [selectedLeagueOption, setSelectedLeagueOption] = useState(null);
  const [selectedSeasonOption, setSelectedSeasonOption] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false);
  const [isPrizeLoading, setIsPrizeLoading] = useState<boolean>(false);
  const [availableTeams, setAvailableTeams] = useState(null);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const [prizeList, setPrizeList] = useState([defaultTripleThreatPrizes]);
  const [isPlayerLoading, setIsPlayerLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<any>({
    title: "",
    handler: () => {
      return;
    },
  });

  const [resultData, setResultData] = useState({
    data: null,
    isLoading: false,
    error: null,
  });
  const [availablePlayersByTeam, setAvailablePlayersByTeam] =
    useState<Record<string, IPlayer[]>>();

  const { quizId } = useParams();
  const edit = quizId !== "create";

  useEffect(() => {
    const fetchQuizAndPlayers = async () => {
      if (!quizId || !edit) return;

      // Fetch the quiz data
      try {
        setIsLoading(true);
        setResultData({ data: null, isLoading: true, error: null });

        const resData = await fetchQuiz({ quizId });

        if (
          resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
          resData?.status === HttpStatus.RESOURCE_CREATED
        ) {
          const quizData = resData?.data?.data;

          setResultData({
            data: quizData,
            isLoading: false,
            error: null,
          });

          // Fetch players only if the quiz data has the required information
          if (quizData?.weekLeague && quizData?.leagueSeasonId) {
            setPrizeList(
              quizData?.prizeConfiguration?.map((prize) => prize?.prize),
            );
            getSelectedGWPlayers(
              quizData?.weekLeague,
              quizData?.leagueSeasonId,
            );

            // fetchPlayersOnMatchChange(fetchPlayerPayload);
          }
        } else {
          throw new Error(resData?.message || "Failed to fetch quiz data.");
        }
      } catch (error) {
        console.error("Error:", error);
        setResultData({
          data: null,
          isLoading: false,
          error: error.message || "An unexpected error occurred.",
        });
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: error.message || "Something went wrong, please try again.",
          variant: "danger",
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuizAndPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId]);

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const quizData = {
    // matchId: resultData?.data?.match?.id ?? "",
    weekLeague: resultData?.data?.weekLeague ?? "",
    name: resultData?.data?.name ?? "",
    questionConfiguration: resultData?.data?.questionConfiguration?.map(
      (config, configIndex) => ({
        tierName: config?.tierName ?? "",
        questionsCount: config?.questions?.length ?? 9,
        questions: config?.questions?.map((question, idx) => ({
          question: question?.question,
          eventType: question?.eventType,
          win_sort_order: question?.win_sort_order,
          options: question?.options?.map((opt, optIndex) => ({
            playerId: opt?.playerId,
          })),
        })),
        rewardType:
          resultData?.data?.prizeConfiguration?.[configIndex]?.rewardType,
        teams: resultData?.data?.teamConfiguration?.[configIndex]?.teams,
      }),
    ),
  };

  const initialValues = edit
    ? quizData
    : {
        gameWeek: "",
        name: "",
        questionConfiguration: Array.from({ length: 1 }, () =>
          defaultTTQQuestionConfig(),
        ),
      };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useEffect(() => {
    setLeaguesOption(leaguesMetadata.leaguesSeason);
  }, [leaguesMetadata]);

  const handleLeaguesChange = (e) => {
    setIsLoading(true);
    const seasonOption = e?.leagueSeasons?.map((item) => ({
      ...item,
      label: item?.name,
      value: item?.id,
    }));
    const activeSeason = getActiveSeason(seasonOption);
    setSelectedLeagueOption(e);
    setSelectedSeasonOption(activeSeason);
    setSeasonsOption(seasonOption || []);
    setIsLoading(false);
  };

  const getSelectedGWPlayers = async (
    gwNumber: any,
    leagueSeasonId: string,
  ) => {
    setIsPlayerLoading(true);
    const resData = await fetchGWPlayers(gwNumber, leagueSeasonId);
    const teamOptions = Object.entries(
      resData?.data?.data?.teams as Record<string, ITeam>,
    ).map(([teamId, teamData]) => ({
      label: teamData?.shortCode,
      value: teamId,
    }));
    setAvailableTeams(teamOptions);
    const playersList = resData?.data?.data?.players as Record<
      string,
      IPlayer[]
    >;

    setAvailablePlayersByTeam(playersList);
    setIsPlayerLoading(false);
  };

  const publishIngQuiz = async (publish: boolean) => {
    try {
      const resData = await publishQuiz({ quizId, publish });

      if (
        resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
        resData?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `Quiz ${
            publish ? "published" : "unpublished"
          } successfully!`,
          variant: "success",
        }));
        setIsPublishLoading(true);

        setTimeout(() => {
          navigate(0);
        }, 2000);
      } else {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message ?? "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
        setIsPublishLoading(false);
      }
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "An unexpected error occurred, please try again.",
        variant: "danger",
      }));
      setIsPublishLoading(false);
    }
  };

  const prizeDisbursing = async () => {
    try {
      setIsPrizeLoading(true);
      const resData = await disbursePrizeQuiz({ quizId });

      if (
        resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
        resData?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: "Prize disbursed successfully!",
          variant: "success",
        }));

        setIsPrizeLoading(false);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      } else {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message ?? "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
      }
      setIsPrizeLoading(false);
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "An unexpected error occurred, please try again.",
        variant: "danger",
      }));
      setIsPrizeLoading(false);
    }
  };

  const updateOrSave = async (values: any, { resetForm }: any) => {
    const payload = {
      leagueSeasonId: edit
        ? resultData?.data?.leagueSeasonId
        : selectedSeasonOption?.id,
      weekLeague: values?.weekLeague,
      name: values?.name,
      questionConfiguration: values?.questionConfiguration?.map(
        (config, configIndex) => ({
          tierNumber: configIndex + 1,
          tierName: config?.tierName,
          questions: config?.questions?.map((question, idx) => ({
            questionNumber: idx + 1,
            question: question?.question,
            eventType: question?.eventType,
            win_sort_order: question?.win_sort_order,
            options: question?.options?.map((opt, optIndex) => ({
              playerId: opt?.playerId,
              optionNumber: optIndex + 1,
            })),
          })),
        }),
      ),
      prizeConfiguration: values?.questionConfiguration?.map(
        (config, configIndex) => ({
          prize: prizeList?.[configIndex],
          rewardType: config?.rewardType,
        }),
      ),
      teamConfiguration: values?.questionConfiguration?.map(
        (config, configIndex) => ({
          tierNumber: configIndex + 1,
          teams: config?.teams,
        }),
      ),
      version: 5,
    };

    const resData: any = edit
      ? await updateQuiz({ ...payload, quizId }, {})
      : await saveQuiz(payload, {});
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `Quiz has been ${edit ? "edited" : "created"} successfully`,
        variant: "success",
      }));

      setIsLoading(false);

      if (edit) {
        setTimeout(() => {
          navigate(0);
        }, 2000);
      } else {
        setTimeout(() => {
          navigate(`/quiz`);
        }, 2000);
      }
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setIsLoading(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };

  if (isLoading || resultData?.isLoading) {
    return (
      <div className="react-table-loader">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (
    (!resultData?.data && edit) ||
    (edit && resultData?.data?.version !== 5)
  ) {
    return <div className="react-table-loader">Data unavailable</div>;
  }

  const modalHandler = (title, handler) => {
    setModalContent({
      ...modalContent,
      title: title,
      handler: handler,
    });
    setShowModal(true);
  };

  const handleGWChange = (e) => {
    getSelectedGWPlayers(e?.value, selectedSeasonOption?.id);
  };

  return (
    <>
      <ConfirmationModal
        variant="alert"
        show={showModal}
        title={modalContent?.title}
        handleClick={modalContent.handler}
        handleClose={() => setShowModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowModal(false)}
        btnText="Confirm"
        isLoading={isPrizeLoading || isPublishLoading}
      />
      <Button
        className="d-flex align-items-start px-0 dynamic-width-button"
        variant="link"
        onClick={() => navigate(-1)}
      >
        {Action.goBack}
      </Button>
      <Typography variant="heading3" className="pb-2">
        {edit ? "Edit Quiz" : "Create Quiz"}
      </Typography>
      {!edit && (
        <section className="d-flex gap-4 pb-2">
          <Select
            placeholder="Filter by leagues"
            options={leaguesOption}
            value={selectedLeagueOption}
            onChange={(e) => {
              handleLeaguesChange(e);
            }}
            isLoading={leaguesMetadata.isLoading}
            loadingMessage={() => <Spinner variant="primary" size="sm" />}
            noOptionsMessage={() =>
              leaguesMetadata?.isError
                ? leaguesMetadata?.errorMsg
                : CommonMessage.noData
            }
            isDisabled={edit}
          />

          <Select
            placeholder="Filter by season"
            options={seasonsOption}
            value={selectedSeasonOption}
            onChange={(e) => {
              setSelectedSeasonOption(e);
            }}
            isDisabled={edit}
          />
        </section>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={QuizSchema}
        onSubmit={updateOrSave}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          isValid,
          isSubmitting,
          errors,
        }) => {
          return (
            <Form>
              <div
                className={`d-flex align-items-center gap-2 ${
                  edit ? "pb-4" : "pb-1"
                }`}
              >
                <Typography variant="heading3">
                  Game Week {edit ? `${values?.weekLeague}` : "*"}
                </Typography>
                {edit && (
                  <div className="d-flex align-items-center pb-2">
                    {resultData?.data?.isPublished ? (
                      <span className="badge rounded-pill text-bg-success px-2 py-1">
                        Published
                      </span>
                    ) : (
                      <span className="badge rounded-pill text-bg-secondary px-2 py-1 text-light">
                        Yet to be Published
                      </span>
                    )}
                  </div>
                )}
              </div>
              <section className="d-flex flex-column w-100">
                <div className=" d-flex gap-2">
                  {!edit && (
                    <Select
                      className="flex-grow-1"
                      placeholder="Select game week"
                      options={gameweekOptions}
                      name="weekLeague"
                      isLoading={isLoading}
                      onChange={(e) => {
                        setFieldValue("weekLeague", e.value);
                        handleGWChange(e);
                      }}
                    />
                  )}
                </div>
              </section>
              <div className="my-3">
                <Typography variant="heading3" className="mb-2">
                  Quiz name
                </Typography>
                <Field
                  className="form-control p-2 rounded"
                  name="name"
                  placeholder="Enter quiz name"
                  disabled={resultData?.data?.isPublished}
                />
                <ErrorMessage
                  name={"name"}
                  component="div"
                  className="text-danger"
                />
                <div className="divider-container"></div>
                <div className="questions-config-container">
                  <QuestionConfigForm
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    edit={edit}
                    isPlayerLoading={isPlayerLoading}
                    resultData={resultData}
                    values={values}
                    isLoading={isLoading}
                    prizeList={prizeList}
                    setPrizeList={setPrizeList}
                    setShowToaster={setShowToaster}
                    availableTeams={availableTeams}
                    availablePlayers={availablePlayersByTeam}
                  />
                </div>
              </div>
              <div className="position-fixed bottom-0 px-2 py-3 w-100 bg-white">
                <div className="d-flex justify-content-start gap-3 w-75">
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-25"
                    disabled={
                      isLoading || resultData?.data?.isPublished || isSubmitting
                    }
                  >
                    {isLoading
                      ? Action.saving
                      : edit
                      ? "Update Quiz"
                      : "Create Quiz"}
                  </Button>
                  {edit && (
                    <Button
                      type="button"
                      variant="primary"
                      className="w-25"
                      disabled={
                        isLoading ||
                        resultData?.data?.prizeDisbursed ||
                        !isValid
                      }
                      onClick={() => {
                        if (!resultData?.data?.isPublished) {
                          modalHandler(
                            `Are you sure you want to publish the quiz ?`,
                            () => publishIngQuiz(true),
                          );
                        } else {
                          modalHandler(
                            `Are you sure you want to unpublish the quiz ?`,
                            () => publishIngQuiz(false),
                          );
                        }
                      }}
                    >
                      {isLoading
                        ? !resultData?.data?.isPublished
                          ? Action.publishing
                          : Action.unPublishing
                        : !resultData?.data?.isPublished
                        ? "publish"
                        : "unPublish"}
                    </Button>
                  )}
                  {edit && (
                    <Button
                      type="button"
                      variant="primary"
                      className="w-25"
                      disabled={
                        isLoading ||
                        resultData?.data?.prizeDisbursed ||
                        !resultData?.data?.isPublished ||
                        !isValid ||
                        resultData?.data?.match?.status ===
                          MatchStatus.COMPLETED
                      }
                      onClick={() => {
                        if (
                          resultData?.data?.isPublished &&
                          !resultData?.data?.prizeDisbursed
                        ) {
                          modalHandler(
                            `Are you sure you want to distribute the prize?`,
                            () => prizeDisbursing(),
                          );
                        }
                      }}
                    >
                      {isLoading
                        ? Action.disbursingPrize
                        : Action.disbursePrize}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};

import Milestone from "./milestone";
import "./style.css";
import PlayToEarnTasks from "./PlayToEarnTasks";

const MilestoneAndTasks = () => {
  return (
    <div className="tasks-wrapper">
      <div className="milestone">
        <Milestone />
      </div>
      <div className="tasks">
        <PlayToEarnTasks />
      </div>
    </div>
  );
};

export default MilestoneAndTasks;

import { useState } from "react";
import DatePicker from "react-datepicker";
const DateTimePicker = ({
  selectedDate,
  dateChangeHandler,
  placeholder = "Select date",
  name = "",
}) => {
  const [date, setDate] = useState(selectedDate);
  return (
    <DatePicker
      selected={date}
      placeholderText={placeholder}
      onChange={(date) => {
        setDate(date);
        dateChangeHandler(date);
      }}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="MMMM d, yyyy h:mm aa"
      name={name}
      className="form-control rounded"
    />
  );
};

export default DateTimePicker;

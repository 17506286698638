import { useState, useEffect } from "react";
import Typography from "../../../common/components/Typography";
import { fetchQuizList } from "../../service";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import moment from "moment";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import {
  EMPTY_ERROR,
  QuizTabsEnum,
  TTQ_VERSION,
} from "../../../common/constants";
import { HttpStatus } from "../../../utils/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import { Button, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { TrueFalseIconViewer } from "../../../common/components/TrueFalseIconViewer";
export const QuizList = () => {
  const [quizList, setQuizList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab");
  const [activeTab, setActiveTab] = useState(selectedTab);
  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const updateTab = (tab: string) => {
    setSearchParams({ tab });
    setActiveTab(tab);
  };

  useEffect(() => {
    getQuizList();
    updateTab(QuizTabsEnum.CURRENT_VERSION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuizList = async () => {
    setQuizList({
      ...quizList,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const resData = await fetchQuizList({
      platform: "Admin",
      status: ["Scheduled", "Live", "In Review", "Completed"],
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      const current = [];
      const old = [];

      resData?.data?.data?.forEach((quiz) => {
        if (quiz?.version && quiz?.version === 5) {
          current.push(quiz);
        } else {
          old.push(quiz);
        }
      });
      setQuizList((prev) => ({
        ...prev,
        isLoading: false,
        data: {
          ...prev?.data,
          current,
        },
      }));
      setQuizList((prev) => ({
        ...prev,
        isLoading: false,
        data: {
          ...prev?.data,
          old,
        },
      }));
    } else {
      setQuizList({
        ...quizList,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };

  const columns = [
    {
      accessorKey: "team1Name",
      header: "Team 1",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      accessorKey: "team2Name",
      header: "Team 2",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      header: "Teams",
      cell: ({ row }) => {
        if (row?.original?.version === TTQ_VERSION.FIVE) {
          return row?.original?.teamDataByTier?.map((data) => (
            <div className="d-flex flex-column" key={data?.tierNumber}>
              <div className="d-flex gap-2">
                <span className="nowrap">{data?.tierNumber}.</span>
                <span className="nowrap">
                  {data?.teams?.map((team) => `${team?.shortCode} , `)}
                </span>
              </div>
            </div>
          ));
        }
      },
      canSort: false,
    },
    {
      header: "tier Names",
      cell: ({ row }) => {
        if (row?.original?.version === TTQ_VERSION.FIVE) {
          return row?.original?.questionConfiguration?.map((config) => (
            <div key={config?.tierName}>
              <span className="nowrap">{config?.tierName}</span>
            </div>
          ));
        } else {
          return null;
        }
      },
    },
    {
      accessorKey: "quizId",
      header: "Quiz Id",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      accessorKey: "isPublished",
      header: "Published",
      cell: (info) => (
        <TrueFalseIconViewer value={info?.getValue() ? true : false} />
      ),
      // canSort: false,
    },
    {
      accessorKey: "matchStartDate",
      header: "Start Date",
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      // canSort: false,
    },
    {
      accessorKey: "matchStartTime",
      header: "Start Time",
      cell: (info) => info?.getValue()?.slice(0, 5),
      // canSort: false,
    },
    {
      accessorKey: "quizCreatedAt",
      header: "Created At",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY (HH:mm)"),
      // canSort: false,
    },
    {
      accessorKey: "prizeDisbursedAt",
      header: "Prize Disbursed",
      cell: (info) => {
        const row = info.row.original;
        const date = info.getValue() ? moment(info.getValue()) : null;
        const disbursedDate = date?.isValid()
          ? date?.format("DD/MM/YYYY (HH:mm)")
          : "";
        return (
          <div className="d-flex align-items-center gap-2">
            <TrueFalseIconViewer value={row.prizeDisbursed ? true : false} />
            {row.prizeDisbursed ? `\u{1F4C5} ${disbursedDate}` : null}
          </div>
        );
      },
    },
    {
      id: "navigate",
      header: "Actions",
      cell: (info) => {
        return (
          <Link to={`/quiz/${info.row.original.quizId}`} state={{ edit: true }}>
            <Button size="sm" variant="link">
              Configure
            </Button>
          </Link>
        );
      },
      canSort: false,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between">
        <Typography variant="pageTitle" className="pb-2">
          Quiz List
        </Typography>
        <Link to="/quiz/create">
          <Button variant="primary"> Create Quiz</Button>
        </Link>
      </div>
      <Row className="mb-2">
        <Tabs
          defaultActiveKey={1}
          id="quizTab"
          className="mb-2 ms-4"
          activeKey={activeTab}
          onSelect={(key: any) => updateTab(key)}
          variant="pills"
        >
          <Tab
            eventKey={QuizTabsEnum.CURRENT_VERSION}
            title={"Current version"}
          >
            <Row>
              <TableWrapper>
                <ReactTable
                  columns={columns?.slice(2)}
                  data={quizList?.data?.current || []}
                  isLoading={quizList?.isLoading}
                  hasError={quizList?.error?.isError}
                  errorMessage={quizList?.error?.errorMsg}
                />
              </TableWrapper>
            </Row>
          </Tab>

          <Tab eventKey={QuizTabsEnum.OLDER_VERSION} title={"Older version"}>
            <Row>
              <TableWrapper>
                <ReactTable
                  columns={columns.slice(0, 2).concat(columns.slice(4))}
                  data={quizList?.data?.old || []}
                  isLoading={quizList?.isLoading}
                  hasError={quizList?.error?.isError}
                  errorMessage={quizList?.error?.errorMsg}
                />
              </TableWrapper>
            </Row>
          </Tab>
        </Tabs>
      </Row>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};

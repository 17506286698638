import React, { useState, useEffect } from "react";
import {
  fetchAcquisitionsStats,
  fetchReferralStats,
  fetchTelegramDefaultMetrics,
} from "../../service";
import { HttpStatus } from "../../../utils/constants";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  Referral,
  formatDate,
} from "../../../Referrals/component/ReferralsTable";
import "./style.scss";
import {
  DAYTIME_PHASE,
  defaultPageSize,
  EMPTY_ERROR,
  ReferralTabsEnum,
} from "../../../common/constants";
import { getTimeStampedDate } from "../../../teamslist/component/TeamConfigure/teamConfigHelper";
import { useLocation, useSearchParams } from "react-router-dom";
import { formatStatsDate } from "../Stats";
import { Tab, Tabs } from "react-bootstrap";

export const ReferralStatComponent = ({ startDate, endDate }) => {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [referralStats, setReferralStats] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [tgReferralStats, setTgReferralStats] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [acquisitions, setAcquisitions] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const location = useLocation();
  const isTgStats = location.pathname.includes("telegram");
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab");
  const [activeTab, setActiveTab] = useState("userReferral");

  const updateTab = (tab: string, subtab: string) => {
    setSearchParams({ tab, subtab });
    setActiveTab(subtab);
  };

  useEffect(() => {
    if (isTgStats) {
      getTgReferrals();
      getAcquisitionsStats();
    } else {
      getReferralStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentPage, pageSize, isTgStats]);

  const getAcquisitionsStats = async () => {
    setAcquisitions({
      ...acquisitions,
      isLoading: true,
      error: EMPTY_ERROR,
    });

    try {
      const resData: any = await fetchAcquisitionsStats({});

      if (
        resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
        resData?.status === HttpStatus.RESOURCE_CREATED
      ) {
        const formattedData = Object.keys(resData?.data?.data || {}).map(
          (key) => ({
            source: key,
            referralCount: resData.data.data[key],
          }),
        );
        setAcquisitions((prev) => ({
          ...prev,
          isLoading: false,
          data: formattedData,
        }));
      } else {
        setAcquisitions({
          ...acquisitions,
          data: null,
          isLoading: false,
          error: { errorMsg: resData?.message, isError: true },
        });
      }
    } catch (error) {
      setAcquisitions({
        ...acquisitions,
        data: null,
        isLoading: false,
        error: { errorMsg: "Failed to fetch acquisitions data", isError: true },
      });
    }
  };

  const getReferralStats = async () => {
    setReferralStats({
      ...referralStats,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const tsStartDate = getTimeStampedDate(startDate, DAYTIME_PHASE.START);
    const tsEndDate = getTimeStampedDate(endDate, DAYTIME_PHASE.END);
    const resData: any = await fetchReferralStats({
      startDate: tsStartDate,
      endDate: tsEndDate,
      skip: (currentPage - 1) * pageSize,
      limit: pageSize,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setReferralStats((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setReferralStats({
        ...referralStats,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
  };

  const getTgReferrals = async () => {
    let formattedStartDate;
    let formattedEndDate;
    if (startDate && endDate) {
      formattedStartDate = formatStatsDate(startDate);
      formattedEndDate = formatStatsDate(endDate);
    }

    const resData: any = await fetchTelegramDefaultMetrics({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      const responseData = resData?.data?.data;
      setTgReferralStats((pre) => ({
        ...pre,
        isLoading: false,
        data: responseData?.usersWithReferredCount,
      }));
    } else {
      setTgReferralStats({
        ...tgReferralStats,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "code",
        id: "code",
        header: () => <span>{Referral.code}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "isDeleted",
        id: "status",
        header: () => <span>{Referral.status}</span>,
        cell: (info) => (
          <span
            style={{
              color: info.getValue() ? "red" : "green",
            }}
          >
            {info.getValue() ? "Deactivated" : "Active"}
          </span>
        ),
      },
      {
        accessorKey: "createdAt",
        id: "createdAt",
        header: () => <span>{Referral.createdAt}</span>,
        cell: (info) => formatDate(info.getValue()),
      },
      {
        accessorKey: "count",
        id: "count",
        header: () => <span>{Referral.count}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "userCreatedTeams",
        id: "userCreatedTeams",
        header: () => <span>{Referral.createdUserTeams}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "redeemCount",
        id: "redeemCount",
        header: () => <span>{Referral.bonusRedeemed}</span>,
        cell: (info) => info.getValue(),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const tgReferralColumns: ColumnDef<any, any>[] = [
    {
      accessorKey: "username",
      id: "username",
      header: () => <span>{Referral.username}</span>,
      cell: (info) => info.getValue(),
      enableSorting: false,
    },
    {
      accessorKey: "name",
      id: "name",
      header: () => <span>{Referral.name}</span>,
      cell: (info) => info.getValue(),
      enableSorting: false,
    },
    {
      accessorKey: "referralCount",
      id: "referralCount",
      header: () => <span>{Referral.referralCount}</span>,
      cell: (info) => info.getValue(),
      enableSorting: true,
    },
  ];
  const tgReferralAquisitionnsColumns: ColumnDef<any, any>[] = [
    {
      accessorKey: "source",
      id: "source",
      header: () => <span>{Referral.source}</span>,
      cell: (info) => info.getValue(),
      enableSorting: false,
    },
    {
      accessorKey: "referralCount",
      id: "referral count",
      header: () => <span>{Referral.referralCount}</span>,
      cell: (info) => info.getValue(),
      enableSorting: true,
    },
  ];

  return (
    <>
      {isTgStats ? (
        <Tabs
          defaultActiveKey={ReferralTabsEnum.USER_REFERRAL}
          id="taskTab"
          className="mb-2 ms-4"
          activeKey={activeTab}
          onSelect={(key: any) => updateTab(selectedTab, key)}
          variant="pills"
        >
          <Tab
            eventKey={ReferralTabsEnum.USER_REFERRAL}
            title={"User Referral"}
          >
            <TableWrapper>
              <ReactTable
                data={tgReferralStats?.data || []}
                columns={tgReferralColumns}
              />
            </TableWrapper>
          </Tab>

          <Tab
            eventKey={ReferralTabsEnum.USER_ACQUISITIONS}
            title={"User Acquisitions"}
          >
            <TableWrapper>
              <ReactTable
                data={acquisitions?.data || []}
                columns={tgReferralAquisitionnsColumns}
              />
            </TableWrapper>
          </Tab>
        </Tabs>
      ) : (
        <ReactTable
          isLoading={referralStats?.isLoading}
          data={referralStats?.data?.promotions || []}
          columns={columns}
          pageChangeHandler={setCurrentPage}
          pageSizeHandler={setPageSize}
          currentPageNumber={currentPage}
          totalRows={referralStats?.data?.totalCount}
          rowsPerPage={pageSize}
          hasError={referralStats?.error.isError}
          errorMessage={referralStats?.error.errorMsg}
        />
      )}
    </>
  );
};

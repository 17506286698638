import React, { useCallback } from "react";
import { defaultTTQQuestionConfig, generateQuestions } from "../utils";
import { ErrorMessage, Field, FieldArray } from "formik";
import Typography from "../../../common/components/Typography";
import { Button } from "react-bootstrap";
import Select from "react-select";
import {
  defaultTripleThreatPrizes,
  EventTypeEnum,
  OrderEnum,
  PrizeTypeEnum,
} from "../../../common/constants";
import { rewardTypeOptions } from "../../../Trivia/triviaUtils";
import PrizeConfig from "../../../common/components/PrizeConfig";
import TierNameInput from "./TierNameInput";

const QuestionConfigForm = ({
  errors,
  setFieldValue,
  setFieldTouched,
  resultData,
  edit,
  values,
  isPlayerLoading,
  isLoading,
  prizeList,
  setPrizeList,
  setShowToaster,
  availableTeams,
  availablePlayers,
}) => {
  const handleUpdatePrizeList =
    (qConfigIndex: number) => (updatedPrizeList: any) => {
      setPrizeList((list) => {
        const newPrizeList = [...list];

        while (newPrizeList?.length <= qConfigIndex) {
          newPrizeList.push([]);
        }

        newPrizeList[qConfigIndex] = updatedPrizeList;
        return newPrizeList;
      });
    };

  const getPlayersOptions = useCallback(
    (configIndex) => {
      if (!availablePlayers) return [];
      const selectedTeams = values?.questionConfiguration?.[configIndex]?.teams;
      let playersByTeam = [];
      selectedTeams?.forEach(
        (team) =>
          (playersByTeam = [...playersByTeam, ...availablePlayers[team]]),
      );
      const playerOptions = playersByTeam?.map((player) => ({
        label: player?.name,
        value: player?.id,
      }));
      return playerOptions;
    },
    [availablePlayers, values?.questionConfiguration],
  );
  return (
    <FieldArray name="questionConfiguration">
      {({ insert, remove, push }) => (
        <div>
          {values?.questionConfiguration?.map((config, configIndex) => (
            <div key={config?.tierName}>
              <div className="d-flex justify-content-between align-items-center">
                <Typography variant="heading3" className="mb-4">
                  Tier {configIndex + 1}
                </Typography>

                <Button
                  variant="danger"
                  onClick={() => remove(configIndex)}
                  disabled={
                    resultData?.data?.isPublished ||
                    values.questionConfiguration.length <= 1
                  }
                >
                  Delete Tier
                </Button>
              </div>

              {/* tier name and no. of questions */}
              <section className="d-flex gap-4">
                <section className="w-50">
                  <Typography variant="heading4">Tier name</Typography>
                  <TierNameInput
                    configIndex={configIndex}
                    tierName={config?.tierName}
                    setFieldValue={setFieldValue}
                    isPublished={resultData?.data?.isPublished}
                    setFieldTouched={setFieldTouched}
                  />
                  <ErrorMessage
                    name={`questionConfiguration.${configIndex}.tierName`}
                    component="div"
                    className="text-danger"
                  />
                </section>

                <section className="w-50">
                  <Typography variant="heading4">No. of Questions</Typography>
                  <Field
                    className="form-control p-2 rounded"
                    name={`questionConfiguration.${configIndex}.questionsCount`}
                    type="number"
                    placeholder="No. of questions"
                    min={1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = parseInt(e.target.value) || 1;
                      setFieldValue(
                        `questionConfiguration.${configIndex}.questionsCount`,
                        value,
                      );
                      setFieldValue(
                        `questionConfiguration.${configIndex}.questions`,
                        generateQuestions(values, value, configIndex),
                      );
                    }}
                    disabled={resultData?.data?.isPublished}
                  />
                </section>
              </section>

              {/*select teams */}
              <section className="w-100 mt-2 mb-2">
                <Typography variant="heading4">Select Teams</Typography>
                <Select
                  isMulti
                  className="flex-grow-1"
                  value={availableTeams?.filter((team) =>
                    config?.teams?.some((selTeam) => selTeam === team?.value),
                  )}
                  isDisabled={resultData?.data?.isPublished}
                  placeholder="Select Teams"
                  options={availableTeams}
                  isLoading={isLoading}
                  name={`questionConfiguration.${configIndex}.teams`}
                  onChange={(selectedTeam) => {
                    setFieldValue(
                      `questionConfiguration.${configIndex}.teams`,
                      selectedTeam?.map((team) => team?.value),
                    );
                  }}
                />
              </section>
              {/* Questions form */}
              <section>
                {config?.questions?.map((question, index) => (
                  <div key={index}>
                    <Typography variant="pageTitle" className="p-1">
                      Question {index + 1}
                    </Typography>
                    {/* eventType and winning_order */}
                    <div className="row px-1">
                      <div className="col-md-6">
                        <Typography variant="heading6" className="">
                          Winning Order
                        </Typography>
                        <div className="input-container mb-4 w-50">
                          <Select
                            options={Object.values(OrderEnum).map((order) => ({
                              label: order,
                              value: order,
                            }))}
                            placeholder="Select Win Order"
                            className="quiz-option-input"
                            name={`questionConfiguration.${configIndex}.questions.${index}.win_sort_order`}
                            onChange={(e) => {
                              setFieldValue(
                                `questionConfiguration.${configIndex}.questions.${index}.win_sort_order`,
                                e.value,
                              );
                            }}
                            isDisabled={resultData?.data?.isPublished && edit}
                            value={Object.values(OrderEnum)
                              .map((order) => ({
                                label: order,
                                value: order,
                              }))
                              .find(
                                (option) =>
                                  option.value ===
                                  values.questionConfiguration?.[configIndex]
                                    ?.questions[index]?.win_sort_order,
                              )}
                          />

                          <ErrorMessage
                            name={`questionConfiguration.${configIndex}.questions.${index}.win_sort_order`}
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <Typography variant="heading6" className="p-1">
                          Event Type
                        </Typography>
                        <div className="input-container mb-4 w-100">
                          <Select
                            options={Object.values(EventTypeEnum).map(
                              (eventType) => ({
                                label: eventType,
                                value: eventType,
                              }),
                            )}
                            placeholder="Select Event Type"
                            className="quiz-option-input w-50"
                            name={`questionConfiguration.${configIndex}.questions.${index}.eventType`}
                            isDisabled={resultData?.data?.isPublished && edit}
                            onChange={(e) => {
                              setFieldValue(
                                `questionConfiguration.${configIndex}.questions.${index}.eventType`,
                                e.value,
                              );
                            }}
                            value={Object.values(EventTypeEnum)
                              .map((eventType) => ({
                                label: eventType,
                                value: eventType,
                              }))
                              .find(
                                (option) =>
                                  option.value ===
                                  values.questionConfiguration?.[configIndex]
                                    ?.questions[index]?.eventType,
                              )}
                          />

                          <ErrorMessage
                            name={`questionConfiguration.${configIndex}.questions.${index}.eventType`}
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                    </div>

                    {/*question */}
                    <div className="relative mb-2">
                      <Field
                        className="quiz-question fs-6"
                        name={`questionConfiguration.${configIndex}.questions.${index}.question`}
                        placeholder="Enter Question"
                        disabled={resultData?.data?.isPublished && edit}
                      />
                      <ErrorMessage
                        name={`questionConfiguration.${configIndex}.questions.${index}.question`}
                        component="div"
                        className="absolute py-2 px-1 top-0 right-0 text-danger"
                      />
                    </div>

                    {/* Options */}
                    <FieldArray
                      name={`questionConfiguration.${configIndex}.questions.${index}.options`}
                    >
                      {({ insert, remove, push }) => (
                        <div className="pt-2">
                          {values?.questionConfiguration?.[configIndex]
                            ?.questions?.[index]?.options &&
                          values?.questionConfiguration?.[configIndex]
                            ?.questions?.[index]?.options?.length > 0 ? (
                            values?.questionConfiguration?.[
                              configIndex
                            ]?.questions?.[index]?.options.map(
                              (option, optIndex) => (
                                <div
                                  className="quiz-options-container quiz-option"
                                  key={optIndex}
                                >
                                  <Typography variant="heading6">
                                    Option {optIndex + 1}
                                  </Typography>
                                  <div className="input-container">
                                    <Select
                                      value={getPlayersOptions(
                                        configIndex,
                                      )?.find(
                                        (option) =>
                                          option?.value ===
                                          values.questionConfiguration?.[
                                            configIndex
                                          ]?.questions[index].options[optIndex]
                                            .playerId,
                                      )}
                                      getOptionLabel={(option) => option.label}
                                      getOptionValue={(option) => option.value}
                                      options={getPlayersOptions(configIndex)}
                                      name={`questionConfiguration.${configIndex}.questions.${index}.options.${optIndex}.playerId`}
                                      placeholder={`Select Option ${
                                        optIndex + 1
                                      }`}
                                      className="quiz-option-input"
                                      isDisabled={
                                        resultData?.data?.isPublished && edit
                                      }
                                      isLoading={isPlayerLoading}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `questionConfiguration.${configIndex}.questions.${index}.options.${optIndex}.playerId`,
                                          e.value,
                                        );
                                        setFieldTouched(
                                          `questionConfiguration.${configIndex}.questions.${index}.options`,
                                          true,
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`questionConfiguration.${configIndex}.questions.${index}.options.${optIndex}.playerId`}
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>
                                </div>
                              ),
                            )
                          ) : (
                            <div>No options available.</div>
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <div className="relative mb-2">
                      {typeof errors?.questionConfiguration?.[configIndex]
                        ?.questions?.[index]?.options != "object" && (
                        <span className="text-danger">
                          {
                            errors?.questionConfiguration?.[configIndex]
                              ?.questions?.[index]?.options
                          }
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </section>

              {/*Prize config*/}
              <section className="d-flex flex-column w-100 mt-2 mb-4">
                <Typography variant="heading4">Prize Configuration</Typography>

                <div className=" d-flex flex-column gap-2 mb-2">
                  <Select
                    className="flex-grow-1"
                    value={rewardTypeOptions.find(
                      (opt) => opt.value === config?.rewardType,
                    )}
                    options={rewardTypeOptions}
                    placeholder="Select Prize Type"
                    name={`questionConfiguration.${configIndex}.rewardType`}
                    isLoading={isLoading}
                    onChange={(selectedOption) => {
                      setFieldValue(
                        `questionConfiguration.${configIndex}.rewardType`,
                        selectedOption?.value,
                      );
                    }}
                  />
                  <div className="custom-error-message">
                    {values?.questionConfiguration?.[configIndex]
                      ?.rewardType === PrizeTypeEnum.PERCENTAGE
                      ? prizeList[configIndex]?.length === 0 &&
                        "Prize Configuration is required"
                      : prizeList?.[configIndex]?.length !==
                          values?.questionConfiguration?.[configIndex]
                            ?.questions?.length &&
                        "Prize Configuration is required"}
                  </div>
                </div>

                <PrizeConfig
                  isLoading={isLoading || resultData.isLoading}
                  isError={false}
                  errorMsg="Something went wrong, please try again"
                  prizeList={
                    prizeList?.[configIndex] ?? defaultTripleThreatPrizes
                  }
                  setPrizeList={handleUpdatePrizeList(configIndex)}
                  isReadOnly={resultData?.data?.isPublished}
                  setShowToaster={setShowToaster}
                  isDisabledDeleteRow={resultData?.data?.isPublished}
                  isFromFieldDisabled={resultData?.data?.isPublished}
                  isToFieldDisabled={resultData?.data?.isPublished}
                  canSetDefaultToAndFromValue={true}
                  isRewardTypeFlat={config?.rewardType === PrizeTypeEnum.FLAT}
                  questionsCount={config?.questions?.length}
                  isEditing={!!edit}
                />
              </section>
            </div>
          ))}

          <Button
            variant="primary"
            onClick={() => {
              push(
                defaultTTQQuestionConfig(
                  values?.questionConfiguration?.length + 1,
                ),
              );
              setPrizeList((prev) => [...prev, defaultTripleThreatPrizes]);
            }}
            disabled={resultData?.data?.isPublished}
          >
            Add Tier
          </Button>
        </div>
      )}
    </FieldArray>
  );
};

export default QuestionConfigForm;

import Typography from "../../../common/components/Typography";

const UserSubscriptions = () => {
  return (
    <>
      <Typography variant="pageTitle">User Subscriptions</Typography>
      <Typography variant="heading6">Work in progress</Typography>
    </>
  );
};

export default UserSubscriptions;

import { Link, useNavigate } from "react-router-dom";
import Typography from "../../../common/components/Typography";
import { Button, Col, Form, Row } from "react-bootstrap";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import ReactToastr from "../../../common/components/ReactToaster";
import { useEffect, useState } from "react";
import { EMPTY_ERROR } from "../../../common/constants";
import { HttpStatus } from "../../../utils/constants";
import { fetchUsersList } from "../../service";
const UserList = () => {
  const [usersList, setUsersList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(null);
  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };
  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: "name",
      id: "name",
      header: () => <span>Username</span>,
      cell: (info) => info.getValue(),
      enableSorting: false,
    },
    {
      accessorKey: "username",
      id: "username",
      header: () => <span>Source</span>,
      cell: (info) => info.getValue(),
      enableSorting: false,
    },
    {
      accessorKey: "actions",
      cell: ({ row }) => {
        const { name, username, id } = row?.original;
        return (
          <div className="d-flex gap-2">
            <>
              <Button
                size="sm"
                onClick={() => {
                  navigate(`/user-acquisition/${id}`, {
                    state: { username: name, source: username, name: "" },
                  });
                }}
                className="text-nowrap"
                disabled={false}
              >
                Edit
              </Button>
            </>
          </div>
        );
      },
      enableSorting: false,
    },
  ];

  const getUsersList = async () => {
    setUsersList((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const resData: any = await fetchUsersList({
      source: searchTerm ? [searchTerm] : [],
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUsersList((prev) => ({
        ...prev,
        isLoading: false,
        data: resData?.data?.data,
      }));
    }
  };
  useEffect(() => {
    getUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between">
        <Typography variant="pageTitle" className="pb-2">
          User List
        </Typography>
        <Link to="/user-acquisition/create">
          <Button variant="primary"> Create User</Button>
        </Link>
      </div>

      <Row className="mb-2">
        <Col lg="6">
          <div className="d-flex gap-2">
            <Form.Control
              aria-label="Large"
              value={searchTerm || ""}
              aria-describedby="inputGroup-sizing-sm"
              placeholder="Search by source"
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            <Button
              size="sm"
              variant="primary"
              disabled={!searchTerm}
              onClick={() => getUsersList()}
            >
              Search
            </Button>
          </div>
        </Col>
      </Row>

      <TableWrapper>
        <ReactTable
          isLoading={usersList?.isLoading}
          data={usersList?.data ?? []}
          hasError={usersList?.error?.isError}
          errorMessage={usersList?.error?.errorMsg}
          columns={columns}
          totalRows={usersList?.data?.length}
        />
      </TableWrapper>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};

export default UserList;

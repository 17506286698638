import React, { useEffect, useState } from "react";
import ReactTable from "../../common/components/ReactTable/ReactTable";
import TableWrapper from "../../common/components/ReactTable/TableWrapper";
import { EMPTY_ERROR } from "../../common/constants";
import GraphView from "./GraphView";
import { Row, Tab, Tabs } from "react-bootstrap";
import ReactToastr from "../../common/components/ReactToaster";
import { HttpStatus } from "../../utils/constants";
import { fetchEventStats } from "../service";
import DateRangeFilter from "../../common/components/DateRangeFilter/DateRangeFilter";
import {
  getInitialDateRange,
  initialDateRange,
  initialStaticDateRange,
  staticDateOption,
} from "../../Stats/statsUtil";
import { getLocalDisplayedDateRange } from "../../teamslist/component/TeamConfigure/teamConfigHelper";
import { formatStatsDate } from "../../Stats/component/Stats";
import Typography from "../../common/components/Typography";

enum AnalyticsTabsEnum {
  TABLE_VIEW = "TABLE_VIEW",
  GRAPH_VIEW = "GRAPH_VIEW",
}

export const Analytics = () => {
  const [userAnalytics, setUserAnalytics] = useState<any>({
    data: [],
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const [activeTab, setActiveTab] = useState(AnalyticsTabsEnum.TABLE_VIEW);

  const [dateRange, setDateRange] = useState(getInitialDateRange());
  const [definedDateRange, setDefinedDateRange] = useState<any>(
    initialStaticDateRange,
  );

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const getEventsStats = async () => {
    setUserAnalytics((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const resData: any = await fetchEventStats({
      startDate: formatStatsDate(dateRange?.[0]),
      endDate: formatStatsDate(dateRange?.[1]),
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUserAnalytics((prev) => ({
        ...prev,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setUserAnalytics((prev) => ({
        ...prev,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };

  const columns = [
    {
      accessorKey: "eventName",
      id: "eventName",
      header: "Event Name",
      cell: ({ row }) => <span>{row?.original?.eventName}</span>,
      canSort: false,
    },
    {
      accessorKey: "count",
      id: "count",
      header: "Event Count",
      cell: ({ row }) => <span>{row?.original?.count}</span>,
    },
  ];
  useEffect(() => {
    getEventsStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const handleDateRange = (dateRange) => {
    setDateRange(dateRange);
  };
  const handleStaticDateRange = (filterButtonOption, oldValue) => {
    if (filterButtonOption?.key === "lifetime") {
      setDefinedDateRange((prev) => ({
        initialStaticDateRange,
        [filterButtonOption.key]: !prev[filterButtonOption.key],
      }));
      setDateRange([null, null]);
    } else {
      setDefinedDateRange((prev) => ({
        initialStaticDateRange,
        [filterButtonOption.key]: !prev[filterButtonOption.key],
      }));
      if (oldValue) setDateRange(initialDateRange);
      else {
        const dateToSet = getLocalDisplayedDateRange(
          filterButtonOption.daysToGet,
          filterButtonOption.daysNumber,
        );
        setDateRange(dateToSet);
      }
    }
  };

  return (
    <>
      <Row className="mb-2 d-flex">
        <Typography variant="pageTitle">App Analytics</Typography>
      </Row>
      <Row>
        <DateRangeFilter
          staticDateOption={staticDateOption}
          staticDateRange={definedDateRange}
          dateChangeHandler={handleDateRange}
          staticDateChangeHandler={handleStaticDateRange}
          dateRange={dateRange}
          canClear={false}
        />
      </Row>
      <Row className="mb-2">
        <Tabs
          defaultActiveKey={1}
          id="analytics-tab"
          className="mb-2 ms-4"
          activeKey={activeTab}
          onSelect={(key: any) => setActiveTab(key)}
          variant="pills"
        >
          <Tab eventKey={AnalyticsTabsEnum.TABLE_VIEW} title={"Table View"}>
            <TableWrapper>
              <ReactTable
                isLoading={userAnalytics?.isLoading}
                data={userAnalytics?.data}
                columns={columns}
                hasError={userAnalytics?.error?.isError}
                errorMessage={userAnalytics?.error?.errorMsg}
              />
            </TableWrapper>
          </Tab>

          <Tab eventKey={AnalyticsTabsEnum.GRAPH_VIEW} title={"Graph View"}>
            <Row>
              <GraphView data={userAnalytics?.data} />
            </Row>
          </Tab>
        </Tabs>
      </Row>

      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};

import { Field, FieldArray, Form, Formik } from "formik";
import { Button, Row, Form as BsForm, Col } from "react-bootstrap";
import Typography from "../../../common/components/Typography";
import { Action, Multiplier } from "../../../common/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { ActionMeta } from "react-select";
import {
  currencyTypes,
  EarningType,
  earningTypes,
  getAvailableEarningTypesOptions,
  getAvailableGameModeOptions,
  getDefaultConfigValues,
  getDefaultEarningValues,
  initialCreateSubscriptionValues,
  multiplierOptions,
  subscriptionSchema,
  SubscriptionTypeEnum,
  subscriptionTypes,
} from "../../subscriptionUtils";
import { gameModesOptions } from "../../../Tasks/tasksUtils";
import { useEffect, useState } from "react";
import { HttpStatus } from "../../../utils/constants";
import { createSubscription, updateSubsciption } from "../../service";
import ReactToastr from "../../../common/components/ReactToaster";

interface MultiplierSelectProps {
  label: string;
  placeholder: string;
  options: {
    label: Multiplier;
    value: Multiplier;
  }[];
  name: string;
  value: {
    label: Multiplier;
    value: Multiplier;
  };
  onChange?: (
    newValue: {
      label: Multiplier;
      value: Multiplier;
    },
    actionMeta: ActionMeta<{
      label: Multiplier;
      value: Multiplier;
    }>,
  ) => void;
  error?: string;
}

const MultiplierSelect: React.FC<MultiplierSelectProps> = ({
  label,
  placeholder,
  options,
  name,
  value,
  onChange,
  error,
}) => {
  return (
    <>
      <BsForm.Label>{label}</BsForm.Label>
      <Select
        placeholder={placeholder}
        options={options}
        name={name}
        value={value}
        onChange={onChange}
        menuPlacement="auto"
      />
      {error && (
        <Typography className="text-danger fs-10 position-absolute">
          {error}
        </Typography>
      )}
    </>
  );
};

const SubscriptionForm = () => {
  const [initialSubscriptionValues, setInitialSubscriptionFormValues] =
    useState(initialCreateSubscriptionValues);
  const navigate = useNavigate();
  const location = useLocation();

  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const { edit, data } = location?.state ?? {};

  useEffect(() => {
    if (edit && data) {
      const serializedData = {
        id: data.id,
        type: data.type,
        version: data.version,
        amount: data.amount,
        currencyCode: data.currencyCode,
        name: data.name,
        description: data.description,
        validityDays: data.validityDays,
        configuration: data.configuration,
        totalTicketsCredited: data.totalTicketsCredited,
      };
      setInitialSubscriptionFormValues(serializedData);
    }
  }, [location, edit, data]);

  const handleSubmit = async (values: any) => {
    try {
      const res = !edit
        ? await createSubscription(values)
        : await updateSubsciption(values);

      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster({
          show: true,
          message: `Subscription ${!edit ? "created" : "updated"}`,
          variant: "success",
        });
        setTimeout(() => {
          navigate("/subscriptions/model");
        }, 2000);
      } else {
        throw new Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setShowToaster({
        show: true,
        message: error.message,
        variant: "danger",
      });
    }
  };

  return (
    <>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
      <Button
        className="d-flex align-items-start px-0"
        variant="link"
        onClick={() => navigate(-1)}
      >
        {Action.goBack}
      </Button>
      <Typography variant="pageTitle">
        {edit ? "Update" : "Create"} Subscription
      </Typography>
      <section className="mt-2">
        <Row>
          <Formik
            initialValues={initialSubscriptionValues}
            validationSchema={subscriptionSchema}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, setFieldValue, setFieldError }) => {
              const addConfig = () => {
                if (values.type === SubscriptionTypeEnum.DEFAULT) {
                  const earningType = getAvailableEarningTypesOptions(
                    values?.configuration,
                  )?.at(0)?.value;
                  return getDefaultEarningValues(earningType);
                }
                if (values.type === SubscriptionTypeEnum.ONE_TIME) {
                  return {
                    gameMode: getAvailableGameModeOptions(
                      values?.configuration,
                    )?.at(0)?.value,
                    teamsPerMatch: 1,
                  };
                }
              };

              console.log(values);

              return (
                <Form className="d-flex flex-column gap-4">
                  <Row>
                    <Col md={6} className="position-relative">
                      <BsForm.Label>Select Type</BsForm.Label>
                      <Select
                        placeholder="Select subscription type"
                        options={subscriptionTypes}
                        name="type"
                        value={subscriptionTypes.find(
                          (option) => option.value === values.type,
                        )}
                        onChange={(option) => {
                          setFieldValue("type", option.value);
                          setFieldValue(
                            "configuration",
                            getDefaultConfigValues(option.value),
                          );
                          if (
                            option.value === SubscriptionTypeEnum.GOLDEN_TICKET
                          ) {
                            setFieldValue("validityDays", undefined);
                          } else {
                            if (option.value === SubscriptionTypeEnum.NO_ADS) {
                              setFieldValue("validityDays", 365);
                            } else {
                              setFieldValue("validityDays", 30);
                            }
                          }
                          setFieldError("type", undefined);
                        }}
                        menuPlacement="auto"
                      />
                      {errors?.type && touched.type && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.type as string}
                        </Typography>
                      )}
                    </Col>
                    <Col className="position-relative" md={4}>
                      <BsForm.Label>Version</BsForm.Label>
                      <Field
                        type="number"
                        name="version"
                        className="form-control"
                        min={0}
                      />
                      {errors?.version && touched.version && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.version as string}
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="position-relative">
                      <BsForm.Label>Amount</BsForm.Label>
                      <Field
                        type="number"
                        name="amount"
                        className="form-control"
                        min={0}
                      />
                      {errors?.amount && touched.amount && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.amount as string}
                        </Typography>
                      )}
                    </Col>
                    <Col md={4} className="position-relative">
                      <BsForm.Label>Currency</BsForm.Label>
                      <Select
                        menuPlacement="auto"
                        placeholder="Select currency"
                        options={currencyTypes}
                        name="currencyCode"
                        value={currencyTypes.find(
                          (option) => option.value === values.currencyCode,
                        )}
                        onChange={(option) => {
                          setFieldValue("currencyCode", option.value);
                          setFieldError("currencyCode", undefined);
                        }}
                      />
                      {errors?.currencyCode && touched.currencyCode && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.currencyCode as string}
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} className="position-relative">
                      <BsForm.Label>Name</BsForm.Label>
                      <Field
                        name="name"
                        type="text"
                        placeholder="Name"
                        className="form-control"
                      />
                      {errors?.name && touched.name && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.name as string}
                        </Typography>
                      )}
                    </Col>
                    <Col md={6} className="position-relative">
                      <BsForm.Label>Description</BsForm.Label>
                      <Field
                        name="description"
                        type="text"
                        placeholder="Description"
                        className="form-control"
                      />
                      {errors?.description && touched.description && (
                        <Typography className="text-danger fs-10 position-absolute">
                          {errors?.description as string}
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {values?.type !== SubscriptionTypeEnum.GOLDEN_TICKET ? (
                      <Col md={4} className="position-relative">
                        <BsForm.Label>Validity (in days)</BsForm.Label>
                        <Field
                          name="validityDays"
                          type="number"
                          placeholder="validity"
                          className="form-control"
                          min={0}
                        />
                        {errors?.validityDays && touched.validityDays && (
                          <Typography className="text-danger fs-10 position-absolute">
                            {errors?.validityDays as string}
                          </Typography>
                        )}
                      </Col>
                    ) : (
                      <Col md={4} className="position-relative">
                        <BsForm.Label>Number of Golden Tickets</BsForm.Label>
                        <Field
                          name="configuration.totalTicketsCredited"
                          type="number"
                          placeholder="Ticket count"
                          className="form-control"
                          min={0}
                        />
                        {errors.configuration?.["totalTicketsCredited"] &&
                          touched.configuration?.["totalTicketsCredited"] && (
                            <Typography className="text-danger fs-10 position-absolute">
                              {errors.configuration?.["totalTicketsCredited"]}
                            </Typography>
                          )}
                      </Col>
                    )}
                  </Row>

                  {![
                    SubscriptionTypeEnum.NO_ADS,
                    SubscriptionTypeEnum.GOLDEN_TICKET,
                  ].includes(values?.type) && (
                    <section className="border rounded">
                      <FieldArray name="configuration">
                        {({ push, remove }) => (
                          <div className="p-2 d-flex flex-column gap-3">
                            <Row className="d-flex justify-content-between align-items-center">
                              <Col>
                                <Typography variant="heading3">
                                  Configuration
                                </Typography>
                                <div className="divider-container"></div>
                              </Col>
                              <Col md={"auto"}>
                                <Button
                                  variant="primary"
                                  onClick={() => push(addConfig())}
                                  disabled={values?.configuration?.length >= 4}
                                >
                                  Add
                                </Button>
                              </Col>
                            </Row>
                            {[
                              SubscriptionTypeEnum.NO_ADS,
                              SubscriptionTypeEnum.DEFAULT,
                            ].includes(values?.type) &&
                              values?.configuration?.map((_, index) => (
                                <div key={index} className="">
                                  <Row
                                    key={index}
                                    className="d-flex align-items-end"
                                  >
                                    <Col md={3} className="position-relative">
                                      <BsForm.Label>Benifit</BsForm.Label>
                                      <Select
                                        placeholder="Select earning type"
                                        options={
                                          getAvailableEarningTypesOptions(
                                            values?.configuration,
                                          ) || earningTypes
                                        }
                                        name={`configuration.${index}.earningType`}
                                        value={earningTypes?.find(
                                          (option) =>
                                            option.value ===
                                            values?.configuration[index]
                                              ?.earningType,
                                        )}
                                        menuPlacement="auto"
                                        onChange={(option) => {
                                          setFieldValue(
                                            `configuration.${index}`,
                                            getDefaultEarningValues(
                                              option?.value,
                                            ),
                                          );
                                          setFieldError(
                                            `configuration.${index}.earningType`,
                                            undefined,
                                          );
                                        }}
                                      />
                                    </Col>

                                    {[
                                      EarningType.TASK,
                                      EarningType.REFERRAL,
                                    ].includes(
                                      values?.configuration?.[index]
                                        ?.earningType,
                                    ) && (
                                      <Col md={3} className="position-relative">
                                        <MultiplierSelect
                                          label="Multiplier"
                                          placeholder="Select multiplier"
                                          options={multiplierOptions}
                                          name={`configuration.${index}.multiplier`}
                                          value={multiplierOptions.find(
                                            (option) =>
                                              option.value ===
                                              values.configuration[index]
                                                ?.multiplier,
                                          )}
                                          onChange={(option) => {
                                            setFieldValue(
                                              `configuration.${index}.multiplier`,
                                              option.value,
                                            );
                                            setFieldError(
                                              `configuration.${index}.multiplier`,
                                              undefined,
                                            );
                                          }}
                                          error={
                                            errors?.configuration?.[index]
                                              ?.multiplier &&
                                            touched?.configuration?.[index]
                                              ?.multiplier &&
                                            (errors?.configuration?.[index]
                                              ?.multiplier as string)
                                          }
                                        />
                                      </Col>
                                    )}

                                    {[EarningType.GAME].includes(
                                      values?.configuration?.[index]
                                        ?.earningType,
                                    ) && (
                                      <>
                                        <Col
                                          md={2}
                                          className="position-relative"
                                        >
                                          <MultiplierSelect
                                            label="Triple Threat Multiplier"
                                            placeholder="Select multiplier"
                                            options={multiplierOptions}
                                            name={`configuration.${index}.games[0].multiplier`}
                                            value={multiplierOptions.find(
                                              (option) =>
                                                option.value ===
                                                values.configuration[index]
                                                  ?.games[0].multiplier,
                                            )}
                                            onChange={(option) => {
                                              setFieldValue(
                                                `configuration.${index}.games[0].multiplier`,
                                                option.value,
                                              );
                                              setFieldError(
                                                `configuration.${index}.games[0].multiplier`,
                                                undefined,
                                              );
                                            }}
                                            error={
                                              errors?.configuration?.[index]
                                                ?.games[0]?.multiplier &&
                                              touched?.configuration?.[index]
                                                ?.games[0]?.multiplier &&
                                              (errors?.configuration?.[index]
                                                ?.games[0]
                                                ?.multiplier as string)
                                            }
                                          />
                                        </Col>
                                        <Col
                                          md={2}
                                          className="position-relative"
                                        >
                                          <MultiplierSelect
                                            label="Goalfest Multiplier"
                                            placeholder="Select multiplier"
                                            options={multiplierOptions}
                                            name={`configuration.${index}.games[1].multiplier`}
                                            value={multiplierOptions.find(
                                              (option) =>
                                                option.value ===
                                                values.configuration[index]
                                                  ?.games[1].multiplier,
                                            )}
                                            onChange={(option) => {
                                              setFieldValue(
                                                `configuration.${index}.games[1].multiplier`,
                                                option.value,
                                              );
                                              setFieldError(
                                                `configuration.${index}.games[1].multiplier`,
                                                undefined,
                                              );
                                            }}
                                            error={
                                              errors?.configuration?.[index]
                                                ?.games[1]?.multiplier &&
                                              touched?.configuration?.[index]
                                                ?.games[1]?.multiplier &&
                                              (errors?.configuration?.[index]
                                                ?.games[1]
                                                ?.multiplier as string)
                                            }
                                          />
                                        </Col>
                                        <Col
                                          md={2}
                                          className="position-relative"
                                        >
                                          <MultiplierSelect
                                            label="Fantasy Multiplier"
                                            placeholder="Select multiplier"
                                            options={multiplierOptions}
                                            name={`configuration.${index}.games[2].multiplier`}
                                            value={multiplierOptions.find(
                                              (option) =>
                                                option.value ===
                                                values.configuration[index]
                                                  ?.games[2].multiplier,
                                            )}
                                            onChange={(option) => {
                                              setFieldValue(
                                                `configuration.${index}.games[2].multiplier`,
                                                option.value,
                                              );
                                              setFieldError(
                                                `configuration.${index}.games[2].multiplier`,
                                                undefined,
                                              );
                                            }}
                                            error={
                                              errors?.configuration?.[index]
                                                ?.games[2]?.multiplier &&
                                              touched?.configuration?.[index]
                                                ?.games[2]?.multiplier &&
                                              (errors?.configuration?.[index]
                                                ?.games[2]
                                                ?.multiplier as string)
                                            }
                                          />
                                        </Col>
                                      </>
                                    )}

                                    {[EarningType.GOLDEN_TICKET].includes(
                                      values?.configuration?.[index]
                                        ?.earningType,
                                    ) && (
                                      <Col md={3} className="position-relative">
                                        <BsForm.Label>
                                          Tickets count
                                        </BsForm.Label>
                                        <Field
                                          name={`configuration.${index}.totalTicketsCredited`}
                                          type="number"
                                          placeholder="Tickets count"
                                          className="form-control"
                                          min={0}
                                        />
                                        {errors?.configuration?.[index]
                                          ?.totalTicketsCredited &&
                                          touched?.configuration?.[index]
                                            ?.totalTicketsCredited && (
                                            <Typography className="text-danger fs-10 position-absolute">
                                              {
                                                errors?.configuration?.[index]
                                                  ?.totalTicketsCredited
                                              }
                                            </Typography>
                                          )}
                                      </Col>
                                    )}

                                    {values?.configuration?.length > 1 && (
                                      <Col md={3}>
                                        <Button
                                          variant="danger"
                                          onClick={() => remove(index)}
                                        >
                                          Remove
                                        </Button>
                                      </Col>
                                    )}
                                  </Row>

                                  {[EarningType.GOLDEN_TICKET].includes(
                                    values?.configuration?.[index]?.earningType,
                                  ) && (
                                    <>
                                      <div className="mt-3">
                                        <Typography variant="heading6">
                                          Triple Threat
                                        </Typography>
                                        <Row
                                          key={index}
                                          className="d-flex align-items-end"
                                        >
                                          <Col
                                            md={4}
                                            className="position-relative"
                                          >
                                            <BsForm.Label>Amount</BsForm.Label>
                                            <Field
                                              name={`configuration.${index}.rewardsConfig[0].amount`}
                                              type="number"
                                              placeholder="Amount"
                                              className="form-control"
                                              min={0}
                                            />
                                            {errors?.configuration?.[index]
                                              ?.rewardsConfig[0]?.amount &&
                                              touched?.configuration?.[index]
                                                ?.rewardsConfig[0]?.amount && (
                                                <Typography className="text-danger fs-10 position-absolute">
                                                  {
                                                    errors?.configuration?.[
                                                      index
                                                    ]?.rewardsConfig[0]?.amount
                                                  }
                                                </Typography>
                                              )}
                                          </Col>

                                          <Col
                                            md={4}
                                            className="position-relative"
                                          >
                                            <BsForm.Label>
                                              Currency
                                            </BsForm.Label>
                                            <Select
                                              menuPlacement="auto"
                                              placeholder="Select currency"
                                              options={currencyTypes}
                                              name={`configuration.${index}.rewardsConfig[0].currency`}
                                              value={currencyTypes.find(
                                                (option) =>
                                                  option.value ===
                                                  values.configuration?.[index]
                                                    .rewardsConfig[0].currency,
                                              )}
                                              onChange={(option) => {
                                                setFieldValue(
                                                  `configuration.${index}.rewardsConfig[0].currency`,
                                                  option.value,
                                                );
                                                setFieldError(
                                                  `configuration.${index}.rewardsConfig[0].currency`,
                                                  undefined,
                                                );
                                              }}
                                            />
                                            {errors?.configuration?.[index]
                                              ?.rewardsConfig[0]?.currency &&
                                              touched?.configuration?.[index]
                                                ?.rewardsConfig[0]
                                                ?.currency && (
                                                <Typography className="text-danger fs-10 position-absolute">
                                                  {
                                                    errors?.configuration?.[
                                                      index
                                                    ]?.rewardsConfig[0]
                                                      ?.currency as string
                                                  }
                                                </Typography>
                                              )}
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="mt-3">
                                        <Typography variant="heading6">
                                          Goalfest
                                        </Typography>
                                        <Row
                                          key={index}
                                          className="d-flex align-items-end"
                                        >
                                          <Col
                                            md={4}
                                            className="position-relative"
                                          >
                                            <BsForm.Label>Amount</BsForm.Label>
                                            <Field
                                              name={`configuration.${index}.rewardsConfig[1].amount`}
                                              type="number"
                                              placeholder="Amount"
                                              className="form-control"
                                              min={0}
                                            />
                                            {errors?.configuration?.[index]
                                              ?.rewardsConfig[1]?.amount &&
                                              touched?.configuration?.[index]
                                                ?.rewardsConfig[1]?.amount && (
                                                <Typography className="text-danger fs-10 position-absolute">
                                                  {
                                                    errors?.configuration?.[
                                                      index
                                                    ]?.rewardsConfig[1]?.amount
                                                  }
                                                </Typography>
                                              )}
                                          </Col>

                                          <Col
                                            md={4}
                                            className="position-relative"
                                          >
                                            <BsForm.Label>
                                              Currency
                                            </BsForm.Label>
                                            <Select
                                              menuPlacement="auto"
                                              placeholder="Select currency"
                                              options={currencyTypes}
                                              name={`configuration.${index}.rewardsConfig[1].currency`}
                                              value={currencyTypes.find(
                                                (option) =>
                                                  option.value ===
                                                  values.configuration?.[index]
                                                    .rewardsConfig[1].currency,
                                              )}
                                              onChange={(option) => {
                                                setFieldValue(
                                                  `configuration.${index}.rewardsConfig[1].currency`,
                                                  option.value,
                                                );
                                                setFieldError(
                                                  `configuration.${index}.rewardsConfig[1].currency`,
                                                  undefined,
                                                );
                                              }}
                                            />
                                            {errors?.configuration?.[index]
                                              ?.rewardsConfig[1]?.currency &&
                                              touched.configuration?.[index]
                                                ?.rewardsConfig[1]
                                                ?.currency && (
                                                <Typography className="text-danger fs-10 position-absolute">
                                                  {
                                                    errors?.configuration?.[
                                                      index
                                                    ]?.rewardsConfig[1]
                                                      ?.currency as string
                                                  }
                                                </Typography>
                                              )}
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="mt-3">
                                        <Typography variant="heading6">
                                          Fantasy
                                        </Typography>
                                        <Row
                                          key={index}
                                          className="d-flex align-items-end"
                                        >
                                          <Col
                                            md={4}
                                            className="position-relative"
                                          >
                                            <BsForm.Label>Amount</BsForm.Label>
                                            <Field
                                              name={`configuration.${index}.rewardsConfig[2].amount`}
                                              type="number"
                                              placeholder="Amount"
                                              className="form-control"
                                              min={0}
                                            />
                                            {errors?.configuration?.[index]
                                              ?.rewardsConfig[2]?.amount &&
                                              touched?.configuration?.[index]
                                                ?.rewardsConfig[2]?.amount && (
                                                <Typography className="text-danger fs-10 position-absolute">
                                                  {
                                                    errors?.configuration?.[
                                                      index
                                                    ]?.rewardsConfig[2]?.amount
                                                  }
                                                </Typography>
                                              )}
                                          </Col>

                                          <Col
                                            md={4}
                                            className="position-relative"
                                          >
                                            <BsForm.Label>
                                              Currency
                                            </BsForm.Label>
                                            <Select
                                              menuPlacement="auto"
                                              placeholder="Select currency"
                                              options={currencyTypes}
                                              name={`configuration.${index}.rewardsConfig[2].currency`}
                                              value={currencyTypes.find(
                                                (option) =>
                                                  option.value ===
                                                  values.configuration?.[index]
                                                    .rewardsConfig[2].currency,
                                              )}
                                              onChange={(option) => {
                                                setFieldValue(
                                                  `configuration.${index}.rewardsConfig[2].currency`,
                                                  option.value,
                                                );
                                                setFieldError(
                                                  `configuration.${index}.rewardsConfig[2].currency`,
                                                  undefined,
                                                );
                                              }}
                                            />
                                            {errors?.configuration?.[index]
                                              ?.rewardsConfig[2]?.currency &&
                                              touched?.configuration?.[index]
                                                ?.rewardsConfig[2]
                                                ?.currency && (
                                                <Typography className="text-danger fs-10 position-absolute">
                                                  {
                                                    errors?.configuration?.[
                                                      index
                                                    ]?.rewardsConfig[2]
                                                      ?.currency as string
                                                  }
                                                </Typography>
                                              )}
                                          </Col>
                                        </Row>
                                      </div>
                                    </>
                                  )}
                                  <div className="divider-container"></div>
                                </div>
                              ))}

                            {values?.type === SubscriptionTypeEnum.ONE_TIME &&
                              values?.configuration?.length > 0 &&
                              values?.configuration?.map((_, index) => (
                                <Row
                                  key={index}
                                  className="d-flex justify-content-center align-items-end"
                                >
                                  <Col md={4} className="position-relative">
                                    <BsForm.Label>Game Mode</BsForm.Label>
                                    <Select
                                      placeholder="Select game mode"
                                      options={
                                        getAvailableGameModeOptions(
                                          values?.configuration,
                                        ) || gameModesOptions
                                      }
                                      name={`configuration.${index}.gameMode`}
                                      value={gameModesOptions?.find(
                                        (option) =>
                                          option.value ===
                                          values.configuration[index]?.gameMode,
                                      )}
                                      onChange={(option) => {
                                        setFieldValue(
                                          `configuration.${index}.gameMode`,
                                          option?.value,
                                        );
                                        setFieldError(
                                          `configuration.${index}.gameMode`,
                                          undefined,
                                        );
                                      }}
                                      menuPlacement="auto"
                                    />
                                  </Col>
                                  <Col md={4} className="position-relative">
                                    <BsForm.Label>
                                      No. of teams per match
                                    </BsForm.Label>
                                    <Field
                                      name={`configuration.${index}.teamsPerMatch`}
                                      type="number"
                                      min={1}
                                      placeholder="teamsPerMatch"
                                      className="form-control"
                                    />
                                    {errors?.configuration?.[index]
                                      ?.teamsPerMatch &&
                                      touched?.configuration?.[index]
                                        ?.teamsPerMatch && (
                                        <Typography className="text-danger fs-10 position-absolute">
                                          {
                                            errors?.configuration?.[index]
                                              ?.teamsPerMatch
                                          }
                                        </Typography>
                                      )}
                                  </Col>
                                  {values?.configuration?.length > 1 && (
                                    <Col md={3}>
                                      <Button
                                        variant="danger"
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                              ))}
                          </div>
                        )}
                      </FieldArray>
                    </section>
                  )}

                  <Button type="submit" variant="primary" className="w-100">
                    {edit ? "Update" : "Create"} Subscription
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </section>
    </>
  );
};

export default SubscriptionForm;

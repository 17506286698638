import { useState, useEffect, useCallback } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Spinner,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import * as FileSaver from "file-saver";
import { PublishMatchList } from "./PublistMatch";
import { UnPublishMatchList } from "./UnPublishMatch";
import { CustomStatusCode, HttpStatus } from "../../utils/constants";
import { downloadMatchesReportApi, fetchMatchList } from "../service";
import "./matchlist.scss";
import {
  Action,
  ContestTypeEnum,
  DateRangeEnum,
  DateRangeEnumLabel,
  DaysToGetEnum,
  EMPTY_ERROR,
  Match,
  MatchStatusOption,
  defaultPageSize,
  minSearchTimeDelay,
  searchTimeDelay,
} from "../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { setTeamsData } from "./TeamsColorConfig/teamColorConfigSlice";
import { fetchTeamList } from "../../Players/service";
import ReactToastr from "../../common/components/ReactToaster";
import Select from "react-select";
import Typography from "../../common/components/Typography";
import DateRangeFilter from "../../common/components/DateRangeFilter/DateRangeFilter";
import {
  getLocalDisplayedDateRange,
  getDisplayedDateFromUTC,
} from "./TeamConfigure/teamConfigHelper";
import { getActiveSeason } from "../../LeaguesConfigure/components/leaguesUtils";
import { CommonMessage, getCommonError } from "../../common/message";
import { ClearInputCross } from "../../common/SVG";
import { getSearchedMatchIds } from "../../utils/matchUtil";
import { convertBase64ToBuffer } from "../../utils/transformation";
import Itooltip from "../../common/Itooltip/Itooltip";
import { showFailureToaster } from "../../utils/apiUtil";
import { getPaginationQuery } from "../../utils/userUtil";

const initialDateRange = [null, null];
const initialStaticDateRange = {
  [DateRangeEnum.last7Days]: false,
  [DateRangeEnum.next7Days]: false,
  [DateRangeEnum.next15Days]: false,
};
const staticDateOption = [
  {
    key: DateRangeEnum.last7Days,
    label: DateRangeEnumLabel[DateRangeEnum.last7Days],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 7,
  },
  {
    key: DateRangeEnum.next7Days,
    label: DateRangeEnumLabel[DateRangeEnum.next7Days],
    daysToGet: DaysToGetEnum.next,
    daysNumber: 7,
  },
  {
    key: DateRangeEnum.next15Days,
    label: DateRangeEnumLabel[DateRangeEnum.next15Days],
    daysToGet: DaysToGetEnum.next,
    daysNumber: 15,
  },
];
const MatchList = ({ isTgMatches = false }) => {
  const dispatch = useDispatch();
  const teamsInfoList = useSelector((state: any) => state.teams);
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const [activeTab, setActiveTab] = useState<any>(1);
  const [selectedTeamOptionsUnPub, setSelectedTeamOptionsUnPub] =
    useState<any[]>(null);
  const [selectedTeamsUnPub, setSelectedTeamsUnPub] = useState<string[]>(null);
  const [selectedStatusUnPub, setSelectedStatusUnPub] = useState<any[]>(null);
  const [unPubSearchMatchIdInput, setUnPubSearchMatchIdInput] = useState(null);
  const [unPubSearchMatchId, setUnPubSearchMatchId] = useState(null);
  const [selectedStatusOptionUnPub, setSelectedStatusOptionUnPub] =
    useState<any[]>(null);
  const [dateRangeUnPub, setDateRangeUnPub] = useState(initialDateRange);
  const [staticDateRangeUnPub, setStaticDateRangeUnPub] = useState<any>(
    initialStaticDateRange,
  );
  const [selectedTeamOptionsPub, setSelectedTeamOptionsPub] =
    useState<any[]>(null);
  const [selectedTeamsPub, setSelectedTeamsPub] = useState<string[]>(null);
  const [selectedStatusPub, setSelectedStatusPub] = useState<any[]>(null);
  const [selectedStatusOptionPub, setSelectedStatusOptionPub] =
    useState<any[]>(null);
  const [pubCurrentPage, setPubCurrentPage] = useState(1);
  const [pubPageSize, setPubPageSize] = useState(defaultPageSize);
  const [dateRangePub, setDateRangePub] = useState(initialDateRange);
  const [staticDateRangePub, setStaticDateRangePub] = useState<any>(
    initialStaticDateRange,
  );
  const [unPubCurrentPage, setUnPubCurrentPage] = useState(1);
  const [unPubPageSize, setUnPubPageSize] = useState(defaultPageSize);
  const [leaguesOption, setLeaguesOption] = useState(
    leaguesMetadata.leaguesSeason,
  );
  const [seasonsOption, setSeasonsOption] = useState([]);
  const [selectedLeagueOption, setSelectedLeagueOption] = useState(null);
  const [selectedSeasonOption, setSelectedSeasonOption] = useState(null);
  const [filteredLeagueSeason, setFilteredLeagueSeason] = useState(null);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [publishedMatchList, setPublishedMatchList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [unPublishedMatchList, setUnPublishedMatchList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });

  // 1) prizeDisbursed variable is linked to the checkbox filter provided on the pubished screen
  const [prizeDisbursed, setPrizeDisbursed] = useState(false);
  const handlePrizeDisbursedChange = () => {
    setPrizeDisbursed(!prizeDisbursed);
  };
  const [unPubSpecialMatch, setUnPubSpecialMatch] = useState(null);
  const [filterUnPubSpecialMatch, setFilterUnPubSpecialMatch] = useState(null);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
    if (showToaster?.callBack) {
      showToaster.callBack();
    }
  };

  useEffect(() => {
    setLeaguesOption(leaguesMetadata.leaguesSeason);
  }, [leaguesMetadata]);

  const handleLeaguesChange = (e) => {
    const seasonOption = e?.leagueSeasons?.map((item) => ({
      ...item,
      label: item?.name,
      value: item?.id,
    }));
    const activeSeason = getActiveSeason(seasonOption);
    setSelectedLeagueOption(e);
    setSelectedSeasonOption(activeSeason);
    setFilteredLeagueSeason(activeSeason);
    setSeasonsOption(seasonOption || []);
  };

  const handleTeamFilterPub = (selectedOptions = []) => {
    setSelectedTeamOptionsPub(selectedOptions);
    setSelectedTeamsPub(selectedOptions?.map((option) => option.value));
  };

  const handleTeamFilterUnPub = (selectedOptions = []) => {
    setSelectedTeamOptionsUnPub(selectedOptions);
    setSelectedTeamsUnPub(selectedOptions?.map((option) => option.value));
  };

  const handleStatusFilterUnPub = (selectedOptions = []) => {
    setSelectedStatusOptionUnPub(selectedOptions);
    setSelectedStatusUnPub(selectedOptions?.map((option) => option.value));
  };

  const handleStatusFilterPub = (selectedOptions = []) => {
    setSelectedStatusOptionPub(selectedOptions);
    setSelectedStatusPub(selectedOptions?.map((option) => option.value));
  };

  const handleDateRangeUnPub = (e) => {
    setDateRangeUnPub(e);
    setStaticDateRangeUnPub(initialStaticDateRange);
  };
  const handleStaticDateRangeUnPub = (filterButtonOption, oldValue) => {
    setStaticDateRangeUnPub((prev) => ({
      initialStaticDateRange,
      [filterButtonOption.key]: !prev[filterButtonOption.key],
    }));
    if (oldValue) setDateRangeUnPub(initialDateRange);
    else {
      const dateToSet = getLocalDisplayedDateRange(
        filterButtonOption.daysToGet,
        filterButtonOption.daysNumber,
      );
      setDateRangeUnPub(dateToSet);
    }
  };

  const handleDateRangePub = (date) => {
    setDateRangePub(date);
    setStaticDateRangePub(initialStaticDateRange);
  };
  const handleStaticDateRangePub = (filterButtonOption, oldValue) => {
    setStaticDateRangePub((prev) => ({
      initialStaticDateRange,
      [filterButtonOption.key]: !prev[filterButtonOption.key],
    }));
    if (oldValue) setDateRangePub(initialDateRange);
    else {
      const dateToSet = getLocalDisplayedDateRange(
        filterButtonOption.daysToGet,
        filterButtonOption.daysNumber,
      );
      setDateRangePub(dateToSet);
    }
  };

  const getTeamsInfoList = useCallback(async () => {
    dispatch(
      setTeamsData({
        ...teamsInfoList,
        isLoading: true,
        error: EMPTY_ERROR,
      }),
    );
    const resData: any = await fetchTeamList();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      dispatch(
        setTeamsData({
          ...teamsInfoList,
          isLoading: false,
          data: resData?.data?.data,
          error: EMPTY_ERROR,
        }),
      );
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!teamsInfoList?.data?.length) getTeamsInfoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamsInfoList]);

  const downloadPlayerReport = async () => {
    setIsDownloadingReport(true);
    const resData: any = await downloadMatchesReportApi({
      query: {
        published: true,
        prizeDisbursed: prizeDisbursed,
      },
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsDownloadingReport(false);
      const buffer = convertBase64ToBuffer(resData?.data);
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, `matches_report_${Date.now()}.xlsx`);
    } else {
      setIsDownloadingReport(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const getPublishedMatchList = useCallback(async () => {
    setPublishedMatchList({
      ...publishedMatchList,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const startDate = getDisplayedDateFromUTC(dateRangePub?.[0]);
    const endDate = getDisplayedDateFromUTC(dateRangePub?.[1]);
    const resData: any = await fetchMatchList({
      query: {
        published: true,
        teamIds: selectedTeamsPub || [],
        startDate: startDate,
        endDate: endDate,
        status: selectedStatusPub,
        leagueSeasonId: filteredLeagueSeason?.id,
        prizeDisbursed: prizeDisbursed ? true : undefined,
        contestType: isTgMatches
          ? ContestTypeEnum.TG_FANTASY
          : ContestTypeEnum.MATCH,
      },
      page: getPaginationQuery(pubCurrentPage, pubPageSize),
    });

    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setPublishedMatchList((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setPublishedMatchList({
        ...publishedMatchList,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pubPageSize,
    pubCurrentPage,
    selectedTeamsPub,
    dateRangePub,
    selectedStatusPub,
    filteredLeagueSeason,
    prizeDisbursed,
    isTgMatches,
  ]);
  const getUnPublishedMatchList = useCallback(async () => {
    setUnPublishedMatchList({
      ...unPublishedMatchList,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const startDate = getDisplayedDateFromUTC(dateRangeUnPub?.[0]);
    const endDate = getDisplayedDateFromUTC(dateRangeUnPub?.[1]);
    const resData: any = await fetchMatchList({
      query: {
        published: false,
        featured: filterUnPubSpecialMatch ? true : undefined,
        teamIds: selectedTeamsUnPub || [],
        startDate: startDate,
        endDate: endDate,
        status: selectedStatusUnPub,
        leagueSeasonId: filteredLeagueSeason?.id,
        ids: getSearchedMatchIds(unPubSearchMatchId),
        contestType: isTgMatches
          ? ContestTypeEnum.TG_FANTASY
          : ContestTypeEnum.MATCH,
      },
      page: getPaginationQuery(unPubCurrentPage, unPubPageSize),
    });

    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setUnPublishedMatchList((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setUnPublishedMatchList({
        ...unPublishedMatchList,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unPubCurrentPage,
    unPubPageSize,
    selectedTeamsUnPub,
    dateRangeUnPub,
    selectedStatusUnPub,
    filteredLeagueSeason,
    unPubSearchMatchId,
    filterUnPubSpecialMatch,
    isTgMatches,
  ]);

  useEffect(() => {
    getPublishedMatchList();
  }, [getPublishedMatchList]);

  useEffect(() => {
    getUnPublishedMatchList();
  }, [getUnPublishedMatchList]);

  useEffect(() => {
    if (unPubSearchMatchIdInput !== null) {
      const timerId = setTimeout(() => {
        setUnPubSearchMatchId(unPubSearchMatchIdInput);
        if (unPubCurrentPage !== 1) setUnPubCurrentPage(1);
      }, searchTimeDelay);
      return () => clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unPubSearchMatchIdInput]);

  useEffect(() => {
    if (unPubSpecialMatch !== null) {
      const timerId = setTimeout(() => {
        setFilterUnPubSpecialMatch(unPubSpecialMatch);
        if (unPubCurrentPage !== 1) setUnPubCurrentPage(1);
      }, minSearchTimeDelay);
      return () => clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unPubSpecialMatch]);

  return (
    <>
      <div className="header-row-wrapper">
        <div className="header-filter-wrapper">
          <Typography variant="pageTitle">
            {isTgMatches ? Match.tgMatches : Match.matches}
          </Typography>
          <Select
            placeholder="Filter by leagues"
            className="leagues-select-wrapper"
            options={leaguesOption}
            value={selectedLeagueOption}
            onChange={(e) => {
              handleLeaguesChange(e);
            }}
            isLoading={leaguesMetadata.isLoading}
            loadingMessage={() => <Spinner variant="primary" size="sm" />}
            noOptionsMessage={() =>
              leaguesMetadata?.isError
                ? leaguesMetadata?.errorMsg
                : CommonMessage.noData
            }
          />
        </div>
        <Select
          placeholder="Filter by season"
          className="seasons-select-wrapper"
          options={seasonsOption}
          value={selectedSeasonOption}
          onChange={(e) => {
            setSelectedSeasonOption(e);
            setFilteredLeagueSeason(e);
          }}
        />
      </div>
      <div className="tab-wrapper match-tab-wrapper">
        <Tabs
          defaultActiveKey={1}
          id="matchTab"
          className="mb-3 matchlist-tabs"
          activeKey={activeTab}
          transition={false}
          onSelect={(key: any) => setActiveTab(key)}
        >
          <Tab
            eventKey={1}
            title={"Unpublished"}
            tabClassName="matchTab-tab-content"
          >
            <>
              <Row className="mb-2">
                <Col>
                  <InputGroup className="filter-by-match-id" size="lg">
                    <Form.Control
                      aria-label="Large"
                      value={unPubSearchMatchIdInput || ""}
                      aria-describedby="inputGroup-sizing-sm"
                      placeholder={Match.unPublished.filterByMatchIdPlaceholder}
                      onChange={(event) =>
                        setUnPubSearchMatchIdInput(event.target.value)
                      }
                    />
                    <Button
                      variant="outline-secondary"
                      disabled={!unPubSearchMatchIdInput}
                      onClick={() => setUnPubSearchMatchIdInput("")}
                    >
                      <ClearInputCross />
                    </Button>
                  </InputGroup>
                </Col>
                <Col>
                  <Select
                    className="small-select-filter"
                    placeholder="Filter by teams"
                    closeMenuOnSelect={false}
                    isMulti
                    value={selectedTeamOptionsUnPub}
                    options={teamsInfoList?.data?.map((team) => ({
                      label: team.name,
                      value: team.id,
                    }))}
                    onChange={handleTeamFilterUnPub}
                  />
                </Col>
                <Col>
                  <Select
                    className="small-select-filter"
                    placeholder="Filter by match status"
                    closeMenuOnSelect={false}
                    isMulti
                    value={selectedStatusOptionUnPub}
                    options={MatchStatusOption}
                    onChange={handleStatusFilterUnPub}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <DateRangeFilter
                    staticDateOption={staticDateOption}
                    dateChangeHandler={handleDateRangeUnPub}
                    staticDateChangeHandler={handleStaticDateRangeUnPub}
                    staticDateRange={staticDateRangeUnPub}
                    dateRange={dateRangeUnPub}
                  />
                </Col>
                <Col className="prize-disbursed-flag-container" xs={2}>
                  <div>
                    <input
                      type="checkbox"
                      checked={unPubSpecialMatch}
                      onChange={() => setUnPubSpecialMatch(!unPubSpecialMatch)}
                    />
                    <label>&nbsp;{Match.unPublished.specialMatch}</label>
                  </div>
                </Col>
              </Row>
            </>
            <UnPublishMatchList
              unPublishedMatchList={unPublishedMatchList}
              setUnPubCurrentPage={setUnPubCurrentPage}
              setUnPubPageSize={setUnPubPageSize}
              unPubPageSize={unPubPageSize}
              unPubPage={unPubCurrentPage}
              setShowToaster={setShowToaster}
              getUnPublishedMatchList={getUnPublishedMatchList}
            />
          </Tab>
          <Tab
            eventKey={2}
            tabClassName="matchTab-tab-content"
            title={"Published"}
          >
            <>
              <Row className="mb-2">
                <Col>
                  <Select
                    className="small-select-filter"
                    placeholder="Filter by teams"
                    closeMenuOnSelect={false}
                    isMulti
                    value={selectedTeamOptionsPub}
                    options={teamsInfoList?.data?.map((team) => ({
                      label: team.name,
                      value: team.id,
                    }))}
                    onChange={handleTeamFilterPub}
                  />
                </Col>
                <Col>
                  <Select
                    className="small-select-filter"
                    placeholder="Filter by match status"
                    closeMenuOnSelect={false}
                    isMulti
                    value={selectedStatusOptionPub}
                    options={MatchStatusOption}
                    onChange={handleStatusFilterPub}
                  />
                </Col>
                <Col>
                  {prizeDisbursed && (
                    <div
                      className={`button-with-tooltip ${
                        isDownloadingReport ? "disabled" : ""
                      }`}
                      style={{
                        maxWidth: isDownloadingReport ? "270px" : "220px",
                      }}
                    >
                      <Button
                        className="publish-button"
                        onClick={() => {
                          if (!isDownloadingReport) {
                            downloadPlayerReport();
                          }
                        }}
                        disabled={isDownloadingReport}
                      >
                        {isDownloadingReport
                          ? Action.downloadingReport
                          : Action.downloadReport}
                      </Button>
                      <Itooltip
                        placement="top"
                        tooltipId={"download-match-report"}
                        tooltipContent={
                          Match.configure.downloadMatchWinningReport
                        }
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <DateRangeFilter
                    staticDateOption={staticDateOption}
                    dateChangeHandler={handleDateRangePub}
                    staticDateChangeHandler={handleStaticDateRangePub}
                    staticDateRange={staticDateRangePub}
                    dateRange={dateRangePub}
                  />
                </Col>
                <Col className="prize-disbursed-flag-container" xs={2}>
                  <div>
                    <input
                      type="checkbox"
                      checked={prizeDisbursed}
                      onChange={handlePrizeDisbursedChange}
                    />
                    <label htmlFor="prizeDisbursed">
                      &nbsp;{Match.published.prizeDisbursedLabel}
                    </label>
                  </div>
                </Col>
              </Row>
            </>
            <PublishMatchList
              publishedMatchList={publishedMatchList}
              setPubCurrentPage={setPubCurrentPage}
              setPubPageSize={setPubPageSize}
              pubPageSize={pubPageSize}
              pubPage={pubCurrentPage}
            />
          </Tab>
        </Tabs>
        <ReactToastr
          show={showToaster?.show}
          message={showToaster?.message}
          closeButton={true}
          title={showToaster?.heading}
          toastBg={showToaster?.variant}
          onClose={handleClose}
          position="top-center"
          autohide
        />
      </div>
    </>
  );
};

export default MatchList;

import { PrizeTypeEnum, QuestionsArrayLength } from "../../common/constants";
import * as Yup from "yup";

export const validateFlatTypeQuizPrizeConfig = (prizeArr, totalQuestions) => {
  if (prizeArr?.length !== totalQuestions) {
    throw new Error("Each question should have a reward amount");
  }
  const fromSet = new Set();

  for (const obj of prizeArr) {
    if (fromSet.has(obj.from)) {
      throw new Error("Duplicate question number found in PrizeConfig");
    }
    fromSet.add(obj.from);
  }

  return true;
};

export const defaultTTQQuestionConfig = (tierNumber = 1) => ({
  tierName: "",
  tierNumber,
  questionsCount: QuestionsArrayLength,
  questions: Array.from({ length: QuestionsArrayLength }, () => ({
    question: "",
    win_sort_order: "",
    eventType: "",
    options: Array.from({ length: 3 }, () => ({
      playerId: "",
    })),
  })),
  rewardType: PrizeTypeEnum.FLAT,
  teams: [],
});

export const generateQuestions = (
  values: any,
  count: number,
  configIndex: number,
) => {
  const quesArray = [...values?.questionConfiguration[configIndex]?.questions];
  if (count > quesArray.length) {
    const diff = count - quesArray.length;
    return [
      ...quesArray,
      ...Array.from({ length: diff }, (_, idx) => ({
        question: "",
        eventType: "",
        win_sort_order: "",
        options: Array.from({ length: 3 }, () => ({
          playerId: "",
          playerName: "",
        })),
      })),
    ];
  } else if (count < quesArray.length) {
    const diff = quesArray.length - count;
    return quesArray.slice(0, quesArray.length - diff);
  } else return quesArray;
};

export const QuizSchema = Yup.object().shape({
  weekLeague: Yup.number().required("gameWeek is required"),
  name: Yup.string().required("Quiz name is required"),
  questionConfiguration: Yup.array().of(
    Yup.object().shape({
      tierName: Yup.string().trim().required("Tier name is required"),
      questions: Yup.array().of(
        Yup.object().shape({
          eventType: Yup.string().required("Event Type is required"),
          win_sort_order: Yup.string().required("Win Order is required"),
          question: Yup.string()
            .required("Question is required")
            .test(
              "is-unique-question",
              "Each question must be unique",
              function (value) {
                const { questions } = this.options.context || {};
                if (!value || !questions) return true;

                return (
                  questions.filter((q) => q.question === value).length === 1
                );
              },
            ),
          options: Yup.array()
            .length(3, "Each question must have exactly 4 options")
            .of(
              Yup.object().shape({
                playerId: Yup.string().required("Option is required"),
                playerName: Yup.string().notRequired(),
              }),
            )
            .test(
              "unique-playerIds",
              "Player IDs in options must be unique for the question.",
              function (options) {
                if (!options || options.length !== 3) return true;
                if (options.some((option) => !option.playerId)) return true;
                const playerIds = options.map((option) => option.playerId);
                const uniquePlayerIds = new Set(playerIds);
                if (playerIds.length !== uniquePlayerIds.size) {
                  return false;
                }
                return true;
              },
            ),
        }),
      ),
      rewardType: Yup.string().required("Reward type is required"),
    }),
  ),
});

export const gameweekOptions = [
  {
    label: "Game Week 29",
    value: 29,
  },
  {
    label: "Game Week 30",
    value: 30,
  },
  {
    label: "Game Week 31",
    value: 31,
  },
  {
    label: "Game Week 32",
    value: 32,
  },
  {
    label: "Game Week 33",
    value: 33,
  },
  {
    label: "Game Week 34",
    value: 34,
  },
  {
    label: "Game Week 35",
    value: 35,
  },
  {
    label: "Game Week 36",
    value: 36,
  },
  {
    label: "Game Week 37",
    value: 37,
  },
  {
    label: "Game Week 38",
    value: 38,
  },
];

export interface ITeam {
  shortCode: string;
  teamColors: string[];
  shirtNumberColors: null | string[];
  teamHomeJerseyUrl: null | string;
  logoUrl: string;
}

export interface IPlayer {
  id: string;
  name: string;
  futyPosition: string;
  playerPosition: string;
  shirtNumber: string;
  profileImageUrl: string;
  firstName: string;
  lastName: string;
  teamId: string;
}

import { Button, Col, Image, Row } from "react-bootstrap";
import Typography from "../../../common/components/Typography";
import { Form as BsForm } from "react-bootstrap";
import { Field, FieldArray, FormikErrors, FormikTouched } from "formik";
import Select from "react-select";
import {
  CreateTaskValues,
  currencyOptions,
  gameModesOptions,
  iconRedirectionUrlMap,
  MatchStatus,
  Option,
  RewardCurrencyEnum,
  TaskGroupsEnum,
  taskIcons,
  taskIconsEnum,
  taskLogoMap,
  TaskValue,
  trackingTypeOptions,
  TrackingTypesEnum,
} from "../../tasksUtils";
import { useEffect, useRef, useState } from "react";
import {
  autoFillRedirectionUrl,
  fetchFantasyMatches,
  fetchGoalfestGameweeks,
  fetchQuizMatches,
  fetchTaskImagePresignedUrl,
} from "../../service";
import {
  Action,
  GameModesEnum,
  max_photo_size,
} from "../../../common/constants";
import NoImage from "../../../common/assets/NoImage.svg";
import { uploadBannerToPresignedUrl } from "../../../Trivia/components/service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";

const customOptions = (option) => (
  <div className="d-flex align-items-center">
    <img
      src={taskLogoMap[option.value]}
      alt={option.label}
      style={{ width: 20, height: 20, marginRight: 10 }}
    />
    {option.label}
  </div>
);

interface ITaskField {
  idx: number;
  task: TaskValue;
  errors: any;
  values: CreateTaskValues;
  touched: FormikTouched<CreateTaskValues>;
  setValues: (
    values: React.SetStateAction<CreateTaskValues>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CreateTaskValues>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CreateTaskValues>>;
  setFieldError: (field: string, message: string | undefined) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CreateTaskValues>>;
  remove: (idx: number) => void;
  maxTier: number;
  setShowToaster: React.Dispatch<React.SetStateAction<any>>;
  isEdit: boolean;
  oldImageUrl: string | null;
}

const TaskFields: React.FC<ITaskField> = ({
  idx,
  task,
  errors,
  values,
  touched,
  setValues,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  setShowToaster,
  isEdit,
  oldImageUrl,
}) => {
  const gameModes = values.tasks[idx].gameModes as GameModesEnum[];
  const [isLoadingMatches, setIsLoadingMatches] = useState<boolean>(false);
  const [matchOptions, setMatchOptions] = useState<Option[]>([]);

  const gameImageInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState<string>("");
  const [presignUrl, setPresignUrl] = useState<string>("");
  const [image, setImage] = useState(null);
  const [gameImage, setGameImage] = useState(null);
  const [isUploadGameImage, setIsUploadGameImage] = useState(false);

  const getPresignedUrl = async () => {
    setIsUploadGameImage(true);
    const resData: any = await fetchTaskImagePresignedUrl(fileName);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      if (resData?.data?.data) {
        setPresignUrl(resData?.data?.data?.presignUrl);
      }
    } else {
      setIsUploadGameImage(false);
      setImage(gameImage);
      if (resData?.status !== CustomStatusCode.sessionExpire) {
      }
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: resData?.message ?? "Something went wrong, please try again",
        variant: "danger",
      }));
    }
  };

  const handleGameImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > max_photo_size) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: "Please upload an image smaller than 2MB.",
          variant: "danger",
        }));
        return;
      }
      setFile(selectedFile);
      setFileName(selectedFile.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleGameImageClick = () => {
    gameImageInputRef.current.click();
  };

  const handleGameImageUpload = async () => {
    if (!file) {
      setIsUploadGameImage(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "Please select an image",
        variant: "danger",
      }));
      return;
    }
    try {
      const resData = await uploadBannerToPresignedUrl(presignUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      if (resData?.status === HttpStatus.REQUEST_SUCCEEDED) {
        setGameImage(presignUrl);
        setIsUploadGameImage(false);
        setFieldValue(`tasks.${idx}.iconUrl`, presignUrl, true);
      } else {
        setIsUploadGameImage(false);
        setImage(gameImage);
        if (resData?.status !== CustomStatusCode.sessionExpire) {
        }
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
      }
    } catch (error) {
      setIsUploadGameImage(false);
      setImage(gameImage);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "Error uploading team logo",
        variant: "danger",
      }));
    }
  };

  useEffect(() => {
    if (presignUrl) {
      handleGameImageUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presignUrl]);

  useEffect(() => {
    if (fileName) {
      getPresignedUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingMatches(true);
      setMatchOptions([]);

      const fetchPromises = [];
      if (gameModes?.includes(GameModesEnum.TG_TRIPLE_THREAT)) {
        fetchPromises.push(
          fetchQuizMatches({
            isPublished: true,
            status: [MatchStatus.SCHEDULED],
          }),
        );
      }

      if (gameModes?.includes(GameModesEnum.TG_GOALFEST)) {
        fetchPromises.push(
          fetchGoalfestGameweeks({
            query: {
              isPublished: true,
              status: [MatchStatus.SCHEDULED],
            },
          }),
        );
      }

      if (gameModes?.includes(GameModesEnum.TG_FANTASY)) {
        fetchPromises.push(
          fetchFantasyMatches({
            query: {
              isPublished: true,
              contestType: GameModesEnum.TG_FANTASY,
              status: [MatchStatus.SCHEDULED],
            },
          }),
        );
      }

      const results = await Promise.all(fetchPromises);
      setMatchOptions(results.flat());
      setIsLoadingMatches(false);
    };

    fetchData();
  }, [gameModes]);

  useEffect(() => {
    if (oldImageUrl && isEdit) {
      setImage(oldImageUrl);
      setGameImage(oldImageUrl);
    }
  }, [oldImageUrl, isEdit]);

  const isIconSocial = (icon: taskIconsEnum) =>
    [
      taskIconsEnum.INSTAGRAM,
      taskIconsEnum.TELEGRAM,
      taskIconsEnum.X,
      taskIconsEnum.YOUTUBE,
    ].includes(icon);

  const getFilteredTrackingTypeOptions = (taskGroup: TaskGroupsEnum) => {
    if (taskGroup === TaskGroupsEnum.MILESTONE) {
      return trackingTypeOptions.filter(
        (option) => option.value === TrackingTypesEnum.GAME_PARTICIPATION,
      );
    }
    if (taskGroup !== TaskGroupsEnum.DAILY_TASK) {
      return trackingTypeOptions.filter(
        (option) => option.value !== TrackingTypesEnum.REFERRAL,
      );
    }
    return trackingTypeOptions;
  };

  const filteredTrackingTypeOptions = getFilteredTrackingTypeOptions(
    values?.taskGroup as TaskGroupsEnum,
  );

  return (
    <section className="border p-2 rounded mb-3">
      <Typography variant="heading3">Tier {idx + 1}</Typography>
      <Row>
        <Col md={4}>
          <BsForm.Label>Task Title</BsForm.Label>
          <Field
            type="text"
            name={`tasks.${idx}.title`}
            className="form-control"
          />
          {errors.tasks?.at(idx)?.title && touched.tasks?.at(idx)?.title ? (
            <Typography className="text-danger fs-10">
              {errors.tasks[idx].title}
            </Typography>
          ) : null}
        </Col>
        <Col md={4}>
          <BsForm.Label>Tier Number</BsForm.Label>
          <Field
            type="number"
            name={`tasks.${idx}.tierNumber`}
            className="form-control"
            value={idx + 1}
            disabled={true}
          />
        </Col>
      </Row>

      <Row className="d-flex my-3 align-items-end justify-content-between">
        <Col md={2}>
          <BsForm.Label>Select Icon</BsForm.Label>

          {values?.taskGroup === TaskGroupsEnum.P2E_GAME ? (
            <div>
              <div
                style={{ alignItems: "center" }}
                className="d-flex flex-column"
              >
                {image ? (
                  <Image src={image} width={100} height={100} alt="game logo" />
                ) : (
                  <Image
                    src={NoImage}
                    width={100}
                    height={100}
                    alt="logo"
                    onClick={handleGameImageClick}
                    thumbnail
                  />
                )}
                <Button
                  className=""
                  onClick={handleGameImageClick}
                  disabled={isUploadGameImage}
                  variant="link"
                >
                  {image
                    ? isUploadGameImage
                      ? Action.updating
                      : Action.update
                    : isUploadGameImage
                    ? Action.uploading
                    : Action.upload}
                </Button>
                <BsForm.Group style={{ display: "none" }}>
                  <BsForm.Label>Upload Image</BsForm.Label>
                  <BsForm.Control
                    ref={gameImageInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleGameImageChange}
                  />
                </BsForm.Group>
              </div>
            </div>
          ) : (
            <>
              <Select
                name={`tasks.${idx}.iconUrl`}
                options={taskIcons}
                placeholder="Select icon"
                defaultValue={taskIcons[0]}
                value={taskIcons.find(
                  (option) => option.value === task.iconUrl,
                )}
                onChange={(option) => {
                  setFieldValue(
                    `tasks.${idx}.iconUrl`,
                    option ? option.value : "",
                  );
                  if (isIconSocial(option.value as taskIconsEnum)) {
                    setFieldError(
                      `tasks.${idx}.redirectionUrl`,
                      `Hint: URL must start with ${
                        iconRedirectionUrlMap[option.label]
                      }`,
                    );
                    setFieldTouched(`tasks.${idx}.redirectionUrl`, true, false);
                  } else {
                    setFieldError(`tasks.${idx}.redirectionUrl`, undefined);
                  }
                  setFieldError(`tasks.${idx}.iconUrl`, undefined);
                }}
                formatOptionLabel={customOptions}
                styles={{
                  menu: (base, props) => ({
                    ...base,
                    zIndex: 300,
                  }),
                }}
              />
            </>
          )}
          {errors.tasks?.at(idx)?.iconUrl && touched.tasks?.at(idx)?.iconUrl ? (
            <Typography className="text-danger fs-10">
              {errors.tasks[idx].iconUrl}
            </Typography>
          ) : null}
        </Col>
        <Col md={3} className="position-relative">
          <BsForm.Label>Redirection URL</BsForm.Label>
          <Field
            type="text"
            name={`tasks.${idx}.redirectionUrl`}
            disabled={
              values?.tasks[idx]?.isInAppUrl &&
              values?.tasks?.[idx]?.trackingDetails?.trackingType ===
                TrackingTypesEnum.GAME_PARTICIPATION
            }
            className="form-control"
          />
          {values?.tasks[idx]?.isInAppUrl ? (
            <Typography className="fs-10 position-absolute">
              Hint: enter in-game url e.g:"/game"
            </Typography>
          ) : (
            errors.tasks?.at(idx)?.redirectionUrl &&
            touched.tasks?.at(idx)?.redirectionUrl && (
              <Typography className="text-danger fs-10 position-absolute">
                {errors.tasks[idx].redirectionUrl}
              </Typography>
            )
          )}
        </Col>
        <Col md={2} className="position-relative">
          <BsForm.Check
            type="checkbox"
            label="isInAppUrl"
            name={`tasks.${idx}.isInAppUrl`}
            checked={task?.isInAppUrl}
            onChange={(e) => {
              setFieldValue(`tasks.${idx}.isInAppUrl`, !task?.isInAppUrl);
              if (e?.target?.checked) {
                setFieldValue(`tasks.${idx}.isTrackable`, true);
              } else {
                if (values?.tasks?.at(idx)?.redirectionUrl) {
                  setFieldError(`tasks.${idx}.redirectionUrl`, undefined);
                }
              }
            }}
            disabled={values.taskGroup === TaskGroupsEnum.P2E_GAME}
          />
          {errors.tasks?.at(idx)?.isInAppUrl &&
            touched.tasks?.at(idx)?.isInAppUrl && (
              <Typography className="text-danger fs-10 position-absolute">
                {errors.tasks?.at(idx)?.isInAppUrl}
              </Typography>
            )}
        </Col>
        <Col md={2} className="position-relative">
          <BsForm.Check
            type="checkbox"
            label="isTrackable"
            name={`tasks.${idx}.isTrackable`}
            checked={task.isTrackable}
            onChange={() =>
              setFieldValue(`tasks.${idx}.isTrackable`, !task.isTrackable)
            }
            disabled={values.taskGroup === TaskGroupsEnum.P2E_GAME}
          />
          {errors.tasks?.at(idx)?.isTrackable &&
            touched.tasks?.at(idx)?.isTrackable && (
              <Typography className="text-danger fs-10 position-absolute">
                {errors.tasks?.at(idx)?.isTrackable}
              </Typography>
            )}
        </Col>
        <Col md={2} className="position-relative">
          <BsForm.Check
            type="checkbox"
            label="isRepeatable"
            disabled={values?.taskGroup !== TaskGroupsEnum.DAILY_TASK}
            name={`tasks.${idx}.isRepeatable`}
            checked={task.isRepeatable}
            onChange={() =>
              setFieldValue(`tasks.${idx}.isRepeatable`, !task.isRepeatable)
            }
          />
          {errors.tasks?.at(idx)?.isRepeatable &&
            touched.tasks?.at(idx)?.isRepeatable && (
              <Typography className="text-danger fs-10 position-absolute">
                {errors.tasks?.at(idx)?.isRepeatable}
              </Typography>
            )}
        </Col>
      </Row>

      <FieldArray name={`tasks.${idx}.rewards`}>
        {({ push, remove }) => (
          <div className="border p-2 rounded my-3">
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={10}>
                <Typography variant="heading4">Rewards Config</Typography>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  onClick={() =>
                    push({
                      amount: 0,
                      currency: RewardCurrencyEnum.FUTY_CREDITS,
                      rewardAfterMins: 0,
                    })
                  }
                >
                  Add Reward
                </Button>
              </Col>
            </Row>
            {task.rewards.map((_, index) => (
              <Row key={index} className="p-3 rounded d-flex align-items-end">
                <BsForm.Label className="text-black">
                  Reward {index + 1}
                </BsForm.Label>

                <Col md={3} className="position-relative">
                  <BsForm.Label>Amount</BsForm.Label>
                  <Field
                    type="number"
                    name={`tasks.${idx}.rewards.${index}.amount`}
                    className="form-control"
                    placeholder="Amount"
                  />
                  {errors.tasks?.at(idx)?.rewards?.at(index)?.amount &&
                    touched.tasks?.at(idx)?.rewards?.at(index)?.amount && (
                      <Typography className="text-danger fs-10 position-absolute">
                        {errors.tasks?.at(idx)?.rewards?.at(index)?.amount}
                      </Typography>
                    )}
                </Col>
                <Col md={3} className="position-relative">
                  <BsForm.Label>Currency</BsForm.Label>
                  <Select
                    options={currencyOptions}
                    name={`tasks.${idx}.rewards.${index}.currency`}
                    className="select-filter border-0"
                    placeholder="Currency"
                    value={currencyOptions.find(
                      (option) =>
                        option.value === task?.rewards?.at(index)?.currency,
                    )}
                    onChange={(selectedOption) => {
                      setFieldValue(
                        `tasks.${idx}.rewards.${index}.currency`,
                        selectedOption ? selectedOption.value : "",
                      );
                      setFieldError(
                        `tasks.${idx}.rewards.${index}.currency`,
                        undefined,
                      );
                    }}
                    styles={{
                      menu: (base, props) => ({
                        ...base,
                        zIndex: 300,
                      }),
                    }}
                  />
                  {errors.tasks?.at(idx)?.rewards?.at(index)?.currency &&
                    touched.tasks?.at(idx)?.rewards?.at(index)?.currency && (
                      <Typography className="text-danger fs-10 position-absolute">
                        {errors.tasks?.at(idx)?.rewards?.at(index)?.currency}
                      </Typography>
                    )}
                </Col>
                <Col md={3} className="position-relative">
                  <BsForm.Label>RewaredAfterMins</BsForm.Label>
                  <Field
                    type="number"
                    name={`tasks.${idx}.rewards.${index}.rewardAfterMins`}
                    className="form-control"
                    placeholder="Delay in minutes"
                  />
                </Col>
                {values.tasks.at(idx).rewards.length > 1 && (
                  <Col md={3}>
                    <Button variant="danger" onClick={() => remove(index)}>
                      Remove Reward
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
          </div>
        )}
      </FieldArray>

      <Row className="my-3">
        <Typography variant="heading4">Tracking details</Typography>
        <Col>
          <Select
            name={`tasks.${idx}.trackingDetails.trackingType`}
            placeholder="Select tracking type"
            options={filteredTrackingTypeOptions}
            isDisabled={values.taskGroup === TaskGroupsEnum.P2E_GAME}
            value={
              values.taskGroup === TaskGroupsEnum.P2E_GAME
                ? trackingTypeOptions[0]
                : filteredTrackingTypeOptions?.find(
                    (option) =>
                      option.value === task.trackingDetails.trackingType,
                  )
            }
            onChange={(selectedOption) => {
              setFieldValue(
                `tasks.${idx}.trackingDetails.trackingType`,
                selectedOption ? selectedOption.value : "",
              );
              setFieldError(
                `tasks.${idx}.trackingDetails.trackingType`,
                undefined,
              );
              if (
                selectedOption?.value === TrackingTypesEnum.GAME_PARTICIPATION
              ) {
                setFieldValue(`tasks.${idx}.isInAppUrl`, true);
                setFieldValue(`tasks.${idx}.isTrackable`, true);
                setFieldError(
                  `tasks.${idx}.gameModes`,
                  "Please select game modes",
                );
              } else {
                setFieldValue(`tasks.${idx}.isInAppUrl`, false);
                setFieldValue(`tasks.${idx}.isTrackable`, false);
                setFieldError(`tasks.${idx}.gameModes`, undefined);
              }
            }}
            styles={{
              menu: (base, props) => ({
                ...base,
                zIndex: 300,
              }),
            }}
          />
          {errors.tasks?.at(idx)?.trackingDetails?.trackingType &&
          touched.tasks?.at(idx)?.trackingDetails?.trackingType ? (
            <Typography className="text-danger fs-10">
              {errors.tasks?.at(idx)?.trackingDetails?.trackingType}
            </Typography>
          ) : null}
        </Col>
      </Row>

      {task?.trackingDetails?.trackingType === TrackingTypesEnum.REFERRAL ? (
        <>
          <Row className="my-3">
            <Col md={3}>
              <BsForm.Label>Referral Count</BsForm.Label>
              <Field
                type="number"
                name={`tasks.${idx}.trackingDetails.referralCount`}
                className="form-control"
                placeholder="Total Matches Count"
                min={0}
              />

              {errors.tasks?.at(idx)?.trackingDetails?.referralCount && (
                <Typography className="fs-10 text-danger">
                  {errors.tasks?.at(idx)?.trackingDetails?.referralCount}
                </Typography>
              )}
            </Col>
          </Row>
        </>
      ) : (
        (task.isTrackable ||
          task?.trackingDetails?.trackingType ===
            TrackingTypesEnum.GAME_PARTICIPATION) && (
          <>
            <Row>
              <Col md={4}>
                <BsForm.Group>
                  <Select
                    isMulti
                    name={`tasks.${idx}.gameModes`}
                    placeholder="Select Game modes"
                    options={gameModesOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPlacement="top"
                    value={gameModesOptions.filter((option) =>
                      task.gameModes?.includes(option.value),
                    )}
                    onChange={(selectedOptions) => {
                      const updatedValues = { ...values };
                      const gameModes = selectedOptions?.length
                        ? selectedOptions.map((option) => option.value)
                        : null;
                      // update gameModes array
                      updatedValues.tasks[idx].gameModes = gameModes;

                      // clear previously selected matches (if any)
                      updatedValues.tasks[idx].trackingDetails.matchIds = [];
                      updatedValues.tasks[idx].trackingDetails.gameIds = [];

                      if (updatedValues.tasks[idx]?.isInAppUrl) {
                        updatedValues.tasks[idx].redirectionUrl =
                          autoFillRedirectionUrl(
                            gameModes,
                            updatedValues.tasks[idx].trackingDetails?.gameIds ??
                              [],
                          );
                      }

                      setValues(updatedValues);
                    }}
                    onBlur={() => {
                      const updatedValues = { ...values };
                      if (!updatedValues.tasks[idx]?.gameModes?.length) {
                        setFieldError(
                          `tasks.${idx}.gameModes`,
                          "Please select game modes",
                        );
                      } else {
                        setFieldError(`tasks.${idx}.gameModes`, undefined);
                      }
                    }}
                    styles={{
                      menu: (base, props) => ({
                        ...base,
                        zIndex: 300,
                      }),
                    }}
                  />
                  {errors.tasks?.at(idx)?.gameModes && (
                    <Typography className="fs-10 text-danger">
                      {errors.tasks?.at(idx)?.gameModes}
                    </Typography>
                  )}
                </BsForm.Group>
              </Col>
              <Col md={4}>
                <Select
                  isMulti
                  isLoading={isLoadingMatches}
                  name={`tasks.${idx}.trackingDetails.matchIds`}
                  placeholder="Select matches"
                  options={matchOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={matchOptions?.filter((option) =>
                    task?.trackingDetails?.matchIds?.includes(option.value),
                  )}
                  isDisabled={task.trackingDetails.isAnyMatch}
                  menuPlacement="top"
                  styles={{
                    menu: (base, props) => ({
                      ...base,
                      zIndex: 300,
                    }),
                  }}
                  onChange={(selectedOptions) => {
                    const matchIds = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];

                    const gameIds = selectedOptions
                      ? selectedOptions.map((option) => option.optional)
                      : [];

                    const gameModesArr = selectedOptions
                      ? (selectedOptions.map((option) => {
                          return option?.label?.split("-")[0];
                        }) as GameModesEnum[])
                      : [];

                    const updatedValues = { ...values };

                    updatedValues.tasks[idx].trackingDetails.matchIds =
                      matchIds;
                    updatedValues.tasks[idx].trackingDetails.gameIds = gameIds;
                    updatedValues.tasks[idx].trackingDetails.gameModesArr =
                      gameModesArr;
                    updatedValues.tasks[idx].trackingDetails.totalMatchesCount =
                      matchIds.length;

                    if (values?.tasks[idx]?.isInAppUrl) {
                      updatedValues.tasks[idx].redirectionUrl =
                        autoFillRedirectionUrl(
                          updatedValues.tasks[idx].gameModes ?? [],
                          gameIds,
                        );
                    }

                    setValues(updatedValues);
                  }}
                />
              </Col>
              <Col>
                <BsForm.Check
                  type="checkbox"
                  label="isAnyMatch"
                  name={`tasks.${idx}.trackingDetails.isAnyMatch`}
                  checked={task.trackingDetails.isAnyMatch}
                  className="pt-2"
                  onChange={(e) => {
                    const updatedValues = { ...values };
                    if (e.target.checked) {
                      updatedValues.tasks[
                        idx
                      ].trackingDetails.totalMatchesCount = 1;
                      if (
                        !updatedValues.tasks[idx]?.gameModes?.length ||
                        updatedValues.tasks[idx]?.gameModes?.length === 0
                      ) {
                        setFieldError(
                          `tasks.${idx}.gameModes`,
                          "Please select game modes",
                        );
                      }
                    } else {
                      updatedValues.tasks[
                        idx
                      ].trackingDetails.totalMatchesCount = 0;
                      setFieldError(`tasks.${idx}.gameModes`, undefined);
                    }
                    const isAnyMatch = !task.trackingDetails.isAnyMatch;
                    updatedValues.tasks[idx].trackingDetails.isAnyMatch =
                      isAnyMatch;
                    updatedValues.tasks[idx].trackingDetails.matchIds = [];
                    updatedValues.tasks[idx].trackingDetails.gameIds = [];
                    updatedValues.tasks[idx].trackingDetails.gameModesArr =
                      null;

                    if (updatedValues.tasks[idx]?.isInAppUrl) {
                      updatedValues.tasks[idx].redirectionUrl =
                        autoFillRedirectionUrl(
                          updatedValues.tasks[idx].gameModes ?? [],
                          updatedValues.tasks[idx].trackingDetails?.gameIds ??
                            [],
                        );
                    }
                    setValues(updatedValues);
                  }}
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={3}>
                <BsForm.Label>Total Matches Count</BsForm.Label>
                <Field
                  type="number"
                  name={`tasks.${idx}.trackingDetails.totalMatchesCount`}
                  className="form-control"
                  placeholder="Total Matches Count"
                  min={0}
                  disabled={!task?.trackingDetails?.isAnyMatch}
                  validate={(value) => {
                    if (idx === 0 && value <= 0) {
                      return "Tier 1 Matches Count must be greater than 0";
                    }

                    if (
                      idx > 0 &&
                      value <=
                        values.tasks[idx - 1].trackingDetails.totalMatchesCount
                    ) {
                      return `Count must be greater than Tier ${idx} value (${
                        values.tasks[idx - 1].trackingDetails.totalMatchesCount
                      })`;
                    }

                    return undefined;
                  }}
                />

                {errors.tasks?.at(idx)?.trackingDetails?.totalMatchesCount && (
                  <Typography className="fs-10 text-danger">
                    {errors.tasks?.at(idx)?.trackingDetails?.totalMatchesCount}
                  </Typography>
                )}
              </Col>
            </Row>
          </>
        )
      )}
    </section>
  );
};

export default TaskFields;

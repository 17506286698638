import React, { useState } from "react";
import { FormControl } from "react-bootstrap";

const TierNameInput = ({
  configIndex,
  setFieldValue,
  isPublished,
  setFieldTouched,
  tierName = "",
}) => {
  const [tierNameInput, setTierNameInput] = useState(tierName);
  return (
    <FormControl
      className="form-control p-2 rounded"
      name={`questionConfiguration.${configIndex}.tierName`}
      placeholder="Tier name"
      disabled={isPublished}
      onChange={(e) => setTierNameInput(e.target.value)}
      value={tierNameInput}
      onBlur={(e) => {
        setFieldTouched(`questionConfiguration.${configIndex}.tierName`, true);
        setFieldValue(
          `questionConfiguration.${configIndex}.tierName`,
          e.target.value,
          true,
        );
      }}
    />
  );
};

export default TierNameInput;

import { useState } from "react";
import { HttpStatus } from "../../../../../utils/constants";
import {
  compareDay,
  createDayPrize,
  deleteDayPrize,
  updateDayPrize,
} from "../../service";
import {
  DayRewardType,
  initialDayReward,
  initialRewardConfig,
  ITriggerToaster,
} from "../../types";
import { Button, Col, Form as F, Row } from "react-bootstrap";
import Select from "react-select";
import { Tasks } from "../../../../constants";
import { taskTypeOptions } from "./options";
import RewardConfig from "./RewardConfig";
import "./index.scss";
import ConfirmationModal from "../../../Modal/ConfirmationModel";
import * as Yup from "yup";
import { FieldArray, Form, Formik, FormikErrors } from "formik";
interface DayRowProps {
  dayNumber?: number;
  isNew: boolean;
  dayReward?: DayRewardType;
  streakMap: Record<number, DayRewardType>;
  triggerToast: (payload: ITriggerToaster) => void;
  closeNewDayRow?: () => void;
  refresh: () => void;
}

const validationSchema = Yup.object().shape({
  day: Yup.number()
    .required("Day is required")
    .min(1, "Day must be greater than 0")
    .max(366, "Day cannot exceed 366"),
  taskType: Yup.string().required("Task type is required"),
  rewardConfig: Yup.array()
    .of(
      Yup.object().shape({
        amount: Yup.number()
          .required("Amount is required")
          .min(1, "Amount must be greater than 0")
          .max(1000000, "Amount cannot exceed 1000000"),
        category: Yup.string().required("Category is required"),
        currency: Yup.string().required("Currency is required"),
      }),
    )
    .min(1, "At least one reward is required"),
});

const DayRewardsRow: React.FC<DayRowProps> = ({
  dayNumber,
  isNew,
  dayReward,
  streakMap,
  triggerToast,
  closeNewDayRow,
  refresh,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const handleDeleteDayReward = async (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => Promise<void | FormikErrors<DayRewardType>>,
  ) => {
    try {
      const res = await deleteDayPrize({
        id: dayReward?.id,
      });

      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        refresh();
        setFieldValue("day", "");
        triggerToast({
          message: "Day deleted",
          variant: "success",
        });
      } else {
        throw new Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      triggerToast({
        message: error.message,
        variant: "danger",
      });
    }
  };

  const handleSubmit = async (values: DayRewardType) => {
    try {
      const res = isNew
        ? await createDayPrize(values)
        : await updateDayPrize(values);

      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        refresh();
        if (isNew) closeNewDayRow?.();
        triggerToast({
          message: `Day ${isNew ? "created" : "updated"}`,
          variant: "success",
        });
      } else {
        throw new Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      triggerToast({
        message: error.message,
        variant: "danger",
      });
    }
  };

  const disableFields = !isNew && !!streakMap[dayNumber];

  return (
    <Formik
      initialValues={dayReward ?? initialDayReward}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
      enableReinitialize
    >
      {({ values, setFieldValue, errors, touched, dirty }) => {
        return (
          <Form>
            <div
              style={{
                backgroundColor: isNew
                  ? "rgba(60, 255, 0, 0.23)"
                  : "transparent",
              }}
              className="py-4 position-relative"
            >
              <Row>
                {isNew && (
                  <span className="new-day-label">
                    Add reward details for new day
                  </span>
                )}

                <Col lg={2}>
                  <F.Control
                    type="number"
                    name="day"
                    value={values.day ?? ""}
                    placeholder="Day"
                    className="button-height"
                    disabled={disableFields}
                    onChange={(e) => {
                      const numericValue = +e.target.value;
                      setFieldValue("day", numericValue);
                    }}
                    isInvalid={touched.day && !!errors.day}
                  />
                  {touched.day && errors.day && (
                    <F.Control.Feedback type="invalid">
                      {errors.day}
                    </F.Control.Feedback>
                  )}
                </Col>

                <Col lg={2}>
                  <Select
                    placeholder="Task Type"
                    menuPlacement="auto"
                    options={taskTypeOptions}
                    value={taskTypeOptions.find(
                      (opt) => opt.value === values.taskType,
                    )}
                    onChange={(selected) => {
                      setFieldValue("taskType", selected?.value);
                    }}
                    isDisabled={disableFields}
                    styles={{
                      control: (p) => ({
                        ...p,
                        ...(disableFields && { backgroundColor: "#f3efee" }),
                      }),
                    }}
                  />
                </Col>

                <Col lg={1}></Col>

                <Col lg={6}>
                  <FieldArray name="rewardConfig">
                    {({ push, remove }) => (
                      <div className="d-flex flex-column gap-4">
                        {values.rewardConfig.map((rc, idx) => (
                          <RewardConfig
                            key={idx}
                            idx={idx}
                            rcLength={values.rewardConfig.length}
                            deleteReward={() => remove(idx)}
                          />
                        ))}
                        <Button onClick={() => push(initialRewardConfig)}>
                          Add Reward
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Col>
              </Row>

              <div className="d-flex gap-2 buttons-wrapper">
                {isNew ? (
                  <>
                    <Button type="submit">{Tasks.create}</Button>
                    <Button onClick={closeNewDayRow}>{Tasks.cancel}</Button>
                  </>
                ) : (
                  <>
                    {streakMap[dayNumber] ? (
                      <>
                        <Button
                          type="submit"
                          disabled={
                            !dirty || compareDay(values, streakMap[values.day])
                          }
                        >
                          {Tasks.save}
                        </Button>
                        <Button onClick={() => setShowConfirmationModal(true)}>
                          {Tasks.delete}
                        </Button>
                      </>
                    ) : (
                      <Button type="submit">{Tasks.create}</Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="line" />
            <ConfirmationModal
              variant="alert"
              show={showConfirmationModal}
              title={`Are you sure, you want to delete the day?`}
              handleClick={() => {
                handleDeleteDayReward(setFieldValue);
              }}
              handleClose={() => setShowConfirmationModal(false)}
              secondaryBtnText="Cancel"
              handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
              btnText="Delete"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default DayRewardsRow;

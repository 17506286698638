import { ColumnDef } from "@tanstack/react-table";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import "./style.scss";
import React, { useState, useEffect } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  IAddReferralData,
  addReferral,
  deactivateReferral,
  fetchReferrals,
} from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import Typography from "../../../common/components/Typography";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import { EMPTY_ERROR } from "../../../common/constants";

export const Referral = {
  code: "Referral Code",
  description: "Description",
  createdAt: "Date Created",
  count: "Users Joined",
  bonusRedeemed: "Bonus Redeemed",
  status: "Status",
  action: "Action",
  credits: "Credits",
  deactivate: "Deactivate",
  referralType: "Referral type",
  message: "Message",
  createdUserTeams: "Created User Teams",
  //for telegram referral
  username: "Username",
  name: "Name",
  referralCount: "Referral Count",
  source: "Source",
};

export function formatDate(datetime_string: string): string {
  const dt_object = new Date(datetime_string);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  return dt_object.toLocaleDateString("en-US", options);
}

export const ReferralsTable = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showReactivateModal, setShowReactivateModal] =
    useState<boolean>(false);
  const [isReferralsLoading, setIsReferralsLoading] = useState<boolean>(false);
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [deactivatingReferral, setDeactivatingReferral] = useState<any>({
    code: null,
    description: "",
    id: null,
  });
  const [isReactivating, setIsReactivating] = useState<boolean>(false);
  const [reactivatingReferral, setReactivatingReferral] = useState<any>();
  const [referralList, setReferralList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });

  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const getReferralsList = async () => {
    setIsReferralsLoading(true);
    const resData: any = await fetchReferrals();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setReferralList((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data?.promotions,
      }));
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
    setIsReferralsLoading(false);
  };

  const reactivateReferral = async () => {
    setIsReactivating(true);
    const referralData: IAddReferralData = {
      code: reactivatingReferral.code,
      // TODO: Need to make it dynamic once we have more details on type
      type: reactivatingReferral?.type,
      isBonus: !!reactivatingReferral?.isBonus,
      message: reactivatingReferral?.message,
      description: reactivatingReferral.description,
      credits: 0,
    };
    const resData: any = await addReferral(referralData);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      if (resData?.data?.data?.error)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.data?.data?.error ||
            "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
      else getReferralsList();
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
    setIsReactivating(false);
    setReactivatingReferral(null);
  };

  const deactivatePromo = async () => {
    setIsDeactivating(true);
    const resData: any = await deactivateReferral(deactivatingReferral?.id);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      const deletedPromoId = resData?.data?.data?.id;
      if (deletedPromoId === deactivatingReferral?.id) {
        const existingReferrals = [...referralList?.data];
        const deletedPromo = existingReferrals.find(
          (promo) => promo?.id === deactivatingReferral?.id,
        );
        if (deletedPromo) {
          deletedPromo.isDeleted = true;
          setReferralList((pre) => ({
            ...pre,
            isLoading: false,
            data: existingReferrals,
          }));
        }
      }
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
    setDeactivatingReferral({});
    setIsDeactivating(false);
  };

  useEffect(() => {
    getReferralsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "code",
        id: "code",
        header: () => <span>{Referral.code}</span>,
        cell: (info) => info.getValue(),
        colWidth: 200,
      },
      {
        accessorKey: "description",
        id: "description",
        header: () => <span>{Referral.description}</span>,
        cell: (info) => info.getValue(),
        colWidth: 300,
      },
      {
        accessorKey: "type",
        id: "type",
        header: () => <span>{Referral.referralType}</span>,
        cell: (info) => info.getValue(),
        colWidth: 100,
      },
      {
        accessorKey: "message",
        id: "message",
        header: () => <span>{Referral.message}</span>,
        cell: (info) => info.getValue(),
        colWidth: 200,
      },
      {
        accessorKey: "isDeleted",
        id: "status",
        header: () => <span>{Referral.status}</span>,
        cell: (info) => (
          <span
            style={{
              color: info.getValue() ? "red" : "green",
            }}
          >
            {info.getValue() ? "Deactivated" : "Active"}
          </span>
        ),
        colWidth: 100,
      },
      {
        accessorKey: "createdAt",
        id: "createdAt",
        header: () => <span>{Referral.createdAt}</span>,
        cell: (info) => formatDate(info.getValue()),
        colWidth: 100,
      },

      {
        accessorKey: "Action",
        id: "action",
        header: () => <span>{Referral.action}</span>,
        colWidth: 500,
        cell: (info) => (
          <>
            {info?.row?.original?.isDeleted ? (
              <span>
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    setReactivatingReferral(info?.row?.original);
                    setShowReactivateModal(true);
                  }}
                  disabled={
                    isReactivating &&
                    info?.row?.original?.id === reactivatingReferral?.id
                  }
                >
                  {isReactivating &&
                  info?.row?.original?.id === reactivatingReferral?.id
                    ? "Reactivating..."
                    : "Reactivate"}
                </Button>
              </span>
            ) : (
              <span>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    setDeactivatingReferral(info?.row?.original);
                    setShowModal(true);
                  }}
                  disabled={
                    isDeactivating &&
                    info?.row?.original?.id === deactivatingReferral?.id
                  }
                >
                  {isDeactivating &&
                  info?.row?.original?.id === deactivatingReferral?.id
                    ? "Deactivating..."
                    : "Deactivate"}
                </Button>
              </span>
            )}
            <span className="edit-referral-button-style">
              <Button
                variant="primary"
                size="sm"
                disabled={info?.row?.original?.isDeleted}
                onClick={() => {
                  if (!info?.row?.original?.isDeleted)
                    navigate(`/referrals/${info?.row.original?.id}`, {
                      state: info?.row.original,
                    });
                }}
              >
                {"Edit"}
              </Button>
            </span>
          </>
        ),
        canSort: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      deactivatingReferral,
      isDeactivating,
      reactivatingReferral,
      isReactivating,
    ],
  );

  return isReferralsLoading ? (
    <div className="react-table-loader">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <>
      <Row className="mb-2 d-flex">
        <Col md="10">
          <Typography variant="pageTitle">Referrals</Typography>
        </Col>
        <Col md="2" className="add-btn">
          <Button
            variant="primary"
            onClick={() => {
              navigate("/referrals/new");
            }}
          >
            Add Referral
          </Button>
        </Col>
      </Row>
      <TableWrapper>
        <ReactTable
          isLoading={referralList?.isLoading}
          data={referralList?.data || []}
          columns={columns}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showModal}
        title={`Are you sure you want to deactivate referral code ${deactivatingReferral?.code}?`}
        handleClick={() => {
          deactivatePromo();
          setShowModal(false);
        }}
        handleClose={() => setShowModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowModal(false)}
        btnText="Confirm"
      />
      <ConfirmationModal
        variant="alert"
        show={showReactivateModal}
        title={`Are you sure you want to reactivate referral code ${reactivatingReferral?.code}?`}
        handleClick={() => {
          reactivateReferral();
          setShowReactivateModal(false);
        }}
        handleClose={() => setShowReactivateModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowReactivateModal(false)}
        btnText="Confirm"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};

import React from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Label,
  LineChart,
  Line,
  Text,
} from "recharts";

interface CustomTickProps {
  x?: number;
  y?: number;
  payload?: { value: string };
}
const CustomTick: React.FC<CustomTickProps> = ({ x = 0, y = 0, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        fontSize={12}
        transform="rotate(-90)"
      >
        {payload?.value}
      </Text>
    </g>
  );
};

const GraphView: React.FC<{ data: any }> = ({ data }) => {
  return (
    <>
      <div style={{ width: "100vw", height: "100vh" }}>
        <ResponsiveContainer width="100%" height="80%">
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, bottom: 150, left: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis angle={-45} dataKey="eventName" tick={<CustomTick />}>
              <Label
                fontWeight={600}
                value="Events"
                offset={-20}
                position="insideBottom"
              />
            </XAxis>
            <YAxis>
              <Label
                value="Event count"
                angle={-90}
                position="insideLeft"
                offset={10}
                style={{ textAnchor: "middle" }}
                fontWeight={600}
              />
            </YAxis>
            <Tooltip />
            <Line
              type="monotone"
              dataKey="count"
              stroke="#8884d8"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default GraphView;

import React, { useState, useCallback, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Button, Card, CardBody } from "react-bootstrap";
import { ColumnDef } from "@tanstack/react-table";
import { restrictAlpha } from "../../../utils/userUtil";
import {
  Action,
  GameModesEnum,
  Match,
  prizeConfigMessage,
} from "../../constants";
import { Delete } from "../../SVG";
import ReactTable from "../ReactTable/ReactTable";
import TableWrapper from "../ReactTable/TableWrapper";
import { showFailureToaster } from "../../../utils/apiUtil";
import "./style.scss";
import FlatRewardForm from "./FlatRewardConfig";

const PrizeConfig = ({
  isLoading,
  isError,
  errorMsg,
  prizeList,
  setPrizeList,
  isReadOnly,
  setShowToaster,
  isDisabledDeleteRow,
  isFromFieldDisabled = false,
  isToFieldDisabled = false,
  canSetDefaultToAndFromValue = false,
  isRewardTypeFlat = false,
  questionsCount = 5,
  isEditing = false,
  gameMode = GameModesEnum.TG_TRIPLE_THREAT,
}) => {
  const [newPrize, setNewPrize] = useState({ from: "", to: "", coins: "" });
  const [editingQuestionNumber, setEditingQuestionNumber] = useState(null);
  const [questionAmount, setQuestionAmount] = useState(0);
  const editingQuestionAmountRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (canSetDefaultToAndFromValue && !isReadOnly && prizeList?.length === 0) {
      setNewPrize({ from: "1", to: "1", coins: "" });
    } else {
      setNewPrize({ from: "", to: "", coins: "" });
    }
  }, [canSetDefaultToAndFromValue, isReadOnly, prizeList]);

  // Compute available question numbers by filtering out those already in the prizeList.
  const availableQuestionNumbers = React.useMemo(() => {
    return Array.from({ length: questionsCount }, (_, i) => i + 1)
      .filter((n) => !prizeList.some((prize) => parseInt(prize.from) === n))
      .sort((a, b) => b - a);
  }, [questionsCount, prizeList]);

  const handleDelete = useCallback(
    (deletedIndex) => {
      const filterPrizeList = prizeList?.filter(
        (_item, index) => index !== deletedIndex,
      );
      setPrizeList(filterPrizeList);
    },
    [setPrizeList, prizeList],
  );

  const handleNewPrizeConfig = (key, e) => {
    if (restrictAlpha(e.nativeEvent.data) || !e.nativeEvent.data) {
      setNewPrize((prevPrize) => ({ ...prevPrize, [key]: e.target.value }));
    }
  };

  const handleEditFlatTypeReward = () => {
    const newQuestionAmount = parseInt(editingQuestionAmountRef.current.value);
    if (isNaN(newQuestionAmount)) {
      setShowToaster(
        showFailureToaster(prizeConfigMessage.prizeShouldBeNumber),
      );
    } else if (newQuestionAmount < 0) {
      setShowToaster(showFailureToaster(prizeConfigMessage.prizeCantNegative));
    } else {
      const updatedPrizeList = prizeList?.map((prize) => {
        if (prize?.from === editingQuestionNumber) {
          return {
            ...prize,
            coins: newQuestionAmount,
          };
        }
        return prize;
      });
      setPrizeList(updatedPrizeList);
      setEditingQuestionNumber(null);
      setQuestionAmount(0);
    }
  };

  const FlatTypeColumns: ColumnDef<any, any>[] = [
    {
      accessorKey: "from",
      id: "from",
      header: () => (
        <span>
          {gameMode === GameModesEnum.TG_FANTASY
            ? Match.configure.correctSelectionNumber
            : Match.configure.correctQuestionNumber}
        </span>
      ),
      cell: (info) => info.getValue(),
      enableSorting: false,
    },
    {
      accessorKey: "coins",
      id: "coins",
      header: () => <span>{Match.configure.amountText}</span>,
      cell: (info) => {
        const question = parseInt(info?.row?.original?.from);
        return (
          <div>
            {question === editingQuestionNumber ? (
              <Form.Control
                className="form-control"
                type="number"
                ref={editingQuestionAmountRef}
                defaultValue={questionAmount}
                min={1}
              />
            ) : (
              <span>{info.getValue()}</span>
            )}
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "actions",
      cell: (info) => {
        const question = parseInt(info?.row?.original?.from);
        return (
          <div className="d-flex gap-2">
            {editingQuestionNumber && question === editingQuestionNumber ? (
              <Button onClick={() => handleEditFlatTypeReward()}>Save</Button>
            ) : (
              <>
                <Button
                  size="sm"
                  onClick={() => {
                    setEditingQuestionNumber(info?.row?.original?.from);
                    setQuestionAmount(info?.row?.original?.coins);
                  }}
                  className="text-nowrap"
                  disabled={isReadOnly}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  className="text-nowrap"
                  onClick={() => {
                    !isReadOnly && handleDelete(info?.row?.index);
                  }}
                  disabled={isReadOnly}
                >
                  Delete
                </Button>
              </>
            )}
          </div>
        );
      },
      enableSorting: false,
    },
  ];

  const prizeListColumns = React.useMemo<ColumnDef<any, any>[]>(() => {
    return [
      {
        accessorKey: "from",
        id: "from",
        header: () => <span>{Match.configure.fromText}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "to",
        id: "to",
        header: () => <span>{Match.configure.toText}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "coins",
        id: "coins",
        header: () => <span>{Match.configure.amountText}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "Action",
        id: "action",
        header: () => <span>{Match.configure.action}</span>,
        cell: (info) => {
          return (
            <Button
              disabled={isDisabledDeleteRow}
              size="sm"
              variant="link"
              onClick={() => {
                !isDisabledDeleteRow && handleDelete(info?.row?.index);
              }}
            >
              <Delete />
            </Button>
          );
        },
        canSort: false,
      },
    ];
  }, [handleDelete, isDisabledDeleteRow]);

  const handleAddPrize = () => {
    if (!isReadOnly) {
      const fromPrize = parseInt(newPrize.from);
      const toPrize = parseInt(newPrize.to);
      const coins = parseInt(newPrize.coins);
      if (isNaN(fromPrize) || isNaN(toPrize) || isNaN(coins)) {
        setShowToaster(
          showFailureToaster(prizeConfigMessage.prizeShouldBeNumber),
        );
      } else if (fromPrize < 0 || toPrize < 0 || coins < 0) {
        setShowToaster(
          showFailureToaster(prizeConfigMessage.prizeCantNegative),
        );
      } else if (fromPrize > toPrize) {
        setShowToaster(
          showFailureToaster(prizeConfigMessage.fromShouldLessThanTo),
        );
      } else {
        // Check for overlapping ranges
        const isOverlapping = prizeList.some((prize) => {
          const existingFrom = parseInt(prize.from);
          const existingTo = parseInt(prize.to);
          return (
            (fromPrize >= existingFrom && fromPrize <= existingTo) ||
            (toPrize >= existingFrom && toPrize <= existingTo) ||
            (fromPrize < existingFrom && toPrize > existingTo)
          );
        });

        if (isOverlapping) {
          setShowToaster(
            showFailureToaster(prizeConfigMessage.overlappingRanks),
          );
        } else {
          const newPrizeList = [
            ...prizeList,
            {
              from: fromPrize,
              to: toPrize,
              coins: coins,
            },
          ];
          const sortedPrizeList: any = newPrizeList.sort(
            (a, b) => parseInt(a.from) - parseInt(b.from),
          );
          setPrizeList(sortedPrizeList);
          setNewPrize({ from: "", to: "", coins: "" });
        }
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className={`prize-config-table-wrapper ${
            availableQuestionNumbers.length > 0 ? "col-6" : "col-12"
          } order-1`}
        >
          <TableWrapper>
            <ReactTable
              isLoading={isLoading}
              data={prizeList || []}
              columns={isRewardTypeFlat ? FlatTypeColumns : prizeListColumns}
              configuration={{ sorting: [] }}
              hasError={isError}
              errorMessage={errorMsg}
            />
          </TableWrapper>
        </div>
        {!isLoading && !isError && availableQuestionNumbers.length > 0 && (
          <Card className="add-prize-card col-6 py-4">
            <CardBody className="add-prize-card-body px-0">
              <div className="">
                {!isRewardTypeFlat ? (
                  <div className="d-flex">
                    <div className="input-div">
                      <Form.Control
                        type="input"
                        id="from-input"
                        className="border-left-style"
                        placeholder={Match.configure.fromText}
                        onChange={(e) =>
                          !isFromFieldDisabled &&
                          handleNewPrizeConfig("from", e)
                        }
                        value={newPrize.from}
                        disabled={isFromFieldDisabled}
                      />
                    </div>
                    <div className="input-div">
                      <Form.Control
                        type="input"
                        id="from-input"
                        placeholder={Match.configure.toText}
                        onChange={(e) =>
                          !isToFieldDisabled && handleNewPrizeConfig("to", e)
                        }
                        value={newPrize.to}
                        disabled={isToFieldDisabled}
                      />
                    </div>
                    <div className="input-div">
                      <Form.Control
                        type="input"
                        id="from-input"
                        placeholder={Match.configure.amountText}
                        onChange={(e) => handleNewPrizeConfig("coins", e)}
                        value={newPrize.coins}
                        disabled={isFromFieldDisabled}
                      />
                    </div>
                    <div className="input-div">
                      <Button
                        className="add-prize-button"
                        disabled={isReadOnly}
                        onClick={handleAddPrize}
                      >
                        {Action.addNewPrize}
                      </Button>
                    </div>
                  </div>
                ) : (
                  availableQuestionNumbers.map((num, idx) => (
                    <FlatRewardForm
                      key={num}
                      isReadOnly={isReadOnly}
                      prizeList={prizeList}
                      setPrizeList={setPrizeList}
                      questionNumber={num}
                      setShowToaster={setShowToaster}
                      isActiveRow={idx === 0}
                    />
                  ))
                )}
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  );
};

export default PrizeConfig;

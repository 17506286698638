import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";
import { API_METHOD } from "../api/api";

export const fetchQuizList = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.QUIZ_LIST_URL;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const publishQuiz = async (payload, config = {}) => {
  const { quizId, publish } = payload;
  const url: string = getBase() + "/api" + URLS.QUIZ_URL + `/${quizId}/publish`;
  return await handleApiRequest(API_METHOD.PUT, url, { publish }, config);
};

export const disbursePrizeQuiz = async (payload, config = {}) => {
  const { quizId } = payload;
  const url: string =
    getBase() + "/api" + URLS.QUIZ_URL + `/${quizId}/disbursePrize`;
  return await handleApiRequest(API_METHOD.PUT, url, config);
};

export const fetchQuiz = async (payload, config = {}) => {
  const { quizId } = payload;
  const url: string = getBase() + "/api" + URLS.QUIZ_URL + `/${quizId}`;
  return await handleApiRequest(API_METHOD.GET, url, config);
};

export const saveQuiz = async (payload = {}, config: any = {}) => {
  const url: string = getBase() + "/api" + URLS.QUIZ_SAVE_URL;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const updateQuiz = async (payload, config: any = {}) => {
  const { quizId, ...restPayload } = payload;
  const url: string = getBase() + "/api" + URLS.QUIZ_SAVE_URL + `/${quizId}`;
  return await handleApiRequest(API_METHOD.PUT, url, restPayload, config);
};

export const getAllUpcomingSeasonMatchesList = async (
  payload = {},
  config = {},
) => {
  const url: string = getBase() + "/api" + URLS.MATCH_LIST_URL;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const fetchGWPlayers = async (
  gameWeekNumber: number,
  leagueSeasonId: string,
) => {
  const url =
    getBase() +
    "/api" +
    URLS.GW_PLAYERS_LIST +
    `?leagueSeasonId=${leagueSeasonId}&weekLeague=${gameWeekNumber}`;
  return await handleApiRequest(API_METHOD.GET, url);
};

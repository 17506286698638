import { ErrorMessage, Field, Form, Formik } from "formik";
import Typography from "../../../common/components/Typography";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import { createUser, updateUser } from "../../service";
import { HttpStatus } from "../../../utils/constants";
import React, { useState } from "react";
import ReactToastr from "../../../common/components/ReactToaster";
const defaultInitialValues = {
  name: "",
  username: "",
  source: "",
};

const schema = Yup.object().shape({
  name: Yup.string().trim().optional(),
  username: Yup.string().trim().required("Username is required"),
  source: Yup.string()
    .trim()
    .required("Source is required")
    .min(3, "atleast 3 characters")
    .max(30, "max. 30 characters"),
});

const UserInfoForm = () => {
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const isEdit = React.useMemo(() => !!id, [id]);

  const initialValues = isEdit ? location?.state : defaultInitialValues;

  const handleSubmit = async (values: any) => {
    const mappedFormData = values;

    try {
      let res;
      if (isEdit) {
        res = await updateUser(id, {
          name: values?.username,
          source: values?.source,
        });
      } else {
        res = await createUser(mappedFormData);
      }
      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: "User created",
          variant: "success",
        }));
        setTimeout(() => {
          navigate("/user-acquisition");
        }, 700);
      } else {
        throw Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: error?.message,
        variant: "danger",
      }));
    }
  };

  return (
    <div className="h-full">
      <Typography variant="heading2">User Acquisition Info</Typography>

      <div className="h-full">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <Form>
                <section className="form-container">
                  {!isEdit && (
                    <div>
                      <Field
                        className="form-control"
                        name="name"
                        placeholder="Enter name"
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="text-danger"
                      />
                    </div>
                  )}
                  <div>
                    <Field
                      className="form-control"
                      name="username"
                      placeholder="Enter username"
                    />
                    <ErrorMessage
                      component="div"
                      name="username"
                      className="text-danger"
                    />
                  </div>

                  <div>
                    <Field
                      className="form-control"
                      name="source"
                      placeholder="Enter source"
                    />
                    <ErrorMessage
                      component="div"
                      name="source"
                      className="text-danger"
                    />
                  </div>

                  <Button type="submit" variant="primary" className="w-100">
                    {id ? "Update User" : "Create"} User
                  </Button>
                </section>
              </Form>
            );
          }}
        </Formik>
      </div>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </div>
  );
};

export default UserInfoForm;

import * as Yup from "yup";
import { gameModesOptions } from "../Tasks/tasksUtils";
import { CurrencyType, GameModesEnum, Multiplier } from "../common/constants";

export enum SubscriptionTypeEnum {
  DEFAULT = "DEFAULT",
  ONE_TIME = "ONE_TIME",
  NO_ADS = "NO_ADS",
  GOLDEN_TICKET = "GOLDEN_TICKET",
}

export enum EarningType {
  TASK = "Task",
  REFERRAL = "Referral",
  GAME = "Game",
  GOLDEN_TICKET = "GOLDEN_TICKET",
}

export const multiplierOptions = [
  { label: Multiplier.HALF, value: Multiplier.HALF },
  { label: Multiplier.ONE, value: Multiplier.ONE },
  { label: Multiplier.ONE_AND_HALF, value: Multiplier.ONE_AND_HALF },
  { label: Multiplier.TWO, value: Multiplier.TWO },
];

export const subscriptionTypes = [
  {
    label: SubscriptionTypeEnum.DEFAULT,
    value: SubscriptionTypeEnum.DEFAULT,
  },
  {
    label: SubscriptionTypeEnum.GOLDEN_TICKET,
    value: SubscriptionTypeEnum.GOLDEN_TICKET,
  },
  {
    label: SubscriptionTypeEnum.ONE_TIME,
    value: SubscriptionTypeEnum.ONE_TIME,
  },
  {
    label: SubscriptionTypeEnum.NO_ADS,
    value: SubscriptionTypeEnum.NO_ADS,
  },
];

export const earningTypes = [
  {
    label: EarningType.TASK,
    value: EarningType.TASK,
  },
  {
    label: EarningType.REFERRAL,
    value: EarningType.REFERRAL,
  },
  {
    label: EarningType.GAME,
    value: EarningType.GAME,
  },
  {
    label: EarningType.GOLDEN_TICKET,
    value: EarningType.GOLDEN_TICKET,
  },
];

export const currencyTypes = [
  {
    label: CurrencyType.TON,
    value: CurrencyType.TON,
  },
];

export const getDefaultEarningValues = (earningType: EarningType) => {
  switch (earningType) {
    case EarningType.TASK:
    case EarningType.REFERRAL:
      return {
        earningType,
        multiplier: 1,
      };

    case EarningType.GAME:
      return {
        earningType,
        games: [
          { contestType: GameModesEnum.TG_TRIPLE_THREAT, multiplier: 1 },
          { contestType: GameModesEnum.TG_GOALFEST, multiplier: 1 },
          { contestType: GameModesEnum.TG_FANTASY, multiplier: 1 },
        ],
      };

    case EarningType.GOLDEN_TICKET:
      return {
        earningType,
        totalTicketsCredited: 0,
        rewardsConfig: [
          {
            contestType: GameModesEnum.TG_TRIPLE_THREAT,
            currency: CurrencyType.TON,
            amount: 0,
          },
          {
            contestType: GameModesEnum.TG_GOALFEST,
            currency: CurrencyType.TON,
            amount: 0,
          },
          {
            contestType: GameModesEnum.TG_FANTASY,
            currency: CurrencyType.TON,
            amount: 0,
          },
        ],
      };
  }
};

export const getDefaultConfigValues = (type: SubscriptionTypeEnum) => {
  if (type === SubscriptionTypeEnum.GOLDEN_TICKET) {
    return {
      totalTicketsCredited: 0,
    };
  }
  if (type === SubscriptionTypeEnum.DEFAULT) {
    return [getDefaultEarningValues(EarningType.TASK)];
  }
  if (type === SubscriptionTypeEnum.ONE_TIME) {
    return [
      {
        gameMode: gameModesOptions[0].value,
        teamsPerMatch: 1,
      },
    ];
  }
  return [];
};

export const initialCreateSubscriptionValues = {
  type: SubscriptionTypeEnum.DEFAULT,
  version: 1,
  amount: 0,
  currencyCode: CurrencyType.TON,
  name: "",
  description: "",
  validityDays: 30,
  configuration: getDefaultConfigValues(SubscriptionTypeEnum.DEFAULT),
};

export const subscriptionSchema = Yup.object().shape({
  type: Yup.string().required("type is required"),
  version: Yup.number()
    .required("version is required")
    .min(0, "cannot be less than 0"),
  amount: Yup.number()
    .positive("amount should be positive")
    .required("amount is required"),
  currencyCode: Yup.string().required("currency is required"),
  name: Yup.string().required("name is required"),

  description: Yup.string().required("description is required"),

  validityDays: Yup.number().when("type", (type, schema) => {
    return type[0] === SubscriptionTypeEnum.GOLDEN_TICKET
      ? schema.notRequired()
      : schema
          .positive("validity should be positive")
          .required("validity is required");
  }),

  configuration: Yup.mixed().when("type", (type, schema) => {
    if (type[0] === SubscriptionTypeEnum.GOLDEN_TICKET) {
      return Yup.object().shape({
        totalTicketsCredited: Yup.number()
          .min(0, "count should be at least 0")
          .required("Golden ticket count is required"),
      });
    } else if (type[0] === SubscriptionTypeEnum.DEFAULT) {
      return Yup.array().of(
        Yup.object().shape({
          earningType: Yup.string()
            .required("earningType is required")
            .oneOf(
              earningTypes.map((type) => type.value),
              "Invalid earning type",
            ),

          // Multiplier - required for Tasks and Referral
          multiplier: Yup.number()
            .positive("multiplier should be positive")
            .when("earningType", (earningType, schema) => {
              return earningType[0] === EarningType.TASK ||
                earningType[0] === EarningType.REFERRAL
                ? schema.required("multiplier is required")
                : schema.notRequired();
            }),

          // Games array - required for Game
          games: Yup.array().when("earningType", (earningType, schema) => {
            if (earningType[0] === EarningType.GAME) {
              return Yup.array()
                .of(
                  Yup.object().shape({
                    contestType: Yup.string().required(
                      "contestType is required",
                    ),
                    multiplier: Yup.number()
                      .positive("multiplier should be positive")
                      .required("multiplier is required"),
                  }),
                )
                .required("games configuration is required")
                .min(1, "At least one game configuration is required");
            }
            return schema.notRequired();
          }),

          // rewardsConfig - required for Golden_Ticket
          rewardsConfig: Yup.array().when(
            "earningType",
            (earningType, schema) => {
              if (earningType[0] === EarningType.GOLDEN_TICKET) {
                return Yup.array()
                  .of(
                    Yup.object().shape({
                      contestType: Yup.string().required(
                        "contestType is required",
                      ),
                      currency: Yup.string().required("currency is required"),
                      amount: Yup.number()
                        .min(0, "amount should be at least 0")
                        .required("amount is required"),
                    }),
                  )
                  .required("rewards configuration is required")
                  .min(1, "At least one reward configuration is required");
              }
              return schema.notRequired();
            },
          ),

          // totalTicketsCredited - required for Golden_Ticket
          totalTicketsCredited: Yup.number()
            .min(0, "count should be at least 0")
            .when("earningType", (earningType, schema) => {
              return earningType[0] === EarningType.GOLDEN_TICKET
                ? schema.required("totalTicketsCredited is required")
                : schema.notRequired();
            }),
        }),
      );
    } else if (type[0] === SubscriptionTypeEnum.ONE_TIME) {
      return Yup.array().of(
        Yup.object().shape({
          gameMode: Yup.string().required("gameMode is required"),
          teamsPerMatch: Yup.number()
            .required("teamsPerMatch is required")
            .min(1, "cannot be less than 1"),
        }),
      );
    } else if (type[0] === SubscriptionTypeEnum.NO_ADS) {
      return Yup.mixed().notRequired();
    }
    return schema;
  }),
});

export const getAvailableEarningTypesOptions = (configuration) => {
  const availableEarningTypes = earningTypes.filter(
    (earningType) =>
      !configuration.some((config) => config.earningType === earningType.value),
  );
  return availableEarningTypes;
};

export const getAvailableGameModeOptions = (configuration) => {
  const availableGameModeOptions = gameModesOptions.filter(
    (gameModeOption) =>
      !configuration.some(
        (config) => config?.gameMode === gameModeOption.value,
      ),
  );
  return availableGameModeOptions;
};

export const exampleSubscription = {
  type: SubscriptionTypeEnum.DEFAULT,
  currencyCode: CurrencyType.TON,
  version: 1,
  amount: 300,
  name: "subscription will need to update",
  description: "get 1.5x extra reward",
  validityDays: 30,
  configuration: [
    {
      earningType: "Task",
      multiplier: 1.5,
    },
    {
      earningType: "Referral",
      multiplier: 2,
    },
    {
      earningType: "Game",
      games: [
        { contestType: GameModesEnum.TG_TRIPLE_THREAT, multiplier: 1.5 },
        { contestType: GameModesEnum.TG_GOALFEST, multiplier: 1.5 },
        { contestType: GameModesEnum.TG_FANTASY, multiplier: 1.5 },
      ],
    },
    {
      earningType: "Golden_Ticket",
      totalTicketsCredited: 20,
      rewardsConfig: [
        {
          contestType: GameModesEnum.TG_TRIPLE_THREAT,
          currency: CurrencyType.TON,
          amount: 100,
        },
        {
          contestType: GameModesEnum.TG_FANTASY,
          currency: CurrencyType.TON,
          amount: 100,
        },
        {
          contestType: GameModesEnum.TG_GOALFEST,
          currency: CurrencyType.TON,
          amount: 100,
        },
      ],
    },
  ],
};

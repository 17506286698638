import { Button, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { TaskGroupsEnum } from "../../tasksUtils";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { deleteTask, fetchTaskList } from "../../service";
import { HttpStatus } from "../../../utils/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import { EMPTY_ERROR } from "../../../common/constants";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import Typography from "../../../common/components/Typography";

const PlayToEarnTasks = () => {
  const [tasksList, setTasksList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const handleDeleteTask = async () => {
    setShowConfirmationModal(false);
    try {
      const res = await deleteTask(deleteItemId);
      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: "Task deleted",
          variant: "success",
        }));
        getTasksList();
      } else {
        throw Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: error?.message,
        variant: "danger",
      }));
    }
  };

  const getTasksList = async () => {
    setTasksList((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const resData: any = await fetchTaskList({
      query: {
        taskGroup: TaskGroupsEnum.P2E_GAME,
      },
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setTasksList((prev) => ({
        ...prev,
        isLoading: false,
        data: resData?.data?.data?.[TaskGroupsEnum.P2E_GAME],
      }));
    } else {
      setTasksList((prev) => ({
        ...prev,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };

  useEffect(() => {
    getTasksList();
  }, []);

  const columns = [
    {
      accessorKey: "tasks",
      header: "Title",
      cell: ({ row }) => {
        return <span>{row.original?.tasks[0]?.title}</span>;
      },
      canSort: false,
    },
    {
      accessorKey: "redirectionUrl",
      header: "Redirection URL",
      cell: ({ row }) => <span>{row.original?.tasks[0]?.redirectionUrl}</span>,
      canSort: false,
    },
    {
      accessorKey: "rewards",
      header: "Rewards",
      cell: ({ row }) =>
        row.original?.tasks[0]?.rewards.map((item, idx) => (
          <div key={idx}>
            <span className="px-1">{item?.amount}</span>
            <span className="px-1">{item?.currency}</span>
          </div>
        )),
      canSort: false,
    },
    {
      accessorKey: "trackingType",
      header: "Tracking Type",
      cell: ({ row }) => (
        <span>{row.original?.tasks[0]?.trackingDetails?.trackingType}</span>
      ),
      canSort: false,
    },
    {
      acessorKey: "actions",
      header: "Actions",
      colWidth: 200,
      cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <Link
              to={`/task/${row.original?.id}`}
              state={{ task: row.original, edit: true }}
            >
              <Button variant="secondary" size="sm">
                Edit
              </Button>
            </Link>

            <Button
              onClick={() => {
                setShowConfirmationModal(true);
                setDeleteItemId(row.original?.id);
              }}
              className="ms-2"
              variant="danger"
              size="sm"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row className="mb-2 d-flex">
        <Col md="10">
          <Typography variant="pageTitle">P2E Games</Typography>
        </Col>
      </Row>
      <TableWrapper>
        <ReactTable
          columns={columns}
          data={tasksList?.data || []}
          isLoading={tasksList?.isLoading}
          hasError={tasksList?.error?.isError}
          errorMessage={tasksList?.error?.errorMsg}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure, you want to delete the task?`}
        handleClick={() => {
          handleDeleteTask();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Delete"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};

export default PlayToEarnTasks;

import API, { API_METHOD } from "../api/api";
import { getBase } from "../apiconfig";
import {
  GameModesEnum,
  TASK_REDIRECTION_URLS,
  TTQ_VERSION,
} from "../common/constants";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";
import { HttpStatus } from "../utils/constants";
import { ERROR_MESSAGE_TYPE } from "../utils/error/errorMsg";
import { Option } from "./tasksUtils";

export const fetchTaskList = async (config = {}) => {
  try {
    const url: string = getBase() + "/api" + URLS.TASK_LIST_URL;
    const data: any = await API.post(url, config);

    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export const fetchQuizMatches = async (payload = {}): Promise<Option[]> => {
  try {
    const url: string = getBase() + "/api" + URLS.QUIZ_LIST_URL;
    const res = await handleApiRequest(API_METHOD.POST, url, payload);

    if (
      res.status === HttpStatus.REQUEST_SUCCEEDED ||
      res.status === HttpStatus.RESOURCE_CREATED
    ) {
      const data: any[] = res.data.data?.filter(
        (quiz) => quiz?.version === TTQ_VERSION.FIVE,
      );
      return data
        .map((quiz) =>
          quiz?.questionConfiguration?.map((quesConfig) => ({
            label: `${GameModesEnum.TG_TRIPLE_THREAT}-GW${quiz?.weekLeague}_${quesConfig?.tierName}`,
            value: `${quiz?.matchId}+GW${quiz?.weekLeague}_${quesConfig?.tierNumber}`,
            optional: JSON.stringify({
              quizId: quiz?.quizId,
              weekLeague: quiz?.weekLeague,
              tierNumber: quesConfig?.tierNumber,
              matchId: quiz?.matchId,
            }),
          })),
        )
        ?.flat();
    } else {
      throw new Error(res?.message);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchGoalfestGameweeks = async (
  payload = {},
): Promise<Option[]> => {
  try {
    const url: string = getBase() + "/api" + URLS.GOALFEST_LIST_URL;
    const res = await handleApiRequest(API_METHOD.POST, url, payload);

    if (
      res.status === HttpStatus.REQUEST_SUCCEEDED ||
      res.status === HttpStatus.RESOURCE_CREATED
    ) {
      const data: any[] = res.data.data;
      return data.map((gameweek) => ({
        label: `${GameModesEnum.TG_GOALFEST}-GAMEWEEK ${gameweek.weekLeague}`,
        value: gameweek.firstMatchId,
        optional: gameweek.goalFestId,
      }));
    } else {
      throw new Error(res?.message);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchFantasyMatches = async (payload = {}): Promise<Option[]> => {
  try {
    const url: string = getBase() + "/api" + URLS.MATCH_LIST_URL;
    const res = await handleApiRequest(API_METHOD.POST, url, payload);

    if (
      res.status === HttpStatus.REQUEST_SUCCEEDED ||
      res.status === HttpStatus.RESOURCE_CREATED
    ) {
      const data: any[] = res.data.data.matches;
      return data.map((match) => ({
        label: `${
          GameModesEnum.TG_FANTASY
        }-${match.teamId1?.shortCode.toUpperCase()} vs. ${match.teamId2?.shortCode.toUpperCase()}`,
        value: match.id,
        optional: match.id,
      }));
    } else {
      throw new Error(res?.message);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const createTask = async (payload = {}) => {
  const url: string = getBase() + "/api" + URLS.CREATE_TASK_URL;
  return await handleApiRequest(API_METHOD.POST, url, payload);
};

export const updateTask = async (taskId = "", payload = {}) => {
  const url = `${getBase()}/api${URLS.UPDATE_TASK_URL.replace(
    "{taskId}",
    taskId,
  )}`;
  return await handleApiRequest(API_METHOD.PUT, url, payload);
};

export const deleteTask = async (taskId = "", payload = {}) => {
  const url = `${getBase()}/api${URLS.DELETE_TASK_URL.replace(
    "{taskId}",
    taskId,
  )}`;
  return await handleApiRequest(API_METHOD.DELETE, url, payload);
};

export const autoFillRedirectionUrl = (
  gameMode: GameModesEnum[],
  gameId: string[],
): string => {
  if (gameMode?.length > 1) {
    return TASK_REDIRECTION_URLS.HOME_PAGE;
  }
  if (gameMode?.[0] === GameModesEnum.TG_TRIPLE_THREAT) {
    if (gameId?.length === 1) {
      const { quizId, weekLeague, tierNumber } = JSON.parse(gameId?.[0]);
      return TASK_REDIRECTION_URLS.TTQ_MATCH(quizId, weekLeague, tierNumber);
    }
    return TASK_REDIRECTION_URLS.TTQ_GAMES_PAGE;
  }

  if (gameMode?.[0] === GameModesEnum.TG_GOALFEST) {
    if (gameId?.length === 1) {
      return TASK_REDIRECTION_URLS.GF_SEASON(gameId[0]);
    }
    return TASK_REDIRECTION_URLS.GF_GAMES_PAGE;
  }

  if (gameMode?.[0] === GameModesEnum.TG_FANTASY) {
    if (gameId?.length === 1) {
      return TASK_REDIRECTION_URLS.FANTASY_MATCH(gameId[0]);
    }
    return TASK_REDIRECTION_URLS.FANTASY_GAMES_PAGE;
  }

  return TASK_REDIRECTION_URLS.HOME_PAGE;
};

export const fetchTaskImagePresignedUrl = async (
  taskImage: string,
  config = {},
) => {
  try {
    const url: string =
      getBase() +
      "/api" +
      URLS.P2E_TASK_PRESIGNED_URL.replace("{fileName}", taskImage);
    const data: any = await API.get(url, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

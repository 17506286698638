import { Button, Form } from "react-bootstrap";
import ReactToastr from "../../../common/components/ReactToaster";
import Typography from "../../../common/components/Typography";
import { Link } from "react-router-dom";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import { SubscriptionTypeEnum } from "../../subscriptionUtils";
import { useEffect, useMemo, useState } from "react";
import { EMPTY_ERROR } from "../../../common/constants";
import { deleteSubscription, fetchSubscriptionsList } from "../../service";
import { HttpStatus } from "../../../utils/constants";

enum FilterOptions {
  ALL = "ALL",
  DEFAULT = "DEFAULT",
  NO_ADS = "NO_ADS",
  ONE_TIME = "ONE_TIME",
  GOLDEN_TICKET = "GOLDEN_TICKET",
}

const Subscriptions = () => {
  const [refreshId, setRefreshId] = useState<number>(0);
  const [deletedOnly, setDeletedOnly] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const [filters, setFilters] = useState<FilterOptions>(FilterOptions.ALL);

  const changeFilters = (option: FilterOptions) => {
    setFilters(option);

    switch (option) {
      case FilterOptions.ALL:
        setDeletedOnly(false);
        setListQuery({
          isDeleted: false,
        });
        return;

      case FilterOptions.GOLDEN_TICKET:
        setDeletedOnly(false);
        setListQuery({
          isDeleted: false,
          type: SubscriptionTypeEnum.GOLDEN_TICKET,
        });
        return;

      case FilterOptions.DEFAULT:
        setListQuery({
          type: SubscriptionTypeEnum.DEFAULT,
          isDeleted: false,
        });
        return;

      case FilterOptions.NO_ADS:
        setListQuery({
          type: SubscriptionTypeEnum.NO_ADS,
          isDeleted: false,
        });
        return;

      case FilterOptions.ONE_TIME:
        setListQuery({
          type: SubscriptionTypeEnum.ONE_TIME,
          isDeleted: false,
        });
        return;
    }
  };

  const [listQuery, setListQuery] = useState<{
    type?: SubscriptionTypeEnum;
    isDeleted?: boolean;
  }>({
    isDeleted: false,
  });

  const refreshTable = () => setRefreshId((p) => p + 1);

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        setSubscriptions((prev) => ({
          ...prev,
          isLoading: true,
        }));
        const res = await fetchSubscriptionsList({
          query: listQuery,
        });

        if (
          res?.status === HttpStatus.REQUEST_SUCCEEDED ||
          res?.status === HttpStatus.RESOURCE_CREATED
        ) {
          setSubscriptions((prev) => ({
            ...prev,
            data: res?.data?.data,
          }));
        } else {
          throw new Error(res?.message ?? "Something went wrong");
        }
      } catch (error) {
        setSubscriptions((prev) => ({
          ...prev,

          data: null,
          error: error.message,
        }));
        setShowToaster({
          show: true,
          message: error.message,
          variant: "danger",
        });
      } finally {
        setSubscriptions((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    };

    fetchSubscriptions();
  }, [refreshId, listQuery, deletedOnly]);

  const columns = useMemo(
    () => [
      ...(filters === FilterOptions.ALL
        ? [
            {
              accessorKey: "type",
              id: "type",
              header: "Type",
              cell: ({ row }) => <span>{row.original?.type}</span>,
            },
          ]
        : []),
      {
        accessorKey: "version",
        id: "version",
        header: "Version",
        cell: ({ row }) => {
          return <span>{row.original?.version}</span>;
        },
      },
      {
        accessorKey: "amount",
        id: "amount",
        header: "Amount",
        cell: ({ row }) => {
          return <span>{row.original?.amount}</span>;
        },
      },
      {
        accessorKey: "currencyCode",
        id: "currencyCode",
        header: "Currency Code",
        cell: ({ row }) => {
          return <span>{row.original?.currencyCode}</span>;
        },
        canSort: false,
      },
      {
        accessorKey: "name",
        id: "name",
        header: "Name",
        cell: ({ row }) => {
          return (
            <p
              style={{
                width: "100px",
              }}
            >
              {row.original?.name}
            </p>
          );
        },
        canSort: false,
      },
      {
        accessorKey: "description",
        id: "description",
        header: "Description",
        cell: ({ row }) => {
          return <span>{row.original?.description}</span>;
        },
        canSort: false,
      },
      {
        accessorKey: "validityDays",
        id: "validityDays",
        header: "Validity (in days)",
        cell: ({ row }) => {
          return <span>{row.original?.validityDays}</span>;
        },
      },
      {
        accessorKey: "configuration",
        id: "configuration",
        header: "Configuration",
        cell: ({ row }) => {
          if (row?.original?.type === SubscriptionTypeEnum.DEFAULT) {
            return row.original?.configuration.map((config, idx) => (
              <div
                key={idx}
                style={{ whiteSpace: "nowrap" }}
                className="d-flex gap-4 justify-content-between"
              >
                <span>earningType: {config?.earningType} </span>
                <span>multiplier :{config?.multiplier}</span>
              </div>
            ));
          }
          if (row?.original?.type === SubscriptionTypeEnum.ONE_TIME) {
            return row.original?.configuration.map((config, idx) => (
              <div
                key={idx}
                style={{ whiteSpace: "nowrap" }}
                className="d-flex gap-4 justify-content-between"
              >
                <span> gameMode: {config?.gameMode}</span>
                <span> teamsPerMatch :{config?.teamsPerMatch}</span>
              </div>
            ));
          }
        },
        canSort: false,
      },
      ...(deletedOnly
        ? []
        : [
            {
              acessorKey: "actions",
              header: "Actions",
              cell: ({ row }) => {
                return (
                  <div
                    className="d-flex align-items-center"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <Link
                      to={`/subscriptions/model/${row.original?.id}`}
                      state={{ data: row.original, edit: true }}
                    >
                      <Button variant="secondary" size="sm">
                        Edit
                      </Button>
                    </Link>

                    <Button
                      onClick={() => {
                        setShowConfirmationModal(true);
                        setDeleteItemId(row.original?.id);
                      }}
                      className="ms-2"
                      variant="danger"
                      size="sm"
                    >
                      Delete
                    </Button>
                  </div>
                );
              },
            },
          ]),
    ],
    [filters, deletedOnly],
  );

  const handleDeleteSubscription = async () => {
    setShowConfirmationModal(false);
    try {
      const res = await deleteSubscription({
        id: deleteItemId,
      });

      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setDeleteItemId("");
        setShowToaster({
          show: true,
          message: "Subscription deleted",
          variant: "success",
        });

        refreshTable();
      } else {
        throw new Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setShowToaster({
        show: true,
        message: error.message,
        variant: "danger",
      });
    }
  };

  return (
    <>
      <div className="mb-2 d-flex position-relative">
        <button
          style={{
            all: "unset",
            cursor: "pointer",
          }}
          onClick={() => changeFilters(FilterOptions.ALL)}
        >
          <Typography variant="pageTitle">Subscriptions</Typography>
        </button>
        <div className="d-flex gap-2 ms-5 align-items-center">
          <Button
            onClick={() => changeFilters(FilterOptions.DEFAULT)}
            variant={
              filters === FilterOptions.DEFAULT ? "secondary" : "primary"
            }
          >
            DEFAULT
          </Button>
          <Button
            onClick={() => changeFilters(FilterOptions.GOLDEN_TICKET)}
            variant={
              filters === FilterOptions.GOLDEN_TICKET ? "secondary" : "primary"
            }
          >
            GOLDEN TICKET
          </Button>
          <Button
            onClick={() => changeFilters(FilterOptions.NO_ADS)}
            variant={filters === FilterOptions.NO_ADS ? "secondary" : "primary"}
          >
            NO ADS
          </Button>
          <Button
            onClick={() => changeFilters(FilterOptions.ONE_TIME)}
            variant={
              filters === FilterOptions.ONE_TIME ? "secondary" : "primary"
            }
          >
            ONE TIME
          </Button>
          <Form.Check
            type="checkbox"
            label="Deleted"
            checked={deletedOnly}
            onChange={(e) => {
              const checked = e.target.checked;
              setDeletedOnly(checked);

              setListQuery((prev) => {
                if (checked) {
                  return { ...prev, isDeleted: true };
                } else {
                  return { ...prev, isDeleted: false };
                }
              });
            }}
            className="ms-2"
          />
        </div>
        <div className="d-flex gap-2 position-absolute end-0">
          <Button variant="primary" onClick={refreshTable}>
            Refresh
          </Button>
          <Link to={"/subscriptions/model/create"}>
            <Button variant="primary">Create</Button>
          </Link>
        </div>
      </div>
      <TableWrapper>
        <ReactTable
          columns={columns}
          data={subscriptions?.data || []}
          isLoading={subscriptions?.isLoading}
          hasError={subscriptions?.error?.isError}
          errorMessage={subscriptions?.error?.errorMsg}
        />
      </TableWrapper>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure, you want to delete the subscription?`}
        handleClick={() => {
          handleDeleteSubscription();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Delete"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};

export default Subscriptions;

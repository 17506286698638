import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import Select from "react-select";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import { Action, EMPTY_ERROR, PrizeTypeEnum } from "../../../common/constants";
import {
  disburseGoalFestPrize,
  fetchGoalFest,
  publishGoalFest,
  saveGoalFest,
} from "../../sevice";
import { Button } from "react-bootstrap";
import Typography from "../../../common/components/Typography";
import ReactToastr from "../../../common/components/ReactToaster";
import PrizeConfig from "../../../common/components/PrizeConfig";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";

export const GoalFestInfo = () => {
  const { goalfestId } = useParams();
  const navigate = useNavigate();
  const [goalfest, setGoalfest] = useState<any>({
    data: null,
    error: EMPTY_ERROR,
  });
  const [initialValues, setInitialValues] = useState<any>({
    prize: [],
    rewardType: PrizeTypeEnum.DIRECT,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<any>({
    title: "",
    handler: () => {
      console.log("Initial Handler");
    },
  });
  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };
  useEffect(() => {
    getGoalfest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGoalfest = async () => {
    setIsLoading(true);
    const resData = await fetchGoalFest(goalfestId);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setGoalfest((prev) => ({
        ...prev,
        data: resData?.data?.data,
      }));
      setInitialValues({
        ...initialValues,
        // prize: resData?.data?.data?.goalFest.contest_prize || [],
        // rewardType:
        // resData?.data?.data?.goalFest.goalfest_rewardType ||
        // PrizeTypeEnum.DIRECT,
        prize: [PrizeTypeEnum.DIRECT, PrizeTypeEnum.FLAT].includes(
          resData?.data?.data?.goalFest.goalfest_rewardType,
        )
          ? resData?.data?.data?.goalFest.contest_prize
          : [],
        rewardType: PrizeTypeEnum.DIRECT,
      });
      setIsLoading(false);
    } else {
      setGoalfest({
        ...goalfest,
        data: null,
        error: { errorMsg: resData?.message, isError: true },
      });
      setInitialValues({
        ...initialValues,
        prize: [],
        rewardType: PrizeTypeEnum.DIRECT,
      });
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
      setIsLoading(false);
    }
  };

  const defaultPrizes = [
    {
      from: "1",
      to: "1",
      coins: "100",
    },
    {
      from: "2",
      to: "2",
      coins: "50",
    },
    {
      from: "3",
      to: "3",
      coins: "10",
    },
  ];

  const updateGoalfest = async (values) => {
    const resData = await saveGoalFest({
      id: goalfestId,
      values,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `Goalfest configuration has been updated successfully`,
        variant: "success",
      }));

      // setTimeout(() => {
      //   navigate("/goalfest");
      // }, 2000);
      //   setIsSubmitting(false);
      getGoalfest();
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message ?? "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
  };

  const modalHandler = (title, handler) => {
    setModalContent({
      ...modalContent,
      title: title,
      handler: handler,
    });
    setShowModal(true);
  };
  const goalFest = goalfest?.data?.goalFest;

  const handlePublishGoalfest = async () => {
    const resData = await publishGoalFest({
      id: goalfestId,
      payload: {
        isPublished: !goalFest?.goalfest_isPublished,
      },
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `Goalfest League has been ${
          goalFest?.goalfest_isPublished === 0 ? "published" : "unpublished"
        } successfully`,
        variant: "success",
      }));
      getGoalfest();
      setIsLoading(false);
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setIsLoading(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
    setShowModal(false);
  };
  const handleDisbursePrice = async () => {
    if (goalFest?.goalfest_prizeDisbursed === 1) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `Prize has already been disbursed.`,
        variant: "danger",
      }));
      setShowModal(false);
      return;
    }

    if (goalFest?.goalfest_isPublished === 0) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `Please publish the Goalfest League ${goalFest?.goalFest_week_league} to disbursed prize.`,
        variant: "danger",
      }));
      setShowModal(false);
      return;
    }

    const resData = await disburseGoalFestPrize(goalfestId);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `Goalfest prize has been disbursed successfully`,
        variant: "success",
      }));

      // setTimeout(() => {
      //   navigate("/goalfest");
      // }, 2000);
      getGoalfest();
      setIsLoading(false);
      setShowModal(false);
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setIsLoading(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
      setShowModal(false);
    }
  };
  return (
    <div className="w-100 position-relative">
      <ConfirmationModal
        variant="alert"
        show={showModal}
        title={modalContent?.title}
        handleClick={modalContent.handler}
        handleClose={() => setShowModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowModal(false)}
        btnText="Confirm"
      />
      <Button
        className="d-flex align-items-start px-0"
        variant="link"
        onClick={() => navigate(-1)}
      >
        {Action.goBack}
      </Button>
      <div className="d-flex align-items-center gap-2">
        <Typography variant="heading3" className="mb-0">
          GoalFest Week {goalFest?.goalFest_week_league}
        </Typography>
        <div className="d-flex align-items-center">
          {goalFest?.goalfest_isPublished ? (
            <span className="badge rounded-pill text-bg-success px-2 py-1">
              Published
            </span>
          ) : (
            <span className="badge rounded-pill text-bg-secondary px-2 py-1 text-light">
              Yet to be Published
            </span>
          )}
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => updateGoalfest(values)}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Typography variant="heading4" className="p-1">
              Prize Configuration
            </Typography>
            <section className="d-flex flex-column w-100">
              <div className=" d-flex gap-2">
                <Select
                  className="flex-grow-1"
                  options={[
                    {
                      label: PrizeTypeEnum.ABSOLUTE,
                      value: PrizeTypeEnum.ABSOLUTE,
                    },
                    {
                      label: PrizeTypeEnum.PERCENTAGE,
                      value: PrizeTypeEnum.PERCENTAGE,
                    },
                    {
                      label: PrizeTypeEnum.DIRECT,
                      value: PrizeTypeEnum.DIRECT,
                    },
                  ]}
                  value={{
                    label: values.rewardType,
                    value: values.rewardType,
                  }}
                  placeholder="Select Prize Type"
                  name="prizeType"
                  isLoading={isLoading}
                  onChange={(e) => {
                    setFieldValue("rewardType", e.value);
                  }}
                />
              </div>
              <div className="w-100 d-flex align-items-center gap-2 px-2 "></div>

              <PrizeConfig
                isLoading={isLoading}
                isError={false}
                errorMsg="Something went wrong, please try again"
                prizeList={
                  initialValues?.prize?.length
                    ? initialValues?.prize
                    : [PrizeTypeEnum.DIRECT, PrizeTypeEnum.FLAT].includes(
                        initialValues?.rewardType,
                      )
                    ? []
                    : defaultPrizes
                }
                setPrizeList={(list) => {
                  setInitialValues({
                    ...initialValues,
                    prize: list,
                  });
                }}
                isReadOnly={goalFest?.goalfest_isPublished}
                setShowToaster={setShowToaster}
                isDisabledDeleteRow={goalFest?.goalfest_isPublished}
                isFromFieldDisabled={goalFest?.goalfest_isPublished}
                isToFieldDisabled={goalFest?.goalfest_isPublished}
                canSetDefaultToAndFromValue={true}
                isRewardTypeFlat={values?.rewardType === PrizeTypeEnum.DIRECT}
              />
            </section>

            <div className="divider-container"></div>
            <div className="d-flex justify-content-between gap-3 w-100">
              <Button
                type="submit"
                variant="primary"
                className="w-25"
                disabled={
                  isLoading ||
                  goalFest?.goalfest_isPublished === 1 ||
                  isSubmitting
                }
              >
                {isSubmitting ? Action.saving : "Update GoalFest"}
              </Button>
              <div className="d-flex justify-content-between gap-3">
                {goalFest?.goalfest_isPublished === 1 && (
                  <Button
                    disabled={goalFest?.goalfest_prizeDisbursed === 1}
                    onClick={() =>
                      modalHandler(
                        `Are you sure you want to disburse prize for the Goalfest League Week ${goalFest?.goalFest_week_league}?`,
                        handleDisbursePrice,
                      )
                    }
                  >
                    Disburse Prize
                  </Button>
                )}
                <Button
                  onClick={() =>
                    modalHandler(
                      `Are you sure you want to ${
                        goalFest?.goalfest_isPublished === 0
                          ? "publish"
                          : "unpublish"
                      } the Goalfest League Week ${
                        goalFest?.goalFest_week_league
                      }?`,
                      handlePublishGoalfest,
                    )
                  }
                >
                  {goalFest?.goalfest_isPublished ? "unpublish" : "publish"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </div>
  );
};

export default GoalFestInfo;

import API from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { HttpStatus } from "../utils/constants";
import { ERROR_MESSAGE_TYPE } from "../utils/error/errorMsg";

export const fetchEventStats = async (config) => {
  try {
    const { startDate, endDate, ...rest } = config;
    const url: string =
      getBase() +
      "/api" +
      URLS.ANALYTICS_LIST +
      `?startDate=${startDate}&endDate=${endDate}`;
    const data: any = await API.get(url, rest);

    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

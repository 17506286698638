import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../NotFound";
import { LoginView } from "../Login";
import Counter from "../Demofolder/components/Counter";
import Layout from "../Layout/Layout";
import MatchList from "../teamslist/component/MatchList";
import { Players } from "../Players/component/PlayerList";
import { PlayerInfo } from "../Players/component/PlayerInfo";
import { PointsTable } from "../FutyPoints/component/PointsTable";
import { ReferralsTable } from "../Referrals/component/ReferralsTable";
import { AddReferral } from "../Referrals/component/AddReferral";
import PushNotification from "../PushNotification/components/PushNotification";
import TeamsColorConfig from "../teamslist/component/TeamsColorConfig";

import { Stats } from "../Stats/component/Stats";
import { LeaguesConfigure } from "../LeaguesConfigure/components";
import { RewardsView } from "../Rewards/components";
import { ManageUser } from "../ManageUser/components";

import { UserIdentityView } from "../UserIdentity/component";
import { UserIdentityDetailView } from "../UserIdentity/component/UserDetails/UserIdentityDetailView";
import { AddMiniLeague } from "../LeaguesConfigure/MiniLeagues/components/FeaturedMiniLeague/AddMiniLeague";
import { QuizList } from "../Quiz/components/QuizList";
import { QuizInfo } from "../Quiz/components/QuizInfo";
import { GoalFestList } from "../GoalFest/components/GoalFestList";
import GoalFestInfo from "../GoalFest/components/GoalFestInfo";
import DailyLogin from "../common/components/Tasks/component/DailyLogin";
import TaskForm from "../Tasks/component/TaskForm";
import TeamConfigure from "../teamslist/component/TeamConfigure";
import WelcomeTasks from "../Tasks/component/WelcomeTasks";
import DailyTasks from "../Tasks/component/DailyTasks";
import MilestoneAndTasks from "../Tasks/component/MilestoneAndTasks";
import Subscriptions from "../Subscriptions/components/Subscriptions";
import SubscriptionForm from "../Subscriptions/components/SubscriptionForm";
import { TriviaForm } from "../Trivia/components/TriviaForm";
import TriviaList from "../Trivia/components/TriviaList";
import UserSubscriptions from "../Subscriptions/components/UserSubscriptions";
import UserList from "../UserAcquisition/components/UserList/UserList";
import UserInfoForm from "../UserAcquisition/components/UserInfoForm/UserInfoForm";
import { Analytics } from "../Analytics/components/Dashboard";

function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Stats />} />
        <Route path="/telegram-stats" element={<Stats type="tgStats" />} />
        <Route path="/leagues" element={<LeaguesConfigure />} />
        <Route path="/mini-leagues/:miniLeagueId" element={<AddMiniLeague />} />
        <Route
          path="/matches"
          element={<Navigate to="/matches/normal-matches" replace />}
        />
        <Route path="/matches/normal-matches" element={<MatchList />} />
        <Route
          path="/matches/normal-matches/:matchId"
          element={<TeamConfigure />}
        />
        <Route
          path="/matches/telegram-matches"
          element={<MatchList isTgMatches={true} />}
        />
        <Route
          path="/matches/telegram-matches/:matchId"
          element={<TeamConfigure />}
        />
        <Route path="/players" element={<Players />} />
        <Route path="/teams" element={<TeamsColorConfig />} />
        <Route path="/players/:playerId" element={<PlayerInfo />} />
        <Route path="/futy-points" element={<PointsTable />} />
        <Route path="/referrals" element={<ReferralsTable />} />
        <Route path="/referrals/:referralId" element={<AddReferral />} />
        <Route path="/push-notification" element={<PushNotification />} />
        <Route path="/manage-user" element={<ManageUser />} />
        <Route path="/rewards" element={<RewardsView />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route
          path="/subscriptions/user-subscriptions"
          element={<UserSubscriptions />}
        />
        <Route path="/subscriptions/model" element={<Subscriptions />} />
        <Route
          path="/subscriptions/model/create"
          element={<SubscriptionForm />}
        />
        <Route path="/subscriptions/model/:id" element={<SubscriptionForm />} />
        <Route path="/user-identity" element={<UserIdentityView />} />
        <Route
          path="/user-identity/:userIdentityId"
          element={<UserIdentityDetailView />}
        />
        <Route path="/quiz" element={<QuizList />} />
        <Route path="/quiz/:quizId" element={<QuizInfo />} />
        <Route path="/goalfest" element={<GoalFestList />} />
        <Route path="/goalfest/:goalfestId" element={<GoalFestInfo />} />
        <Route
          path="/tasks"
          element={<Navigate to={"/tasks/daily-login"} replace />}
        />
        <Route path="/tasks/daily-login" element={<DailyLogin />} />
        <Route path="/tasks/daily-tasks" element={<DailyTasks />} />
        <Route path="/tasks/milestone-tasks" element={<MilestoneAndTasks />} />
        <Route path="/tasks/welcome-tasks" element={<WelcomeTasks />} />
        <Route path="/tasks/create" element={<TaskForm />} />
        <Route path="/task/:taskId" element={<TaskForm />} />
        <Route path="/trivia" element={<TriviaList />} />
        <Route path="/trivia/create" element={<TriviaForm />} />
        <Route path="/trivia/:triviaQuizId" element={<TriviaForm />} />
        <Route path="/user-acquisition" element={<UserList />} />
        <Route path="/user-acquisition/create" element={<UserInfoForm />} />
        <Route path="/user-acquisition/:id" element={<UserInfoForm />} />
        <Route path="/analytics" element={<Analytics />} />
      </Route>
      <Route path="/login" element={<LoginView />} />
      <Route path="/counter-demo" element={<Counter />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AllRoutes;

import * as Yup from "yup";
import {
  PrizeTypeEnum,
  QuestionsArrayLength,
  TriviaDifficulty,
  TriviaOptionsCount,
} from "../common/constants";

export const QuizSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  theme: Yup.string().required("Theme is required"),
  duration: Yup.object().shape({
    startTime: Yup.date()
      .required("Start time is required")
      .min(new Date(), "Start time cannot be in the past"),
    endTime: Yup.date()
      .required("End time is required")
      .min(Yup.ref("startTime"), "End time must be after start time"),
  }),
  questionConfigurations: Yup.array().of(
    Yup.object().shape({
      questions: Yup.array()
        .of(
          Yup.object().shape({
            questionNumber: Yup.number(),
            question: Yup.string()
              .required("Question is required")
              .test(
                "is-unique-question",
                "Each question must be unique",
                function (value) {
                  const { questions } = this.options.context || {};
                  if (!value || !questions) return true;

                  return (
                    questions.filter((q) => q.question === value).length === 1
                  );
                },
              ),
            correctOptionNumber: Yup.number().required(
              "Correct option is required",
            ),
            correctOptionValue: Yup.string(),
            options: Yup.array()
              .length(4, "Each question must have exactly 4 options")
              .of(
                Yup.object().shape({
                  optionNumber: Yup.number(),
                  option: Yup.string().required("Option is required"),
                  countryCode: Yup.string().required("Country is required"),
                  countryName: Yup.string(),
                }),
              )
              .test(
                "unique-options",
                "All options must be unique",
                function (options) {
                  if (!options || options.length === 0) return true;

                  const optionValues = options.map((opt) => opt.option);

                  const duplicateOptions = optionValues.filter(
                    (opt, index, arr) => opt && arr.indexOf(opt) !== index,
                  );

                  let hasErrors = false;

                  options.forEach((opt, index) => {
                    if (duplicateOptions.includes(opt.option)) {
                      this.createError({
                        path: `${this.path}[${index}].option`,
                        message: "Option must be unique",
                      });
                      hasErrors = true;
                    }
                  });

                  return hasErrors
                    ? this.createError({ message: "Options must be unique" })
                    : true;
                },
              ),
          }),
        )
        .test(
          "unique-questions",
          "Each question must be unique",
          function (questions) {
            if (!questions) return true;
            const questionValues = questions.map((q) => q.question);
            return new Set(questionValues).size === questionValues.length;
          },
        ),
      prizes: Yup.array().of(
        Yup.object().shape({
          from: Yup.number(),
          to: Yup.number(),
          coins: Yup.number(),
        }),
      ),
      difficulty: Yup.string().required("Difficulty is required"),
      rewardType: Yup.string().required("Reward type is required"),
      questionsCount: Yup.number(),
    }),
  ),
});

export type CountryOption = {
  value: string;
  key: string;
  name?: string;
  label: JSX.Element;
};

export const rewardTypeOptions = [
  {
    label: PrizeTypeEnum.FLAT,
    value: PrizeTypeEnum.FLAT,
  },
  {
    label: PrizeTypeEnum.ABSOLUTE,
    value: PrizeTypeEnum.ABSOLUTE,
  },
  {
    label: PrizeTypeEnum.PERCENTAGE,
    value: PrizeTypeEnum.PERCENTAGE,
  },
];

export const difficultyOptions = [
  {
    label: TriviaDifficulty.EASY,
    value: TriviaDifficulty.EASY,
  },
  {
    label: TriviaDifficulty.BALLER,
    value: TriviaDifficulty.BALLER,
  },
  {
    label: TriviaDifficulty.FREAK,
    value: TriviaDifficulty.FREAK,
  },
];

export const defaultQuestionPayload = (
  questionNumber: number,
  optionSameAsCountry = false,
) => ({
  questionNumber,
  question: "",
  optionSameAsCountry: optionSameAsCountry,
  correctOptionNumber: null,
  correctOptionValue: "",
  options: Array.from({ length: TriviaOptionsCount }, (_, idx) => ({
    optionNumber: idx + 1,
    countryCode: "",
    countryName: "",
    option: "",
  })),
});

export const defaultQuestionConfig = (
  difficulty: TriviaDifficulty,
  questionsCount: number,
) => ({
  questionsCount,
  rewardType: PrizeTypeEnum.FLAT,
  difficulty,
  questions: Array.from({ length: questionsCount }, (_, i) =>
    defaultQuestionPayload(i + 1),
  ),
  prizes: [],
});

export const initialTriviaValues = {
  name: "",
  description: "",
  theme: "",
  duration: { startTime: null, endTime: null },
  questionConfigurations: [
    defaultQuestionConfig(TriviaDifficulty.EASY, QuestionsArrayLength),
  ],
};

export const optionNumberOptions = Array.from(
  { length: TriviaOptionsCount },
  (_, idx) => ({
    label: idx + 1,
    value: idx + 1,
  }),
);

import { useEffect, useMemo, useRef, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import "./style.scss";
import Typography from "../../../common/components/Typography";
import { Button, Image, Spinner } from "react-bootstrap";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import {
  Action,
  max_photo_size,
  PrizeTypeEnum,
  QuestionsArrayLength,
  TriviaDifficulty,
} from "../../../common/constants";
import Select from "react-select";
import PrizeConfig from "../../../common/components/PrizeConfig";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import {
  CountryOption,
  difficultyOptions,
  defaultQuestionConfig,
  initialTriviaValues,
  optionNumberOptions,
  QuizSchema,
  rewardTypeOptions,
  defaultQuestionPayload,
} from "../../triviaUtils";
import { countries } from "countries-list";
import {
  createTriviaQuiz,
  fetchTriviaQuizById,
  publishTriviaQuiz,
  updateTriviaQuiz,
  uploadBannerToPresignedUrl,
} from "../service";
import DateTimePicker from "../../../common/components/DateAndTimePicker/DateTimePicker";
import { fetchBannerPresignedUrl } from "../service";
import { Form as FormB } from "react-bootstrap";
import NoImage from "../../../common/assets/NoImage.svg";

export const TriviaForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { edit } = location?.state || { edit: false };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false);

  const bannerInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState<string>("");
  const [presignUrl, setPresignUrl] = useState<string>("");
  const [image, setImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [isUploadBanner, setIsUploadBanner] = useState(false);

  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const [prizeList, setPrizeList] = useState([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<any>({
    title: "",
    handler: () => {
      return;
    },
  });

  const handleUpdatePrizeList =
    (qConfigIndex: number) => (updatedPrizeList: any) => {
      setPrizeList((list) => {
        const newPrizeList = [...list];

        while (newPrizeList.length <= qConfigIndex) {
          newPrizeList.push([]);
        }

        newPrizeList[qConfigIndex] = updatedPrizeList;
        return newPrizeList;
      });
    };

  const [resultData, setResultData] = useState({
    data: null,
    isLoading: false,
    error: null,
  });

  const { triviaQuizId } = useParams();

  const handleBannerChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > max_photo_size) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: "Please upload an image smaller than 2MB.",
          variant: "danger",
        }));
        return;
      }
      setFile(selectedFile);
      setFileName(selectedFile.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleBannerClick = () => {
    bannerInputRef.current.click();
  };

  const handleBannerUpload = async () => {
    if (!file) {
      setIsUploadBanner(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "Please select an image",
        variant: "danger",
      }));
      return;
    }
    try {
      const resData = await uploadBannerToPresignedUrl(presignUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      if (resData?.status === HttpStatus.REQUEST_SUCCEEDED) {
        setBannerImage(presignUrl);
        setIsUploadBanner(false);
      } else {
        setIsUploadBanner(false);
        setImage(bannerImage);
        if (resData?.status !== CustomStatusCode.sessionExpire)
          setShowToaster((prev) => ({
            ...prev,
            show: true,
            message: `${
              resData?.message || "Something went wrong, please try again"
            }`,
            variant: "danger",
          }));
      }
    } catch (error) {
      setIsUploadBanner(false);
      setImage(bannerImage);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "Error uploading team logo",
        variant: "danger",
      }));
    }
  };

  const getPresignedUrl = async () => {
    setIsUploadBanner(true);
    const resData: any = await fetchBannerPresignedUrl(fileName);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      if (resData?.data?.data) {
        setPresignUrl(resData?.data?.data?.presignUrl);
      }
    } else {
      setIsUploadBanner(false);
      setImage(bannerImage);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: resData?.message ?? "Something went wrong, please try again",
          variant: "danger",
        }));
    }
  };

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const countryOptions: CountryOption[] = Object.entries(countries).map(
    ([code, country]) => ({
      value: code,
      key: country.name,
      label: (
        <div className="d-flex gap-4">
          <img
            src={`https://flagcdn.com/w40/${code.toLowerCase()}.png`}
            alt={country.name}
          />
          {country.name}
        </div>
      ),
    }),
  );

  const publishIngQuiz = async (publish: boolean) => {
    try {
      const resData = await publishTriviaQuiz({
        isPublished: publish,
        quizId: triviaQuizId,
      });
      if (
        resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
        resData?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `Quiz ${
            publish ? "published" : "unpublished"
          } successfully!`,
          variant: "success",
        }));
        setIsPublishLoading(true);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      } else {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message ?? "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
        setIsPublishLoading(false);
      }
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "An unexpected error occurred, please try again.",
        variant: "danger",
      }));
      setIsPublishLoading(false);
    }
  };

  const getNextDifficulty = (questionConfigs: any) => {
    const allDifficulties = Object.values(TriviaDifficulty);
    const usedDifficulties = new Set(
      questionConfigs.map((item) => item.difficulty),
    );

    return (
      allDifficulties.find((difficulty) => !usedDifficulties.has(difficulty)) ||
      null
    );
  };

  const updateOrSave = async (values: any, { resetForm }: any) => {
    const isValid = prizeList?.every((prizes, index) => {
      const questionConfig = values?.questionConfigurations[index];
      const questionCount = questionConfig?.questions?.length;

      return questionConfig?.rewardType === PrizeTypeEnum.PERCENTAGE
        ? prizes.length > 0
        : prizes.length === questionCount;
    });

    if (!isValid) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "Please add all prizes",
        variant: "danger",
      }));
      return;
    }

    if (!bannerImage) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "Please upload a banner image",
        variant: "danger",
      }));
      return;
    }

    const payload = {
      name: values.name,
      description: values.description,
      duration: values.duration,
      bannerUrl: bannerImage,
      theme: values.theme,
      questionConfigurations: values.questionConfigurations.map(
        (qConfig, qConIndex) => ({
          difficulty: qConfig.difficulty,
          prizes: prizeList?.[qConIndex] ?? [],
          rewardType: qConfig.rewardType,
          questions: qConfig.questions.map((question) => ({
            questionNumber: question.questionNumber,
            question: question.question,
            correctOptionNumber: question.correctOptionNumber,
            correctOptionValue: question.options.find(
              (option) => option.optionNumber === question.correctOptionNumber,
            )?.option,
            options: question.options.map((option) => ({
              optionNumber: option.optionNumber,
              option: option.option,
              countryCode: option.countryCode,
            })),
          })),
        }),
      ),
    };

    const resData: any = edit
      ? await updateTriviaQuiz({ ...payload, quizId: triviaQuizId }, {})
      : await createTriviaQuiz(payload, {});
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `Quiz has been ${edit ? "edited" : "created"} successfully`,
        variant: "success",
      }));

      setIsLoading(false);

      alert(`Quiz has been ${edit ? "edited" : "created"} successfully`);

      if (edit) {
        setTimeout(() => {
          navigate(0);
        }, 2000);
      } else {
        setTimeout(() => {
          navigate(`/trivia`);
        }, 2000);
      }
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setIsLoading(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };

  const modalHandler = (title, handler) => {
    setModalContent({
      ...modalContent,
      title: title,
      handler: handler,
    });
    setShowModal(true);
  };

  const generateQuestions = (
    values: any,
    count: number,
    configIndex: number,
  ) => {
    const quesArray = [
      ...values?.questionConfigurations[configIndex]?.questions,
    ];
    if (count > quesArray.length) {
      const diff = count - quesArray.length;
      return [
        ...quesArray,
        ...Array.from({ length: diff }, (_, idx) =>
          defaultQuestionPayload(quesArray.length + idx + 1),
        ),
      ];
    } else if (count < quesArray.length) {
      const diff = quesArray.length - count;
      return quesArray.slice(0, quesArray.length - diff);
    } else return quesArray;
  };

  const generateDifficultyOptions = (questionConfig: any) => {
    const options = [...difficultyOptions];
    const selectedDifficulties = questionConfig.map(
      (qConfig) => qConfig.difficulty,
    );

    // show only those difficulties that arent selected yet
    return options.filter(
      (option) => !selectedDifficulties.includes(option.value),
    );
  };

  const quizData = useMemo(
    () => ({
      name: resultData?.data?.name ?? "",
      theme: resultData?.data?.theme ?? "",
      description: resultData?.data?.description ?? "",
      duration: resultData?.data?.duration
        ? {
            startTime: new Date(resultData?.data?.duration?.startTime),
            endTime: new Date(resultData?.data?.duration?.endTime),
          }
        : {
            startTime: null,
            endTime: null,
          },
      questionConfigurations: resultData?.data?.questionConfigurations.map(
        (qConfig, qConIdx) => ({
          difficulty: qConfig.difficulty,
          prizes: qConfig.prizes,
          rewardType: qConfig.rewardType,
          questionsCount: qConfig.questions?.length,
          questions: qConfig.questions?.map((question, qIdx) => ({
            questionNumber: question.questionNumber,
            question: question.question,
            correctOptionNumber: question.correctOptionNumber,
            correctOptionValue: question.correctOptionValue,
            options: question.options,
          })),
        }),
      ),
    }),
    [resultData],
  );

  useEffect(() => {
    setImage(resultData?.data?.bannerUrl);
    setBannerImage(resultData?.data?.bannerUrl);
  }, [resultData?.data?.bannerUrl]);

  useEffect(() => {
    if (presignUrl) {
      handleBannerUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presignUrl]);

  useEffect(() => {
    if (fileName) {
      getPresignedUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  useEffect(() => {
    const fetchQuizData = async () => {
      if (!triviaQuizId || !edit) return;
      try {
        setIsLoading(true);
        setResultData({ data: null, isLoading: true, error: null });

        const resData = await fetchTriviaQuizById({ quizId: triviaQuizId });

        if (
          resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
          resData?.status === HttpStatus.RESOURCE_CREATED
        ) {
          const quizData = resData?.data?.data;
          setResultData({
            data: quizData,
            isLoading: false,
            error: null,
          });

          setPrizeList(
            quizData?.questionConfigurations?.map((qConfig) => {
              return qConfig?.prizes ?? [];
            }),
          );
        } else {
          throw new Error(resData?.message ?? "Failed to fetch quiz data.");
        }
      } catch (error) {
        setResultData({
          data: null,
          isLoading: false,
          error: error.message ?? "An unexpected error occurred.",
        });
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: error.message ?? "Something went wrong, please try again.",
          variant: "danger",
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuizData();
  }, [triviaQuizId, edit]);

  if (isLoading || resultData?.isLoading) {
    return (
      <div className="react-table-loader">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!resultData?.data && edit) {
    return <div className="react-table-loader">Data unavailable</div>;
  }

  return (
    <>
      <ConfirmationModal
        variant="alert"
        show={showModal}
        title={modalContent?.title}
        handleClick={modalContent.handler}
        handleClose={() => setShowModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowModal(false)}
        btnText="Confirm"
        isLoading={isPublishLoading}
      />
      <Button
        className="d-flex align-items-start px-0 dynamic-width-button"
        variant="link"
        onClick={() => navigate(-1)}
      >
        {Action.goBack}
      </Button>
      <Typography variant="heading3" className="pb-2">
        {edit ? "Edit Quiz" : "Create Quiz"}
      </Typography>

      <Formik
        initialValues={edit ? quizData : initialTriviaValues}
        validationSchema={QuizSchema}
        onSubmit={updateOrSave}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
        validate={(values) => {
          return QuizSchema.validate(values, { context: values })
            .then(() => ({}))
            .catch((err) => {
              const errors = {};
              err.inner.forEach((error) => {
                errors[error.path] = error.message;
              });
              return errors;
            });
        }}
      >
        {({
          values,
          setFieldValue,
          isValid,
          isSubmitting,
          setFieldTouched,
          errors,
        }) => {
          return (
            <Form>
              <div
                className={`d-flex align-items-center gap-2 ${
                  edit ? "pb-4" : "pb-1"
                }`}
              >
                {edit && (
                  <div className="d-flex align-items-center pb-2">
                    {resultData?.data?.isPublished ? (
                      <span className="badge rounded-pill text-bg-success px-2 py-1">
                        Published
                      </span>
                    ) : (
                      <span className="badge rounded-pill text-bg-secondary px-2 py-1 text-light">
                        Yet to be Published
                      </span>
                    )}
                  </div>
                )}
              </div>

              <div className="d-flex gap-4">
                <div className="w-25">
                  <label>Name</label>
                  <Field
                    className="form-control p-2 rounded"
                    name="name"
                    placeholder="name"
                    disabled={resultData?.data?.isPublished}
                  />
                  <ErrorMessage
                    name={"name"}
                    component="div"
                    className="absolute bottom-0 text-danger"
                  />
                </div>
                <div className="w-25">
                  <label>Description</label>
                  <Field
                    className="form-control p-2 rounded"
                    placeholder="description"
                    name="description"
                    disabled={resultData?.data?.isPublished}
                  />
                  <ErrorMessage
                    name={"description"}
                    component="div"
                    className="absolute bottom-0 text-danger"
                  />
                </div>

                <div>
                  <label>Duration</label>
                  <div className="d-flex gap-2 align-items-start">
                    <div className="relative">
                      <DateTimePicker
                        selectedDate={values?.duration?.startTime}
                        placeholder="Start time"
                        name="duration.startTime"
                        dateChangeHandler={(value) => {
                          setFieldValue("duration.startTime", value);
                        }}
                      />
                      <ErrorMessage
                        name="duration.startTime"
                        component={"div"}
                        className="absolute bottom-0 text-danger"
                      />
                    </div>
                    <div className="relative">
                      <DateTimePicker
                        selectedDate={values?.duration?.endTime}
                        placeholder="End time"
                        name="duration.endTime"
                        dateChangeHandler={(value) => {
                          setFieldValue("duration.endTime", value);
                        }}
                      />
                      <ErrorMessage
                        name="duration.endTime"
                        component={"div"}
                        className="absolute bottom-0 text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-4 my-3">
                <div className="w-25">
                  <label>Theme</label>
                  <Field
                    className="form-control p-2 rounded"
                    placeholder="Theme"
                    name="theme"
                    disabled={resultData?.data?.isPublished}
                  />
                  <ErrorMessage
                    name={"theme"}
                    component="div"
                    className="absolute bottom-0 text-danger"
                  />
                </div>
              </div>

              <div className="d-flex gap-4 mt-4">
                <span>Banner</span>
                <div>
                  <div className="d-flex flex-column">
                    {image ? (
                      <Image
                        src={image}
                        width={100}
                        height={100}
                        alt="team logo"
                      />
                    ) : (
                      <Image
                        src={NoImage}
                        width={100}
                        height={100}
                        alt="logo"
                        onClick={handleBannerClick}
                        thumbnail
                      />
                    )}
                    <Button
                      className="logo-upload-button"
                      onClick={handleBannerClick}
                      disabled={isUploadBanner || resultData?.data?.isPublished}
                      variant="link"
                    >
                      {image
                        ? isUploadBanner
                          ? Action.updating
                          : Action.update
                        : isUploadBanner
                        ? Action.uploading
                        : Action.upload}
                    </Button>
                  </div>
                  <FormB.Group style={{ display: "none" }}>
                    <FormB.Label>Upload Image</FormB.Label>
                    <FormB.Control
                      ref={bannerInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleBannerChange}
                    />
                  </FormB.Group>
                </div>
              </div>

              <div className="pb-5">
                <FieldArray name="questionConfigurations">
                  {({ insert, remove, push }) => (
                    <>
                      <div>
                        {values.questionConfigurations?.map(
                          (questionConfig, qConfigIndex) => (
                            <div key={qConfigIndex}>
                              <div className="divider-container"></div>
                              <div className="divider-container"></div>

                              <div className="d-flex justify-content-between align-items-center">
                                <Typography variant="heading3" className="mb-4">
                                  Difficulty Config {qConfigIndex + 1}
                                </Typography>

                                <Button
                                  variant="danger"
                                  onClick={() => remove(qConfigIndex)}
                                  disabled={
                                    resultData?.data?.isPublished ||
                                    values.questionConfigurations.length <= 1
                                  }
                                >
                                  Delete Difficulty
                                </Button>
                              </div>

                              <section className="d-flex gap-4">
                                <section className="w-50">
                                  <Typography variant="heading4">
                                    Difficulty
                                  </Typography>
                                  <Select
                                    className="flex-grow-1"
                                    value={difficultyOptions.find(
                                      (opt) =>
                                        opt.value ===
                                        questionConfig?.difficulty,
                                    )}
                                    isDisabled={resultData?.data?.isPublished}
                                    options={generateDifficultyOptions(
                                      values.questionConfigurations,
                                    )}
                                    placeholder="Select Prize Type"
                                    name={`questionConfigurations.${qConfigIndex}.difficulty`}
                                    isLoading={isLoading}
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        `questionConfigurations.${qConfigIndex}.difficulty`,
                                        selectedOption?.value,
                                      );
                                    }}
                                  />
                                </section>

                                <section className="w-50">
                                  <Typography variant="heading4">
                                    No. of Questions
                                  </Typography>
                                  <Field
                                    className="form-control p-2 rounded"
                                    name={`questionConfigurations.${qConfigIndex}.questionsCount`}
                                    type="number"
                                    placeholder="No. of questions"
                                    min={1}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      const value =
                                        parseInt(e.target.value) || 1;
                                      setFieldValue(
                                        `questionConfigurations.${qConfigIndex}.questionsCount`,
                                        value,
                                      );
                                      setFieldValue(
                                        `questionConfigurations.${qConfigIndex}.questions`,
                                        generateQuestions(
                                          values,
                                          value,
                                          qConfigIndex,
                                        ),
                                      );
                                    }}
                                    disabled={resultData?.data?.isPublished}
                                  />
                                </section>
                              </section>

                              <section className="d-flex flex-column w-100">
                                <FieldArray
                                  name={`questionConfigurations.${qConfigIndex}.questions`}
                                >
                                  {({ insert, remove, push }) => (
                                    <div>
                                      {questionConfig?.questions?.map(
                                        (question, questionIndex) => (
                                          <div
                                            key={questionIndex}
                                            className="mt-5 container-fluid"
                                          >
                                            <div className="row">
                                              <div className="col-4">
                                                <Typography
                                                  variant="heading4"
                                                  className="p-1"
                                                >
                                                  Question{" "}
                                                  {question.questionNumber}
                                                </Typography>

                                                <div className="relative mb-2">
                                                  <Field
                                                    className="quiz-question fs-6"
                                                    name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.question`}
                                                    placeholder="Enter Question"
                                                    disabled={
                                                      resultData?.data
                                                        ?.isPublished && edit
                                                    }
                                                  />
                                                  <ErrorMessage
                                                    name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.question`}
                                                    component="div"
                                                    className="absolute py-2 px-1 top-0 right-0 text-danger"
                                                  />
                                                  {(() => {
                                                    const optionsError =
                                                      errors
                                                        .questionConfigurations?.[
                                                        qConfigIndex
                                                      ]?.questions?.[
                                                        questionIndex
                                                      ]?.options;

                                                    const questionsError =
                                                      errors
                                                        .questionConfigurations?.[
                                                        qConfigIndex
                                                      ]?.questions;

                                                    if (
                                                      typeof optionsError ===
                                                      "string"
                                                    ) {
                                                      return (
                                                        <div className="absolute py-2 px-1 top-0 right-0 text-danger">
                                                          {optionsError}
                                                        </div>
                                                      );
                                                    }

                                                    if (
                                                      typeof questionsError ===
                                                      "string"
                                                    ) {
                                                      return (
                                                        <div className="absolute py-2 px-1 top-0 right-0 text-danger">
                                                          {questionsError}
                                                        </div>
                                                      );
                                                    }

                                                    return null;
                                                  })()}
                                                </div>
                                              </div>
                                              <div className="relative mt-2 mb-2 col-4">
                                                <Typography
                                                  variant="heading4"
                                                  className="p-1"
                                                >
                                                  Correct Option number
                                                </Typography>
                                                <Select
                                                  placeholder="Select correct option number"
                                                  options={optionNumberOptions}
                                                  className="w-75 text-nowrap"
                                                  name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.correctOptionNumber`}
                                                  value={optionNumberOptions.find(
                                                    (opt) =>
                                                      opt.value ===
                                                      question?.correctOptionNumber,
                                                  )}
                                                  onChange={(option) => {
                                                    setFieldValue(
                                                      `questionConfigurations.${qConfigIndex}.questions.${questionIndex}.correctOptionNumber`,
                                                      option.value,
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    setFieldTouched(
                                                      `questionConfigurations.${qConfigIndex}.questions.${questionIndex}.correctOptionNumber`,
                                                      true,
                                                      true,
                                                    );
                                                  }}
                                                  isDisabled={
                                                    resultData?.data
                                                      ?.isPublished
                                                  }
                                                />

                                                <ErrorMessage
                                                  name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.correctOptionNumber`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </div>
                                              <div className="d-flex align-items-center gap-1 col-4">
                                                <Field
                                                  type="checkbox"
                                                  name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.optionSameAsCountry`}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `questionConfigurations.${qConfigIndex}.questions.${questionIndex}.optionSameAsCountry`,
                                                      e.target.checked,
                                                    );
                                                  }}
                                                  id={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}`}
                                                />
                                                <label
                                                  htmlFor={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}`}
                                                >
                                                  Option same as country
                                                </label>
                                              </div>
                                            </div>
                                            <FieldArray
                                              name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.options`}
                                            >
                                              {({ insert, remove, push }) => (
                                                <div
                                                  className="pt-2"
                                                  style={{
                                                    display: "grid",
                                                    gap: "1rem",
                                                    gridTemplateColumns:
                                                      "1fr 1fr",
                                                  }}
                                                >
                                                  {question?.options?.map(
                                                    (option, optIndex) => (
                                                      <div
                                                        className="quiz-options-container quiz-option mb-0 pb-1"
                                                        key={optIndex}
                                                      >
                                                        <Typography variant="heading6">
                                                          Option{" "}
                                                          {option.optionNumber}
                                                        </Typography>
                                                        {!values
                                                          .questionConfigurations[
                                                          qConfigIndex
                                                        ].questions[
                                                          questionIndex
                                                        ]
                                                          .optionSameAsCountry && (
                                                          <div className="input-container">
                                                            <Field
                                                              className="form-control rounded p-2"
                                                              placeholder="Enter option text"
                                                              name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.options.${optIndex}.option`}
                                                              disabled={
                                                                resultData?.data
                                                                  ?.isPublished
                                                              }
                                                            />
                                                            <ErrorMessage
                                                              name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.options.${optIndex}.option`}
                                                              component="div"
                                                              className="error-message"
                                                            />
                                                          </div>
                                                        )}
                                                        <div className="input-container">
                                                          <Select
                                                            placeholder="Select Country"
                                                            className="w-100 text-nowrap"
                                                            options={
                                                              countryOptions
                                                            }
                                                            name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.options.${optIndex}.countryCode`}
                                                            value={countryOptions.find(
                                                              (opt) =>
                                                                opt.value ===
                                                                option?.countryCode,
                                                            )}
                                                            filterOption={(
                                                              option,
                                                              inputValue,
                                                            ) =>
                                                              option.data.key
                                                                .toLowerCase()
                                                                .includes(
                                                                  inputValue.toLowerCase(),
                                                                )
                                                            }
                                                            onChange={(
                                                              option,
                                                            ) => {
                                                              setFieldValue(
                                                                `questionConfigurations.${qConfigIndex}.questions.${questionIndex}.options.${optIndex}.countryCode`,
                                                                option.value,
                                                              );
                                                              values
                                                                .questionConfigurations[
                                                                qConfigIndex
                                                              ].questions[
                                                                questionIndex
                                                              ]
                                                                .optionSameAsCountry &&
                                                                setFieldValue(
                                                                  `questionConfigurations.${qConfigIndex}.questions.${questionIndex}.options.${optIndex}.option`,
                                                                  option.key,
                                                                );
                                                              setFieldValue(
                                                                `questionConfigurations.${qConfigIndex}.questions.${questionIndex}.options.${optIndex}.countryName`,
                                                                option.key,
                                                              );
                                                            }}
                                                            isDisabled={
                                                              resultData?.data
                                                                ?.isPublished
                                                            }
                                                          />
                                                          <ErrorMessage
                                                            name={`questionConfigurations.${qConfigIndex}.questions.${questionIndex}.options.${optIndex}.countryCode`}
                                                            component="div"
                                                            className="error-message"
                                                          />
                                                        </div>
                                                      </div>
                                                    ),
                                                  )}
                                                </div>
                                              )}
                                            </FieldArray>
                                            <div className="divider-container"></div>
                                          </div>
                                        ),
                                      )}
                                    </div>
                                  )}
                                </FieldArray>
                              </section>

                              <section className="d-flex flex-column w-100 mt-2 mb-4">
                                <Typography variant="heading4">
                                  Prize Configuration
                                </Typography>

                                <div className=" d-flex flex-column gap-2 mb-2">
                                  <Select
                                    className="flex-grow-1"
                                    value={rewardTypeOptions.find(
                                      (opt) =>
                                        opt.value ===
                                        questionConfig?.rewardType,
                                    )}
                                    options={rewardTypeOptions}
                                    placeholder="Select Prize Type"
                                    name={`questionConfigurations.${qConfigIndex}.rewardType`}
                                    isLoading={isLoading}
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        `questionConfigurations.${qConfigIndex}.rewardType`,
                                        selectedOption?.value,
                                      );
                                    }}
                                  />
                                  <div className="custom-error-message">
                                    {values?.questionConfigurations[
                                      qConfigIndex
                                    ]?.rewardType === PrizeTypeEnum.PERCENTAGE
                                      ? prizeList[qConfigIndex]?.length === 0 &&
                                        "Prize Configuration is required"
                                      : prizeList[qConfigIndex]?.length !==
                                          values?.questionConfigurations[
                                            qConfigIndex
                                          ]?.questions?.length &&
                                        "Prize Configuration is required"}
                                  </div>
                                </div>

                                <PrizeConfig
                                  isLoading={isLoading || resultData.isLoading}
                                  isError={false}
                                  errorMsg="Something went wrong, please try again"
                                  prizeList={prizeList?.[qConfigIndex] ?? []}
                                  setPrizeList={handleUpdatePrizeList(
                                    qConfigIndex,
                                  )}
                                  isReadOnly={resultData?.data?.isPublished}
                                  setShowToaster={setShowToaster}
                                  isDisabledDeleteRow={
                                    resultData?.data?.isPublished
                                  }
                                  isFromFieldDisabled={
                                    resultData?.data?.isPublished
                                  }
                                  isToFieldDisabled={
                                    resultData?.data?.isPublished
                                  }
                                  canSetDefaultToAndFromValue={true}
                                  isRewardTypeFlat={
                                    questionConfig?.rewardType ===
                                    PrizeTypeEnum.FLAT
                                  }
                                  questionsCount={
                                    questionConfig?.questions?.length
                                  }
                                  isEditing={!!edit}
                                />
                              </section>
                            </div>
                          ),
                        )}
                      </div>
                      <Button
                        variant="primary"
                        onClick={() =>
                          push(
                            defaultQuestionConfig(
                              getNextDifficulty(values?.questionConfigurations),
                              QuestionsArrayLength,
                            ),
                          )
                        }
                        disabled={
                          resultData?.data?.isPublished ||
                          values?.questionConfigurations.length >= 3
                        }
                      >
                        Add Difficulty Config
                      </Button>
                    </>
                  )}
                </FieldArray>
              </div>
              <div
                style={{
                  zIndex: 900,
                }}
                className="position-fixed bottom-0 py-3 w-100"
              >
                <div className="d-flex justify-content-start gap-3 w-75">
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-25"
                    disabled={
                      isLoading || resultData?.data?.isPublished || isSubmitting
                    }
                  >
                    {isLoading
                      ? Action.saving
                      : edit
                      ? "Update Quiz"
                      : "Create Quiz"}
                  </Button>
                  {edit && (
                    <Button
                      type="button"
                      variant="primary"
                      className="w-25"
                      disabled={isLoading || !isValid}
                      onClick={() => {
                        if (!resultData?.data?.isPublished) {
                          modalHandler(
                            `Are you sure you want to publish the quiz ?`,
                            () => publishIngQuiz(true),
                          );
                        } else {
                          modalHandler(
                            `Are you sure you want to unpublish the quiz ?`,
                            () => publishIngQuiz(false),
                          );
                        }
                      }}
                    >
                      {isLoading
                        ? !resultData?.data?.isPublished
                          ? Action.publishing
                          : Action.unPublishing
                        : !resultData?.data?.isPublished
                        ? "publish"
                        : "unPublish"}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};

import { API_METHOD } from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";

export const fetchSubscriptionsList = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.SUBSCRIPTION + "/list";
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const updateSubsciption = async (payload: any = {}, config = {}) => {
  const { id, ...restPayload } = payload;
  const url: string = getBase() + "/api" + URLS.SUBSCRIPTION + `/${id}`;
  return await handleApiRequest(API_METHOD.PUT, url, restPayload, config);
};

export const deleteSubscription = async (payload: any = {}, config = {}) => {
  const { id, ...restPayload } = payload;
  const url: string = getBase() + "/api" + URLS.SUBSCRIPTION + `/${id}`;
  return await handleApiRequest(API_METHOD.DELETE, url, restPayload, config);
};

export const createSubscription = async (payload: any = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.SUBSCRIPTION;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

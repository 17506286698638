import {
  DateRangeEnum,
  DateRangeEnumLabel,
  DaysToGetEnum,
} from "../common/constants";
import { getLocalDisplayedDateRange } from "../teamslist/component/TeamConfigure/teamConfigHelper";
export const staticDateOption = [
  {
    key: DateRangeEnum.last7Days,
    label: DateRangeEnumLabel[DateRangeEnum.lastWeek],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 7,
  },
  {
    key: DateRangeEnum.last15Days,
    label: DateRangeEnumLabel[DateRangeEnum.last15Days],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 15,
  },
  {
    key: DateRangeEnum.lastMonth,
    label: DateRangeEnumLabel[DateRangeEnum.lastMonth],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 30,
  },
];

export const tgStatsStaticDateOption = [
  ...staticDateOption,
  {
    key: DateRangeEnum.lifetime,
    label: DateRangeEnumLabel[DateRangeEnum.lifetime],
    daysToGet: null,
    daysNumber: null,
  },
];

export const initialDateRange = [null, null];
export const initialStaticDateRange = {
  [DateRangeEnum.last7Days]: true,
  [DateRangeEnum.last15Days]: false,
  [DateRangeEnum.lastMonth]: false,
};

export const referralStatsStaticDateRange = {
  ...initialStaticDateRange,
  [DateRangeEnum.last7Days]: false,
};

export const referralTgStatsStaticDateRange = {
  ...initialStaticDateRange,
  [DateRangeEnum.lifetime]: false,
};

export const getInitialDateRange = () => {
  return getLocalDisplayedDateRange(
    staticDateOption[0].daysToGet,
    staticDateOption[0].daysNumber,
  );
};

export const initialTgUserStats = {
  totalTelegramUsersCount: 0,
  newTelegramUsersCount: 0,

  gameJoinedUserCount: 0,
  gameJoinedNewUsersCount: 0,

  tripleThreatJoinedUsersCount: 0,
  tripleThreatJoinedNewUsersCount: 0,
  tripleThreatJoinedTeamsCount: 0,
  tripleThreatNewUsersJoinedTeamsCount: 0,

  fantasyJoinedUsersCount: 0,
  fantasyJoinedNewUsersCount: 0,
  fantasyJoinedTeamsCount: 0,
  fantasyNewUsersJoinedTeamsCount: 0,

  goalFestJoinedUsersCount: 0,
  goalFestJoinedNewUsersCount: 0,
  goalFestJoinedTeamsCount: 0,
  goalFestNewUsersJoinedTeamsCount: 0,

  triviaJoinedUsersCount: 0,
  triviaJoinedNewUsersCount: 0,
  triviaJoinedTeamsCount: 0,
  triviaNewUsersJoinedTeamsCount: 0,

  totalReferralsCount: 0,
  userReferredAtleastOneUserCount: 0,
};

export const tgUserStatsMetricMap = {
  totalTelegramUsersCount: "Total users till date",
  newTelegramUsersCount: "New users between the above selected time range",

  gameJoinedUserCount: "Total users who played any game",
  gameJoinedNewUsersCount: "New users who played any game",

  tripleThreatJoinedUsersCount: "Total users who played Triple Threat",
  tripleThreatJoinedNewUsersCount: "New users who played Triple Threat",
  tripleThreatJoinedTeamsCount: "Total Triple Threat games played",
  tripleThreatNewUsersJoinedTeamsCount:
    "Total Triple Threat games played by New users",

  fantasyJoinedUsersCount: "Total users who played Fantasy",
  fantasyJoinedNewUsersCount: "New users who played Fantasy",
  fantasyJoinedTeamsCount: "Total Fantasy games played",
  fantasyNewUsersJoinedTeamsCount: "Total Fantasy games played by New users",

  goalFestJoinedUsersCount: "Total users who played Goalfest",
  goalFestJoinedNewUsersCount: "New users who played Goalfest",
  goalFestJoinedTeamsCount: "Total Goalfest games played",
  goalFestNewUsersJoinedTeamsCount: "Total Goalfest games played by New users",

  triviaJoinedUsersCount: "Total users who played Time Machine",
  triviaJoinedNewUsersCount: "New users who played Time Machine",
  triviaJoinedTeamsCount: "Total Time Machine games played",
  triviaNewUsersJoinedTeamsCount:
    "Total Time Machine games played by New users",

  totalReferralsCount: "Total referrals",
  userReferredAtleastOneUserCount: "Users who referred at least one user",
};

export const dummyTgReferrals = [
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 15,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 20,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
  {
    username: "alpha",
    name: "Alpha",
    referralCount: 10,
  },
];

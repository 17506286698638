import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ConfirmationModal from "./common/components/Modal/ConfirmationModel";
import { useDispatch, useSelector } from "react-redux";
import { resetSessionExpireData } from "./redux/sessionExpireSlice";
import {
  leaguesSeasonInitialState,
  setLeagueSeasonMetadata,
} from "./redux/leagueSeasonSlice";
import { useNavigate } from "react-router-dom";

import { HttpStatus } from "./utils/constants";
import { fetchLeaguesData } from "./LeaguesConfigure/components/service";
import { getLeaguesOptionData } from "./LeaguesConfigure/components/leaguesUtils";
import { getCommonError } from "./common/message";

import AllRoutes from "./routes/AllRoutes";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sessionData = useSelector((state: any) => state.session);
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const handleCloseConfirmation = () => {
    dispatch(resetSessionExpireData());
    localStorage.clear();
    navigate("/login");
  };
  const getLeaguesData = async () => {
    dispatch(
      setLeagueSeasonMetadata({
        ...leaguesSeasonInitialState,
        isLoading: true,
      }),
    );
    const resData: any = await fetchLeaguesData();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      dispatch(
        setLeagueSeasonMetadata({
          ...leaguesSeasonInitialState,
          leaguesSeason: getLeaguesOptionData(resData?.data?.data),
        }),
      );
    } else {
      dispatch(
        setLeagueSeasonMetadata({
          isLoading: false,
          isError: true,
          errorMsg: getCommonError(resData?.message),
          leaguesSeason: [],
        }),
      );
    }
  };

  useEffect(() => {
    if (location?.pathname !== "/login") {
      const leaguesData = leaguesMetadata.leaguesSeason;
      if (location?.pathname === "/leagues" || !leaguesData?.length)
        getLeaguesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return (
    <>
      <AllRoutes />
      <ConfirmationModal
        variant={sessionData?.variant}
        show={sessionData?.show}
        title={sessionData?.title}
        handleClick={() => {
          handleCloseConfirmation();
        }}
        handleClose={() => handleCloseConfirmation()}
        btnText="Ok"
      />
    </>
  );
}

export default App;

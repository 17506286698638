import { Button, Col, Row, Spinner } from "react-bootstrap";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { fetchStreakList, mapStreakListToMap } from "../../service";
import { HttpStatus } from "../../../../../utils/constants";
import { Tasks } from "../../../../constants";
import Typography from "../../../Typography";
import {
  DailyStreakDataResponse,
  initialDayReward,
  ITriggerToaster,
} from "../../types";
import "./index.scss";
import ReactToastr from "../../../ReactToaster";
import TableWrapper from "../../../ReactTable/TableWrapper";
import DayRewardsRow from "./DayRewardsRow";

const DailyLogin: React.FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [refresh, setRefresh] = useState<number>(0);
  const [streakData, setStreakData] = useState<DailyStreakDataResponse>();
  const [isListLoading, setIsListLoading] = useState<boolean>(false);
  const [listError, setListError] = useState<Error | null>(null);
  const [showNewDayForm, setShowNewDayForm] = useState<boolean>(false);
  const [showToaster, setShowToaster] = useState({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const triggerToast = ({
    heading,
    message,
    variant,
    route,
  }: ITriggerToaster) => {
    setShowToaster({
      show: true,
      heading,
      message,
      variant,
      route,
    });
  };

  const closeNewDayForm = () => {
    setShowNewDayForm(false);
  };

  const refreshHandler = () => {
    setRefresh((p) => p + 1);
  };

  const openNewDayForm = () => {
    setShowNewDayForm(true);
    scrollRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getStreakList = useCallback(async () => {
    setIsListLoading(true);
    try {
      const res = await fetchStreakList();

      if (
        res?.status === HttpStatus.REQUEST_SUCCEEDED ||
        res?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setStreakData(res.data);
      } else {
        throw new Error(res?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setListError(error);
      triggerToast({
        message: error.message,
        variant: "danger",
      });
    } finally {
      setIsListLoading(false);
    }
  }, []);

  useEffect(() => {
    getStreakList();
  }, [getStreakList]);

  const streakMap = useMemo(() => {
    if (streakData?.data) {
      return mapStreakListToMap(streakData.data);
    }
    return null;
  }, [streakData]);

  useEffect(() => {
    getStreakList();
  }, [refresh, getStreakList]);

  const render = () => {
    if (isListLoading) {
      return (
        <div className="react-table-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }

    if (listError) {
      return (
        <div className="react-table-loader">
          <span>{listError?.message}</span>
        </div>
      );
    }

    if (streakData) {
      return (
        <div className="p-3 h-100">
          <Labels />
          <div ref={scrollRef} className="days-container">
            {showNewDayForm && (
              <DayRewardsRow
                isNew={true}
                dayReward={initialDayReward}
                refresh={refreshHandler}
                closeNewDayRow={closeNewDayForm}
                streakMap={streakMap}
                triggerToast={triggerToast}
              />
            )}

            <div className="d-flex flex-column">
              {streakData.data?.map((streak) => {
                return (
                  <DayRewardsRow
                    dayNumber={streak.day}
                    key={streak.id}
                    isNew={false}
                    dayReward={streak}
                    refresh={refreshHandler}
                    streakMap={streakMap}
                    triggerToast={triggerToast}
                  />
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Typography variant="pageTitle">{Tasks.dailyLogin}</Typography>

        <div className="d-flex gap-3">
          <Button onClick={refreshHandler}>Refresh</Button>
          <Button disabled={showNewDayForm} onClick={openNewDayForm}>
            {Tasks.create}
          </Button>
        </div>
      </div>
      <TableWrapper tableClassName="daily-login-wrapper">
        {render()}
      </TableWrapper>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};

const Labels = () => {
  return (
    <>
      <Row>
        <Col lg={2}>{Tasks.day}</Col>
        <Col lg={2}>{Tasks.taskType}</Col>
        <Col lg={1}></Col>
        <Col lg={2}>{Tasks.amount}</Col>
        <Col lg={2}>{Tasks.category}</Col>
        <Col lg={2}>{Tasks.currency}</Col>
        <Col lg={1}></Col>
      </Row>
      <div className="line mt-2" />
    </>
  );
};

export default DailyLogin;
